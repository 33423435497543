export const images = {
    logo: require("./logo.png"),
    user: require("./user.png"),
    service: require("./service.png"),
    soon: require('./soon.png'),
    businessman: require('./businessman.png'),
    handshake: require('./handshake 1.png'),
    support: require('./support.png'),
    vector: require('./Vector.png'),
    sitemap: require('./sitemap.png'),
    webAnalysis: require('./web-analysis.png'),
    vmImg: require('./database.png'),
    logoFooter: require('./Group 1243.png'),
    logoSvg: require('./logo.svg'),
    paymentImg: require('./paymentIconInvoice.png')
};
