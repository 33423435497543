import React from "react";

const RequestIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6671 5.3335H8.00041C6.52974 5.3335 5.33374 6.5295 5.33374 8.00016V14.6668H8.00041V10.6668L16.5337 17.0668C16.7646 17.2397 17.0453 17.3332 17.3337 17.3332C17.6222 17.3332 17.9029 17.2397 18.1337 17.0668L26.6671 10.6668V22.6668H16.0004V25.3335H26.6671C28.1377 25.3335 29.3337 24.1375 29.3337 22.6668V8.00016C29.3337 6.5295 28.1377 5.3335 26.6671 5.3335ZM17.3337 14.3335L8.88841 8.00016H25.7791L17.3337 14.3335Z"
        fill="white"
      />
      <path
        d="M2.66626 16H11.9996V18.6667H2.66626V16ZM5.33293 20H13.3329V22.6667H5.33293V20ZM9.33293 24H14.6663V26.6667H9.33293V24Z"
        fill="white"
      />
    </svg>
  );
};

export default RequestIcon;
