import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import {useLocation} from "react-router-dom";
 import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getMyVps, getMyVpsDetail} from "../../store/actions/vpsActions";
import {images} from "../../images/images"
import Arrow from "../../images/arrow";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import LoadingPage from "../LoadingPage/LoadingPage";
import VMmodal from '../../components/Modal/VMmodal';
import Footer from "../../components/Footer/Footer";

type myVpsType = {
  account_id: string
  contact_id: string
  agreement: null
  bdate: string
  vm_id: string
  name: string
  status: string
  sdate: string
  cloudvols: string
}

const MyVm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {access} = useAppSelector((state) => state.auth);
  const {myVps, loading} = useAppSelector((state) => state.vps);

  const [modal, setModal] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);

  const handleClick = (id: string) => {
    setModal(true);
    setId(id)
  } 
  useEffect(() => {
    dispatch(getMyVps(access))
  }, [access, dispatch])

  useEffect(() => {
    if (id) {
      dispatch(getMyVpsDetail(access, id))
    }
  }, [access, dispatch, id])
  
  if (loading) return <LoadingPage/>

  return (
    <>
      <div className={'myRequest'}>
        <Sidebar active={location.pathname} type="profile"/>
        <div className="main_wrap" style={{height: myVps.length >= 4 ? '100%' : `calc(${window.innerHeight}px - 200px)`}}>
          <div className="main_wrap-services">
            {myVps.length !== 0 ? myVps?.map((item: myVpsType) => (
              <div className={'service'} key={item.vm_id}  onClick={() => handleClick(item.vm_id)}>
                <div className="service_head">
                  <img src={images.vmImg} alt="service-icon"/>
                </div>
                <div className="title">{item.name}</div>
                <div className="link">
                  <span>{t("inDetail")}</span>
                  <div className="link_hover">
                    <Arrow color="#ffffff" />
                  </div>
                  <div className="link_not-hover">
                    <Arrow color="#0E0E4B" />
                  </div>
                </div>
              </div>
            )) : (
              <>
                <h1>{t("no_info_vm")}</h1>
              </>
            )}
          </div>
        </div>
        {modal && (
          <VMmodal close={() => setModal(false)} />
        )}
      </div>
      {/* <Footer/> */}
    </>
  );
}

export default MyVm;