import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PublicOffer from "../../components/PublicOffer/PublicOffer";
import Steps from "../../components/Steps/Steps";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import Arrow2 from "../../images/arrow2";
import Checked from "../../images/checked";
import { getOfferDetail } from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import { useTranslation } from "react-i18next";
import EMailOffer from "../EXat/EMailOffer";
import {createVpsContractAdditionalAgreement} from "../../store/actions/vpsActions";

const RequestPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { service_id, group_id, name } = location.state;
  
  const {userDetail} = useAppSelector((state) => state.user);
  const { access } = useAppSelector((state) => state.auth);
  const { offerDetail } = useAppSelector((state) => state.contracts);

  const [agree, setAgree] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getOfferDetail(service_id, access));
    dispatch(createVpsContractAdditionalAgreement(access, userDetail?.bank_mfo ? userDetail.tin : userDetail?.pin, navigate, 'colocation')).then((res: any) => {
      localStorage.setItem('error_code', JSON.stringify(res))
      if (res?.error_code === 3) {
        alert("Sizda ushbu xizmat bo'yicha yangi shartnoma mavjud va ushbu shartnoma ko'rib chiqilmoqda. Holatini shaxsiy kabinetingizda \"Shartnomalarim\" bo'limida kuzatishingiz mumkin!")
        navigate('/my-request')
      } else if (res?.error_code === 1) {
        navigate('/contract-info', { state: { group_id, service_id, name } })
      }
    })
  }, [access]);

  // if (loading) return <LoadingPage />;

  return (
    <div className="container">
      <div className="request">
        <Steps activeStep={1} />
        <div className="request_content">
          <EMailOffer title={t("publicOffer")} pdfFile={offerDetail?.file} contract={false}/>
          <div className="request_content-agreement" onClick={() => setAgree(!agree)}>
            <div
              className={
                agree
                  ? "request_content-agreement-active"
                  : "request_content-agreement-check"
              }
            >
              <Checked />
            </div>
            <span>{t("gotAcquaintedWithRules")}</span>
          </div>
          <div className="request_content-buttons">
            <button onClick={() => navigate(-1)}>
              {/* <CancelIcon /> */}
              <span>{t("cancel")}</span>
            </button>
            <button
              disabled={!agree}
              style={{ opacity: !agree ? 0.5 : 1 }}
              onClick={() =>
                navigate("/contract-info", { state: { group_id, service_id, name } })
              }
            >
              <span>{t("next")}</span>
              <Arrow2 color="white" />
            </button>
          </div>
        </div>
        <div className="request_block"></div>
      </div>
    </div>
  );
};

export default RequestPage;
