import * as authTypes from "../actionTypes/authAtionTypes";
import { ReducerActions } from "../utilsTypes";

interface ResponseState {
  scope: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  access_token: string;
}

interface TokenState {
  access: string;
  refresh: string;
}

interface RefreshState {
  access: string;
}

interface AuthState {
  loading: boolean;
  error: string;
  response: ResponseState | null;
  token: TokenState | null;
  access: RefreshState | null;
  message: MessageState | null;
}

interface MessageStateData {
  count: number
  id: string | null,
  msg: string | null,
  is_active: boolean,
  created_time: string | null,
  type: number
}

interface MessageState {
  count: number;
  datas: MessageStateData[]
}

const user = localStorage.getItem("res")
const parsedUser = JSON.parse(user ? user : 'null')

const initState: any = {
  loading: false,
  error: "",
  response: parsedUser,
  token: parsedUser?.refresh,
  access: parsedUser?.access,
  message: null
};

export const AuthReducer = (
  state = initState,
  action: ReducerActions
): AuthState => {
  const { type, payload } = action;
  switch (type) {
    case authTypes.AUTH_START:
      return { ...state, loading: true };
    case authTypes.AUTH_SUCCES:
      return { ...state, loading: false, response: payload };
    case authTypes.SET_TOKEN:
      return { ...state, loading: false, token: payload };
    case authTypes.SET_REFRESH:
      return { ...state, loading: false, access: payload.access };
    case authTypes.AUTH_FAIL:
      return { ...state, loading: false, error: payload };
    case authTypes.LOG_OUT:
      return {
        loading: false,
        error: "",
        response: null,
        token: null,
        access: null,
        message: null
      };
    case 'set':
      return {...state}
    case authTypes.MESSAGE_START:
      return {...state, loading: true}
    case authTypes.MESSAGE_FULL:
      return {...state, loading: false, message: payload}
    case authTypes.MESSAGE_ERROR:
      return {...state, loading: false, error: payload}
    default:
      return state;
  }
};
