import React from 'react';
import OutsideClickHandler from "react-outside-click-handler";
import {useTranslation} from "react-i18next";
import {images} from "../../images/images";

type Props = {
  close: () => void;
}

const SoonModal: React.FC<Props> = ({close}) => {
  const {t} = useTranslation()
  return (
    <>
      <div className="modalWrap">
        <OutsideClickHandler onOutsideClick={close}>
          <div className="modalWrap_block" style={{textAlign: 'center'}}>
            <img src={images.soon} alt=""/>
            <h2 style={{marginTop: '20px'}}>HURMATLI MIJOZ!</h2>
            <p style={{color: "#fff", fontSize: '18px'}}>Ushbu xizmatdan foydalanish <br/> imkoniyati vaqtincha mavjud <br/> emas.</p>
            <div className="modalWrap_block-btnBlock" style={{justifyContent: 'center'}}>
              <button onClick={close}>OK</button>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default SoonModal;
