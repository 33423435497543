import React from "react";

type Props = {
    color: any;
}

const FourthStepIcon: React.FC<Props> = ({color}) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72925 22.3957V19.2707H2.60425M19.2709 22.3957V19.2707H22.3959M5.72925 2.604V5.729H2.60425M19.2709 2.604V5.729H22.3959M7.81258 7.81234H17.1876V17.1873H7.81258V7.81234Z"
        stroke={color}
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FourthStepIcon;
