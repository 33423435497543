import React from "react";

const ErrorIcon = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.3334 117.667C71.3385 117.676 78.2764 116.301 84.7483 113.62C91.2202 110.939 97.0985 107.006 102.045 102.046C107.005 97.099 110.939 91.2207 113.62 84.7488C116.3 78.2769 117.676 71.339 117.667 64.3339C117.676 57.3287 116.3 50.3909 113.619 43.919C110.939 37.4471 107.005 31.5688 102.045 26.6219C97.0985 21.6619 91.2202 17.7285 84.7483 15.0477C78.2764 12.3669 71.3385 10.9915 64.3334 11.0005C57.3282 10.9916 50.3904 12.3671 43.9185 15.0479C37.4466 17.7287 31.5683 21.6621 26.6214 26.6219C21.6616 31.5688 17.7282 37.4471 15.0474 43.919C12.3666 50.3909 10.9911 57.3287 11 64.3339C10.991 71.339 12.3664 78.2769 15.0472 84.7488C17.728 91.2207 21.6614 97.099 26.6214 102.046C31.5683 107.006 37.4466 110.939 43.9185 113.62C50.3904 116.301 57.3282 117.676 64.3334 117.667V117.667Z"
        stroke="#FF8F6B"
        strokeWidth="10.6667"
        strokeLinejoin="round"
      />
      <path
        d="M44 44L83 83"
        stroke="#FF8F6B"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M83 44L44 83"
        stroke="#FF8F6B"
        strokeWidth="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ErrorIcon;
