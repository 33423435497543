import {Dispatch} from "redux";
import axios, {axios2} from "../../axios";
import * as authTypes from "../actionTypes/authAtionTypes";
import {handleType} from "./handleType";
import {getServices} from "./contractActions";

interface ResponseState {
  scope: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  access_token: string;
}

interface TokenState {
  access: string;
  refresh: string;
  tin_or_pin: string
}

interface RefreshData {
  refresh: string | undefined;
}

export interface RefreshState {
  access: string;
}

interface LogoutData {
  response: ResponseState | null;
  token: TokenState | null;
  access: RefreshState | null;
}

export const postAuth = (code: string | null | undefined, origin: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<ResponseState>("oauth/login", {}, {
        headers: {"x-auth": code, "x-path": origin},
      });
      dispatch(handleType(authTypes.AUTH_SUCCES, response.data));
      localStorage.setItem('refresh_token', JSON.stringify(response.data))
    } catch (e) {
      console.log(e)
    }
  };
};

export const getToken = (access_token: string | null | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        "oauth/get-user",
        {is_client: 1},
        {
          headers: {"x-authentication": access_token},
        }
      );
      dispatch(handleType(authTypes.SET_TOKEN, response.data));
      localStorage.setItem('res', JSON.stringify(response.data))
      localStorage.setItem('tin_or_pin', JSON.stringify(response.data?.tin_or_pin))
    } catch (e) {
    }
  };
};

export const refreshToken = (data: RefreshData, navigate: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.post<RefreshState>(
        "auth/token/refresh/",
        data
      );
      dispatch(handleType(authTypes.SET_REFRESH, response.data));
      navigate("/");
      window.location.reload()
    } catch (e) {
    }
  };
};

export const logoutHandler = (data: any, token: any, refresh: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.post(
        "oauth/logout",
        {refresh_token: data.token},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-authentication": refresh.access_token,
          },
        }
      );
      window.location.reload()
      localStorage.removeItem('persist:billing-system')
      localStorage.removeItem('res')
    } catch (e: any) {
      if (e?.response?.status === 500 || e?.response?.status === 401) {
        // @ts-ignore
        dispatch(getServices(''))
      }
    }
  };
};

export const clearAuthData = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(authTypes.LOG_OUT, data));
  };
};

export const getMessage = (token: any, active: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(authTypes.MESSAGE_START, null))
    try {
      if (active) {
        const res = await axios2.get(`main/notify/get/msgs?is_active=0`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        dispatch(handleType(authTypes.MESSAGE_FULL, res.data))
      } else {
        const res = await axios2.get(`main/notify/get/msgs`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        dispatch(handleType(authTypes.MESSAGE_FULL, res.data))
      }
    } catch (e) {
      dispatch(handleType(authTypes.MESSAGE_ERROR, e))
    }
  }
}
