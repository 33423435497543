import { Dispatch } from "redux";
import { axios2 } from "../../axios";
import * as accountTypes from "../actionTypes/accountsActionTypes";
import { iGroupList } from "../models";
import { handleType } from "./handleType";
import * as authTypes from "../actionTypes/authAtionTypes";

export const getGroupList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(handleType(accountTypes.ACCOUNTS_LOADING, null));
      const response = await axios2.get<iGroupList>("accounts/group-list");
      dispatch(handleType(accountTypes.GET_GROUP_LIST, response.data));
    } catch (e) {
      dispatch(handleType(accountTypes.ACCOUNTS_ERROR, e as Error));
    }
  };
};

interface FizuserData {
  mob_phone_no: string | undefined;
  per_adr: string | undefined;
}

interface YurUserData {
  mob_phone_no: string | undefined;
  address: string | undefined;
  xxtut: string | undefined;
  ktut: string | undefined;
  oked: string | undefined;
  paymentAccount: string | undefined;
  bank_mfo: number | undefined;
  director_firstname: string | undefined;
  director_lastname: string | undefined;
  director_middlename: string | undefined;
}

export const updateFizuser = (
  token: any,
  body: FizuserData,
  id: number | undefined
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.patch(
        `accounts/update-fizuser`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(handleType(accountTypes.UPDATE_FIZUSER, response.data));
      return response
    } catch (e) {}
  };
};

export const updateYuruser = (
  token: any,
  body: any,
  id: number | undefined
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios2.patch(
        `accounts/update-yuruser`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(handleType(accountTypes.UPDATE_YURUSER, response.data));
      return response
    } catch (e) {}
  };
};

export const getBank = (token: any, mfo: string | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: any = await axios2.get(`accounts/get-bank?mfo=${mfo}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(handleType(accountTypes.GET_BANK, response.data));
      if (!response?.response?.data?.success && response?.response?.data?.success !== undefined) {
        alert(response?.response?.data?.err_msg)
      }
      return response.data
    } catch (e) {
      console.log('catch', e)
    }
  };
};

export const getMessageDetail = (token: any, id: string| null) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(accountTypes.MESSAGE_DETAIL_START, null))
    try {
      const res = await axios2.get(`main/notify/get/msg/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      dispatch(handleType(accountTypes.MESSAGE_DETAIL_FULL, res.data))
    } catch (e) {
      dispatch(handleType(accountTypes.MESSAGE_DETAIL_ERROR, e))
    }
  }
}

// export const getCurrentTime = () => {
//   return async (dispatch: Dispatch) => {
//     dispatch(handleType(accountTypes.CURRENT_TIME_LOADING, null))
//     try {
//       const response = await axios2.get("accounts/current-time");
//       dispatch(
//         handleType(accountTypes.GET_CURRENT_TIME, response.data.current_time)
//       );
//     } catch (e) {}
//   };
// };
