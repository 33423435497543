// import { toast } from 'react-toastify';
import { Certificate, Key } from './types';
import {
  ISignerErrorCodes,
  ISignerFuncCodes,
  ISignerMsgTypeCodes,
} from './const';
import { Algorithms } from './algorithms';

export class ISigner {
  private static instance: ISigner;

  private readonly webSocket: WebSocket;

  callbacks: Map<any, any>;

  constructor() {
    this.callbacks = new Map();

    let serverAddr = 'ws://127.0.0.1:44480';
    if (window.location.protocol.toLowerCase() === 'https:') {
      serverAddr = 'wss://127.0.0.1:44443';
    }

    this.webSocket = new WebSocket(serverAddr);
    this.webSocket.onopen = () => {
      this.version((version: any) => {
        console.info('ISigner version', version);
      });
    };

    this.webSocket.onmessage = (event: MessageEvent) => {
      const response = JSON.parse(event.data);
      if ('type' in response && response.type === ISignerMsgTypeCodes.Response) {
        if ('cbid' in response) {
          const { cbid } = response;
          console.info('ISigner response:', response);
          if (this.callbacks.has(cbid)) {
            const callback = this.callbacks.get(cbid);
            this.callbacks.delete(cbid);
            if (response.code === ISignerErrorCodes.SUCCESS) {
              callback(response.data);
            } else {
              console.error('ISigner error', response);
              alert(`ISigner error: ${response.data}`);
              callback(response);
            }
          }
        }
      } else {
        console.error('Unknown message type', response);
      }
    };
  }

  public static load(): ISigner {
    if (!ISigner.instance) {
      ISigner.instance = new ISigner();
    }

    return ISigner.instance;
  }

  private execute(type: any, func: any, args: any, callback: any) {
    if (this.webSocket.readyState === WebSocket.CONNECTING) {
      this.webSocket.addEventListener('open', () => this.execute(type, func, args, callback));
    } else if (this.webSocket.readyState === WebSocket.OPEN) {
      const message = {
        type,
        func,
        cbid: Math.random(),
        args,
      };

      console.log('ISigner request:', message);

      if (typeof callback === 'function') this.callbacks.set(message.cbid, callback);

      this.webSocket.send(JSON.stringify(message));
    } else {
      console.info(this.webSocket);
      alert('ISigner WebSocket не подключён!');
    }
  }

  public version(callback: any) {
    this.execute(ISignerMsgTypeCodes.Request, ISignerFuncCodes.VERSION, {}, callback);
  }

  public hash(data: string, callback: any) {
    if (data == null || data.length === 0) {
      callback('');
      return;
    }

    data = data.trim();
    this.execute(ISignerMsgTypeCodes.Request, ISignerFuncCodes.HASH, { data }, callback);
  }

  public keyList(callback: any) {
    this.execute(ISignerMsgTypeCodes.Request, ISignerFuncCodes.KEYLIST, {}, callback);
  }

  public removableList(callback: any) {
    this.execute(ISignerMsgTypeCodes.Request, ISignerFuncCodes.REMOVABLELIST, {}, callback);
  }

  public sign(token: string, digest: string, serial: string, callback: any) {
    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.SIGN,
      { stok: token, data: digest, snum: serial, pass: '' },
      callback,
    );
  }

  public changePin(path: string, curp: string, newp: string, callback: any) {
    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.CHANGEPIN,
      { path, curp, newp },
      callback,
    );
  }

  public resetPin(path: string, sopin: string, callback: any) {
    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.RESET_PIN,
      { path, sopin, format: 0 },
      callback,
    );
  }

  public formatToken(path: string, sopin: string, callback: any) {
    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.RESET_PIN,
      { path, sopin, format: 1 },
      callback,
    );
  }

  public generateKey(stok: string, subject: Certificate, callback: any) {
    const subj = subjectToString(subject);
    const [kalg, palg] = Algorithms.get(subject.algorithm as Algorithms);

    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.KEYPAIRGEN,
      { stok, subj, kalg, palg },
      callback,
    );
  }

  public saveKey(path: string, pass: string, cert: string, root: string, callback: any) {
    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.SAVEKEY,
      { path, cert, root, pass },
      callback,
    );
  }

  public generateQR(path: string, qrID: number, crmID: string, callback: any) {
    this.execute(
      ISignerMsgTypeCodes.Request,
      ISignerFuncCodes.QR,
      {
        path,
        pass: '',
        qrid: qrID,
        crmid: crmID,
      },
      callback,
    );
  }
}

function subjectToString(subject: Certificate) {
  let result = 'C=UZ;';
  if (subject.organization) result += `O=${subject.organization};`;
  if (subject.common_name) result += `CN=${subject.common_name};`;
  if (subject.tin) result += `1.2.860.3.16.1.1=${subject.tin};`;
  if (subject.pinfl) result += `1.2.860.3.16.1.2=${subject.pinfl};`;
  if (subject.address) result += `2.5.4.7=${subject.address};`;
  if (subject.department) result += `2.5.4.11=${subject.department};`;
  if (subject.position) result += `2.5.4.12=${subject.position};`;
  if (subject.email) result += `1.2.840.113549.1.9.1=${subject.email};`;

  return result;
}

function iSignerGetSubjectField(subject: string, name: string) {
  if (subject == null || subject.length === 0) return '';

  let result = '';
  const data = subject.split('/');
  data.forEach((item) => {
    const fields = item.split(';');
    fields.forEach((field) => {
      const [key, value] = field.split('=');
      if (key === name) result = value;
    });
  });

  return result;
}

export function parseKeyList(result: any) {
  const keys: Key[] = [];

  result.forEach((subject: any) => {
    const serial = iSignerGetSubjectField(subject, 'SERIALNUMBER');
    const commonName = iSignerGetSubjectField(subject, 'CN');
    const organization = iSignerGetSubjectField(subject, 'O');
    const validFrom = iSignerGetSubjectField(subject, 'VALIDFROM').substring(0, 10);
    const validTo = iSignerGetSubjectField(subject, 'VALIDTO').substring(0, 10);

    keys.push({
      serial,
      commonName,
      organization,
      validFrom,
      validTo,
      subject,
    });
  });

  return keys;
}
