import React from "react";

type Props = {
    color: any;
}

const DoneIcon: React.FC<Props> = ({color}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.4445" cy="10.4445" r="10.4445" fill={color} />
      <path
        d="M4.72278 9.62699L8.80974 13.714L16.1663 6.35742"
        stroke="white"
        strokeWidth="3.26957"
      />
    </svg>
  );
};

export default DoneIcon;
