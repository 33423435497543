import React, {useState} from "react";
import {useAppSelector} from "../../hooks/redux";

type Props = {
  title: string;
  pdfFile: any;
  contract?: boolean;
};

const PublicOffer: React.FC<Props> = ({title, pdfFile, contract = true}) => {

  function createMarkup() {
    return { __html: `${pdfFile}`}
  }

  return (
    <div className="publicOffer">
      <div className="publicOffer_title mb-4">{title}</div>
      {!contract && (
        <div className='requirement-wrapper'>
          <div className={`requirement-wrapper-scroll`}>
            <h3 className='requirement-title-main'>“YAGONA BILLING” axborot tizimiga xush kelibsiz!</h3>
            <p className='requirement-text'>“YAGONA BILLING” axborot tizimiga (o‘rinlarda - Tizim) kirganingizda yoki
              foydalanganingizda, siz ushbu maxfiylik siyosati va foydalanish shartlarini to‘liq qabul qilasiz.</p>
            <h4 className='requirement-title'>Shartlarni qabul qilish</h4>
            <p className='requirement-text'>Ushbu Tizimga kirib, Siz ushbu Shartlarga roziligingizni bildirasiz. Ushbu
              Shartlarga qo‘shimchalar va o‘zgartirishlar kiritilishi mumkin va yangilangan Shartlar oldindan
              ogohlantirishsiz tizimga joylashtiriladi.</p>
            <p className='requirement-text'>“UNICON.UZ” DUK Foydalanuvchi yoki boshqa tomonlar oldida Tizimning
              ishlashi, Tizimda keltirilgan xizmatlar yoki mahsulotlar tufayli kelib chiqadigan har qanday yo‘qotishlar,
              shu jumladan, beparvolik yoki boshqa holatlar tufayli ma’lumotlarni yo’qotish, foyda yo‘qotish bilan
              bog‘liq to’g’ridan-to‘g‘ri va be’vosita yo‘qotishlar uchun javobgar emas. </p>
            <h4 className='requirement-title'>“YAGONA BILLING” axborot tizimi</h4>
            <p className='requirement-text'>Ushbu Tizimda Siz, “UNICON.UZ” DUK hizmatlaridan foydalanish bo`yicha
              shartnoma ikki tomonlama imzolanishi uchun ma`lumotlar kiritishingiz mumkin.</p>
            <h4 className='requirement-title'>Cookie-fayllar haqida ma’lumot</h4>
            <p className='requirement-text'>“YAGONA BILLING” axborot tizimining ba’zi bo‘limlari parol bilan
              himoyalangan. Siz Tizimning parol bilan himoyalangan bo‘limlariga parolni qaytadan kiritishsiz kirishingiz
              uchun Tizim cookie fayllarni ishlatadi (cookies kompyuteringizda joylashgan bo‘lib sizning
              Internet-brauzeringiz boshqaruvi ostida bo‘ladi). </p>
            <h4 className='requirement-title'>Shartlarni qabul qilish</h4>
            <p className='requirement-text'>Ushbu Tizimga kirib, Siz ushbu Shartlarga roziligingizni bildirasiz. Ushbu
              Shartlarga qo‘shimchalar va o‘zgartirishlar kiritilishi mumkin va yangilangan Shartlar oldindan
              ogohlantirishsiz tizimga joylashtiriladi.</p>
            <p className='requirement-text'>“UNICON.UZ” DUK Foydalanuvchi yoki boshqa tomonlar oldida Tizimning
              ishlashi, Tizimda keltirilgan xizmatlar yoki mahsulotlar tufayli kelib chiqadigan har qanday yo‘qotishlar,
              shu jumladan, beparvolik yoki boshqa holatlar tufayli ma’lumotlarni yo’qotish, foyda yo‘qotish bilan
              bog‘liq to’g’ridan-to‘g‘ri va be’vosita yo‘qotishlar uchun javobgar emas. </p>
            <h4 className='requirement-title'>“YAGONA BILLING” axborot tizimi</h4>
            <p className='requirement-text'>Ushbu Tizimda Siz, “UNICON.UZ” DUK hizmatlaridan foydalanish bo`yicha
              shartnoma ikki tomonlama imzolanishi uchun ma`lumotlar kiritishingiz mumkin.</p>
            <h4 className='requirement-title'>Cookie-fayllar haqida ma’lumot</h4>
            <p className='requirement-text'>“YAGONA BILLING” axborot tizimining ba’zi bo‘limlari parol bilan
              himoyalangan. Siz Tizimning parol bilan himoyalangan bo‘limlariga parolni qaytadan kiritishsiz kirishingiz
              uchun Tizim cookie fayllarni ishlatadi (cookies kompyuteringizda joylashgan bo‘lib sizning
              Internet-brauzeringiz boshqaruvi ostida bo‘ladi). </p>
            <h4 className='requirement-title'>Shartlarni qabul qilish</h4>
          </div>
          <div className='requirement-wrapper-scroll'>
            <p className='requirement-text'>Ushbu Tizimga kirib, Siz ushbu Shartlarga roziligingizni bildirasiz. Ushbu
              Shartlarga qo‘shimchalar va o‘zgartirishlar kiritilishi mumkin va yangilangan Shartlar oldindan
              ogohlantirishsiz tizimga joylashtiriladi.</p>
            <p className='requirement-text'>“UNICON.UZ” DUK Foydalanuvchi yoki boshqa tomonlar oldida Tizimning
              ishlashi, Tizimda keltirilgan xizmatlar yoki mahsulotlar tufayli kelib chiqadigan har qanday yo‘qotishlar,
              shu jumladan, beparvolik yoki boshqa holatlar tufayli ma’lumotlarni yo’qotish, foyda yo‘qotish bilan
              bog‘liq to’g’ridan-to‘g‘ri va be’vosita yo‘qotishlar uchun javobgar emas. </p>
            <h4 className='requirement-title'>“YAGONA BILLING” axborot tizimi</h4>
            <p className='requirement-text'>Ushbu Tizimda Siz, “UNICON.UZ” DUK hizmatlaridan foydalanish bo`yicha
              shartnoma ikki tomonlama imzolanishi uchun ma`lumotlar kiritishingiz mumkin.</p>
            <h4 className='requirement-title'>Cookie-fayllar haqida ma’lumot</h4>
            <p className='requirement-text'>“YAGONA BILLING” axborot tizimining ba’zi bo‘limlari parol bilan
              himoyalangan. Siz Tizimning parol bilan himoyalangan bo‘limlariga parolni qaytadan kiritishsiz kirishingiz
              uchun Tizim cookie fayllarni ishlatadi (cookies kompyuteringizda joylashgan bo‘lib sizning
              Internet-brauzeringiz boshqaruvi ostida bo‘ladi). </p>
          </div>
        </div>
      )}
      {contract && (
        // publicOffer_content agreement
        <div className='requirement-wrapper requirement-wrapper--3'>
          {/*{parse(`${pdfFile}`)}*/}
          <div className="requirement-wrapper-scroll requirement-wrapper-scroll--3" dangerouslySetInnerHTML={createMarkup()}></div>
        </div>
      )}
    </div>
  )
};

export default PublicOffer;
