import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

type Props = {
  close: () => void;
};

const Modal: React.FC<Props> = ({ close }) => {
  return (
    <div className="modalWrap">
      <OutsideClickHandler onOutsideClick={close}>
        <div className="modalWrap_textWrap">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero
          deserunt quo fugit tempore illo! Aliquid nihil mollitia cum magni,
          nesciunt dignissimos suscipit hic fuga fugiat repellat cupiditate
          adipisci quisquam est numquam harum minus dolorem consequatur soluta
          debitis? Similique, earum suscipit minima cupiditate id dolorem
          provident blanditiis, quibusdam perspiciatis ea quod. Dolorem velit
          amet reiciendis deleniti facilis nihil accusantium, atque asperiores
          iste beatae dolor dolorum voluptatem provident eligendi iusto nostrum
          aperiam quia? Ratione eius, nobis impedit dolore fuga sed ab? Porro ea
          incidunt sapiente nemo dicta odit facere nisi eligendi dolorem
          numquam? Dolorem, esse. Minus culpa repudiandae dolorem sed veniam
          porro vitae adipisci numquam beatae ut ratione consequatur obcaecati
          optio quibusdam aspernatur aut, voluptatibus est ex illo molestias,
          hic cum. Est aut dolore eos deleniti sit voluptates inventore illum
          molestias perferendis molestiae. Officia deleniti neque, ab, dolores
          minima sequi maiores suscipit qui rem provident molestias! Similique
          aperiam at repellat ullam, amet corporis ipsa consequatur? Tempora
          aliquam autem asperiores cum, rerum dolor sunt et unde eveniet at
          consectetur. Hic, consectetur repudiandae itaque dolores maiores
          corporis? Minima ea voluptatum unde quia corrupti odit cumque ipsa
          itaque natus eius accusantium provident sint, esse ratione, nobis
          iure! Laudantium fugiat vel alias nisi vero sint distinctio corporis,
          itaque incidunt quos placeat! Et quas est numquam magni qui in,
          officiis veritatis ducimus! Perferendis ab, numquam, maiores animi
          doloremque sed deleniti delectus corporis assumenda dolores recusandae
          nam quasi autem officia natus ducimus libero nihil eum eaque pariatur
          quia magnam. Debitis, culpa ab. Perferendis dolores voluptatibus velit
          fuga unde impedit, dolorum id quibusdam provident fugit. Eveniet
          provident, esse velit rem incidunt, vel possimus sit porro distinctio
          est nihil ipsa ipsam consectetur atque ut consequuntur, doloribus quas
          voluptatibus inventore laborum praesentium minima a accusantium modi.
          Corrupti nihil deleniti illum libero iure qui. In autem quibusdam
          debitis officiis. Nam sit itaque illo quas minima id labore numquam
          assumenda hic, quos corporis enim temporibus praesentium dolor iste
          rerum voluptates! Porro corrupti, officia esse natus sunt voluptate
          delectus tempore soluta dolor, et commodi magnam minima sed nemo unde
          tempora ipsa quis eligendi quibusdam accusantium provident quae.
          Expedita unde odit obcaecati repudiandae eligendi quae molestias ullam
          accusantium tempore non ipsa saepe, repellendus aperiam error quam,
          itaque optio eius ab. Nobis quo illum eligendi nesciunt pariatur qui,
          ullam nostrum adipisci, fuga ab magnam sed molestiae laudantium velit?
          In qui quae quisquam laboriosam sint fugit, ea vitae doloremque neque
          dolores. Dicta id ipsam deleniti nemo ipsum non ab voluptate
          voluptatibus expedita? Eligendi autem impedit ea dolores labore, culpa
          sapiente, incidunt nisi sequi iusto natus aliquid cumque sint harum,
          exercitationem fugit odio optio? Accusantium, ea cupiditate odio
          nostrum distinctio dolor omnis accusamus libero illo velit labore,
          harum maxime deserunt optio ab, ullam quidem mollitia. Quibusdam
          labore molestiae illum natus porro, libero tempore nostrum repellendus
          aperiam, deleniti eos quod modi dignissimos distinctio consequatur vel
          vitae esse reiciendis id nisi, veniam eligendi cupiditate delectus!
          Numquam sit ab nesciunt ea iure, eaque et nostrum eveniet vero quod
          saepe excepturi perferendis illo explicabo accusamus voluptatem quae
          facilis ducimus? Fugit, repellat fuga? Lorem ipsum dolor sit, amet
          consectetur adipisicing elit. Mollitia sit, fugiat ratione assumenda
          earum quos iure illo tempore molestiae. Sed, reprehenderit quae in
          quo, recusandae optio at incidunt reiciendis delectus cupiditate
          consectetur assumenda quidem. Odit quidem magnam, dolor qui quas
          inventore, quis voluptatem aliquid saepe libero labore mollitia animi,
          expedita fugiat facilis in quasi? Exercitationem ab mollitia
          consectetur quasi labore minus nisi repellat. Doloribus excepturi
          debitis, praesentium hic architecto et, similique delectus vitae
          mollitia aspernatur, aperiam possimus illo. Libero nihil, accusantium
          cumque enim recusandae eum adipisci eligendi vel quaerat? Magnam
          temporibus atque fugit quidem? Ducimus, numquam nesciunt! Temporibus
          odio unde voluptates, totam inventore ex beatae et distinctio facilis
          sint, fugiat quod ea obcaecati illo iste. Odio, quibusdam id
          praesentium delectus culpa natus atque autem dolor, officiis veritatis
          harum, repellendus doloribus. Consequatur cum a laborum. Beatae illo,
          culpa officiis sapiente facere doloremque possimus repellendus sequi
          quibusdam incidunt, excepturi temporibus cum fuga laudantium ad optio
          vero atque accusamus aliquam eum. Sint totam voluptatibus illo
          corrupti rem, id quaerat repudiandae voluptatum distinctio similique
          nam suscipit quasi, beatae porro amet quis minima eveniet iusto
          repellendus, tempore asperiores! Sed cum eligendi, ipsum perferendis
          earum, eius pariatur eum labore aspernatur quaerat iure? Alias
          mollitia deserunt praesentium, doloremque dolor obcaecati maiores nisi
          molestias perspiciatis a, molestiae unde aspernatur soluta minus eum
          laborum eos. Reiciendis ut sit consectetur quam inventore voluptatibus
          dolore tenetur dolores eum repellendus molestiae exercitationem a,
          cumque vel repellat id animi quasi ullam itaque? Facilis voluptates
          culpa nisi rerum error ipsum! Deleniti numquam omnis nam libero nobis
          ipsum magni provident eos dolores cupiditate? Nobis dolore recusandae,
          rerum placeat, molestiae illum ad libero magni reiciendis minima
          architecto mollitia at explicabo sequi numquam sapiente, dolorum
          veritatis! Consequuntur necessitatibus et dignissimos a aut? Eum,
          cumque recusandae. Tenetur vitae vel optio, magni possimus consequatur
          eaque velit a hic numquam nobis quaerat tempore consequuntur enim
          iure, labore perferendis reiciendis suscipit. Veniam consequuntur ab
          tempora accusamus consequatur doloremque aliquam necessitatibus
          quaerat ipsam aut sit fuga temporibus voluptates mollitia debitis
          repellendus natus adipisci laudantium, eum nostrum dignissimos. Non
          deserunt harum ut veniam. Iusto distinctio autem ipsum quis eveniet
          fugiat blanditiis. Distinctio at aut suscipit harum? Veniam, amet
          facilis iure reiciendis adipisci veritatis libero. Ipsa deserunt velit
          culpa provident quas corporis vel modi asperiores labore inventore
          delectus aspernatur, voluptatibus consequatur sint totam laudantium
          maxime reiciendis dolorem, porro facere qui officiis! Et corrupti
          provident illum quaerat, eaque minus architecto a reiciendis qui
          excepturi consectetur corporis eum vero placeat ea ab rem rerum
          expedita est. Quasi asperiores tempore dolorum, eligendi laudantium,
          pariatur laborum a magnam nulla enim rem quas quibusdam ipsa earum
          possimus at? Sunt expedita itaque quo voluptate dolore dolorem eaque
          fugit deleniti velit molestias, optio consectetur harum adipisci, aut
          alias omnis eos cumque repellat qui! Fugiat vel, expedita vero ab
          commodi in illo animi, porro optio nisi hic cumque, doloribus dolorum!
          Laboriosam magni nesciunt iste vitae omnis voluptatem enim,
          consectetur facilis ab inventore iure nulla dignissimos unde impedit
          dicta ad odio, minus cupiditate ex neque nostrum perferendis. Est
          numquam deserunt perspiciatis impedit quos.
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Modal;
