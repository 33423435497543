import React from "react";

type Props = {
  bgColor: string;
  iconColor: string;
};

const AllIcon: React.FC<Props> = ({ bgColor, iconColor }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_261_286)">
        <rect x="4" width="47" height="47" rx="10" fill={bgColor} />
        <rect
          x="4.5"
          y="0.5"
          width="46"
          height="46"
          rx="9.5"
          stroke="url(#paint0_linear_261_286)"
        />
      </g>
      <path
        d="M15.875 15.75C15.875 14.7223 16.2833 13.7367 17.01 13.01C17.7367 12.2833 18.7223 11.875 19.75 11.875H35.25C36.2777 11.875 37.2633 12.2833 37.99 13.01C38.7167 13.7367 39.125 14.7223 39.125 15.75V31.25C39.125 32.2777 38.7167 33.2633 37.99 33.99C37.2633 34.7167 36.2777 35.125 35.25 35.125H19.75C18.7223 35.125 17.7367 34.7167 17.01 33.99C16.2833 33.2633 15.875 32.2777 15.875 31.25V15.75ZM28.7917 32.5417H35.25C35.5926 32.5417 35.9211 32.4056 36.1633 32.1633C36.4056 31.9211 36.5417 31.5926 36.5417 31.25V24.7917H28.7917V32.5417ZM26.2083 24.7917H18.4583V31.25C18.4583 31.5926 18.5944 31.9211 18.8367 32.1633C19.0789 32.4056 19.4074 32.5417 19.75 32.5417H26.2083V24.7917ZM28.7917 22.2083H36.5417V15.75C36.5417 15.4074 36.4056 15.0789 36.1633 14.8367C35.9211 14.5944 35.5926 14.4583 35.25 14.4583H28.7917V22.2083ZM26.2083 14.4583H19.75C19.4074 14.4583 19.0789 14.5944 18.8367 14.8367C18.5944 15.0789 18.4583 15.4074 18.4583 15.75V22.2083H26.2083V14.4583Z"
        fill={iconColor}
      />
      <defs>
        <filter
          id="filter0_d_261_286"
          x="0"
          y="0"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_261_286"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_261_286"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_261_286"
          x1="4"
          y1="0"
          x2="51"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={bgColor} />
          <stop offset="0.447916" stopColor={bgColor} stopOpacity="0" />
          <stop offset="1" stopColor={bgColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AllIcon;
