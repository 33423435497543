import * as vpsActionTypes from '../actionTypes/vpsActionTypes'
import {ReducerActions} from "../utilsTypes";
import {
  iVpsTariffs,
  myVmDetail,
  iVmMessage,
  iOsType, iOsTypeDetail
} from "../models";

interface stateTypes {
  loading: boolean,
  operationSystems: iOsType[],
  operationSystemsDetail: iOsTypeDetail[],
  vpsTariffs: iVpsTariffs[],
  vpsBilling: any,
  vpsConfig: any,
  vpsDocument: string,
  vpsBalance: any,
  myVps: any,
  myVpsDetail: myVmDetail | null,
  vmMsgDetail: iVmMessage | null
  vpsFinishDocument: string,
  vpsContractData: any,
  osType: iOsType[] | null,
  osTypeDetail: iOsTypeDetail | null,
  error: any
}

const initialState: stateTypes = {
  loading: false,
  operationSystems: [],
  operationSystemsDetail: [],
  vpsTariffs: [],
  vpsBilling: null,
  vpsConfig: null,
  vpsDocument: '',
  vpsFinishDocument: '',
  vpsBalance: null,
  myVps: [],
  myVpsDetail: null,
  vpsContractData: null,
  error: null,
  vmMsgDetail: null,
  osType: null,
  osTypeDetail: null
}

export const vpsReducer = (
  state = initialState,
  action: ReducerActions
): stateTypes => {
  const {type, payload} = action
  switch (type) {
    case vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_SUCCESS:
      return {...state, loading: false, operationSystems: payload}
    case vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_DETAIL_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_DETAIL_SUCCESS:
      const uniquePayload = action.payload.filter(
        (item: any) => !state.operationSystemsDetail.some((detail) => detail.image_id === item.id)
      );
      return {
        ...state,
        loading: false,
        operationSystemsDetail: [...state.operationSystemsDetail, ...uniquePayload],
      };
    case vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_DETAIL_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.GET_VPS_TARIFFS_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_VPS_TARIFFS_SUCCESS:
      return {...state, loading: false, vpsTariffs: payload}
    case vpsActionTypes.GET_VPS_TARIFFS_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.POST_VPS_BILLING_START:
      return {...state, loading: true}
    case vpsActionTypes.POST_VPS_BILLING_SUCCESS:
      return {...state, loading: false, vpsBilling: payload}
    case vpsActionTypes.POST_VPS_BILLING_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.POST_VPS_CONTRACT_START:
      return {...state, loading: true}
    case vpsActionTypes.POST_VPS_CONTRACT_SUCCESS:
      return {...state, loading: false, vpsDocument: payload}
    case vpsActionTypes.POST_VPS_CONTRACT_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.CREATE_CONTRACT_ADDITIONAL_AGREEMENT_START:
      return {...state, loading: true}
    case vpsActionTypes.CREATE_CONTRACT_ADDITIONAL_AGREEMENT_SUCCESS:
      return {...state, loading: false, vpsConfig: payload}
    case vpsActionTypes.CREATE_CONTRACT_ADDITIONAL_AGREEMENT_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.GET_VPS_BALANCE_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_VPS_BALANCE_SUCCESS:
      return {...state, loading: false, vpsBalance: payload}
    case vpsActionTypes.GET_VPS_BALANCE_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.CLEAR_VPS_BILLING:
      return {
        loading: false,
        operationSystems: [],
        operationSystemsDetail: [],
        vpsTariffs: [],
        vpsBilling: null,
        vpsConfig: null,
        vpsDocument: '',
        vpsFinishDocument: '',
        vpsBalance: null,
        myVps: [],
        myVpsDetail: null,
        vpsContractData: null,
        error: null,
        vmMsgDetail: null,
        osType: null,
        osTypeDetail: null
      }
    case vpsActionTypes.GET_MY_VPS_START:
      return { ...state, loading: true }
    case vpsActionTypes.GET_MY_VPS_SUCCESS:
      return {...state, loading: false, myVps: payload}
    case vpsActionTypes.GET_MY_VPS_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.GET_MY_VPS_DETAIL_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_MY_VPS_DETAIL_SUCCESS:
      return {...state, loading: false, myVpsDetail: payload}
    case vpsActionTypes.GET_MY_VPS_DETAIL_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.POST_VPS_FINISH_START:
      return {...state, loading: true}
    case vpsActionTypes.POST_VPS_FINISH_SUCCESS:
      return {...state, loading: false, vpsFinishDocument: payload}
    case vpsActionTypes.POST_VPS_FINISH_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.CLEAR_VPS_CALCULATION:
      return {...state, vpsBilling: payload}
    case vpsActionTypes.SAVE_VPS_CONTRACT_DATA:
      return {...state, vpsContractData: payload}
    case vpsActionTypes.CLEAR_VPS_CONTRACT_DATA:
      return {...state, vpsContractData: null}
    case vpsActionTypes.GET_MESSAGE_VM_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_MESSAGE_VM_FINISH:
      return {...state, loading: false, vmMsgDetail: payload}
    case vpsActionTypes.GET_MESSAGE_VM_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.GET_OS_TYPE_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_OS_TYPE_SUCCESS:
      return {...state, loading: false, osType: payload}
    case vpsActionTypes.GET_OS_TYPE_ERROR:
      return {...state, loading: false, error: payload}
    case vpsActionTypes.GET_OS_TYPE_DETAIL_START:
      return {...state, loading: true}
    case vpsActionTypes.GET_OS_TYPE_DETAIL_SUCCESS:
      return {...state, loading: false, osTypeDetail: payload}
    case vpsActionTypes.GET_OS_TYPE_DETAIL_ERROR:
      return {...state, loading: false, error: payload}
    default:
      return state
  }
}