import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {images} from "../../images/images";
import {switchLanguage} from "../../store/actions/languageAction";
import {useTranslation} from "react-i18next";
import i18n from "../../resources/i18n";
import {iGroupList} from "../../store/models";
import AllIcon from "../../images/allIcon";
import {getGroupList} from "../../store/actions/accountActions";
import MyRequestIcon from "../../images/MyRequestIcon";
import VmInActive from "../../images/VmInActive";
import VmActiveImg from "../../images/vmActiveImg";
import FavoriteIcon from "../../images/favoriteIcon";
import MyInfoIcon from "../../images/myInfoIcon";
import LogoutIcon from "../../images/logoutIcon";
import {clearAuthData, logoutHandler} from "../../store/actions/authActions";
import {cleanUserDetails} from "../../store/actions/contractActions";
import NotificationIcon from "../../images/NotificationIcon";

type Props = {
  type: "main" | "profile";
};

const HeaderMobile: React.FC<Props> = ({type}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  
  const {response, token, access, message} = useAppSelector((state) => state.auth);
  
  const {userDetail} = useAppSelector((state) => state.user);
  const {language} = useAppSelector((state) => state.language);
  const {groupList} = useAppSelector((state) => state.accounts);
  
  const headerRef = useRef<any>(null)
  
  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [notificationModal, setNotificationModal] = useState<boolean>(false)
  
  
  const arr = [
    {
      id: 1,
      name: t("myContracts"),
      icon: <MyRequestIcon bgColor="#0E0E4B" iconColor="#ffffff"/>,
      activeIcon: <MyRequestIcon bgColor="#ffffff" iconColor="#0E0E4B"/>,
      slug: "/my-request",
    },
    {
      id: 5,
      name: t("myVm"),
      icon: <VmInActive/>,
      activeIcon: <VmActiveImg/>,
      slug: "/my-vm",
    },
    {
      id: 2,
      name: t("savedServices"),
      icon: <FavoriteIcon bgColor="#0E0E4B" iconColor="#ffffff"/>,
      activeIcon: <FavoriteIcon bgColor="#ffffff" iconColor="#0E0E4B"/>,
      slug: "/favorite",
    },
    {
      id: 4,
      name: t("news"),
      icon: <MyInfoIcon bgColor="#0E0E4B" iconColor="#ffffff"/>,
      activeIcon: <MyInfoIcon bgColor="#ffffff" iconColor="#0E0E4B"/>,
      slug: "/news",
    },
    {
      id: 3,
      name: t("myInfo"),
      icon: <MyInfoIcon bgColor="#0E0E4B" iconColor="#ffffff"/>,
      activeIcon: <MyInfoIcon bgColor="#ffffff" iconColor="#0E0E4B"/>,
      slug: "/profile",
    },
  ];
  
  const getAuth = () => {
    window.location.replace(
      `https://api2.unicon.uz/api/oauth/oneid-login?path=${window.location.origin}`
    );
  };
  
  const stickyHeaderFunc = () => {
    const scrollElement = headerRef.current;
    window.addEventListener("scroll", () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        scrollElement.classList.add("sticky__header")
      } else {
        scrollElement.classList.remove("sticky__header")
      }
    })
  }
  
  useEffect(() => {
    dispatch(getGroupList());
  }, []);
  
  useEffect(() => {
    stickyHeaderFunc()
    return () => window.removeEventListener("scroll", stickyHeaderFunc)
  })
  
  const changeLng = (lng: any) => {
    if (lng != language) {
      dispatch(switchLanguage(lng));
      i18n.changeLanguage(lng);
    }
  };
  
  const handleNavigate = (slug: string, id: any) => {
    navigate(`/${slug}`, {state: {groupId: id}});
    setNavOpen(!navOpen)
  };
  
  const navigation = (id: string | null, type: number) => {
    navigate(`/news`, {state: {type, id}})
    setNotificationModal(!notificationModal)
  }
  
  const logout = () => {
    const refresh: any = localStorage.getItem('refresh_token')
    const body = {
      response,
      token,
      access,
    };
    navigate("/");
    dispatch(logoutHandler(body, access, JSON.parse(refresh ? refresh : 'null')));
    dispatch(clearAuthData(body));
    dispatch(cleanUserDetails(userDetail));
    setNavOpen(!navOpen)
    localStorage.clear()
  };
  
  navOpen ? document.body.classList.add('hidden') : document.body.classList.remove('hidden')
  
  return (
    <div className={`header`} ref={headerRef}>
      <div className="header_wrap">
        <div style={{display: 'flex'}}>
          <div className='menu-toggle' onClick={() => setNavOpen(!navOpen)}>
            <div className={navOpen ? 'hamBox hamBoxOpen' : 'hamBox'}>
              <span className={navOpen ? 'lineTop spin' : 'lineTop'}></span>
              <span className={navOpen ? 'lineBottom spin' : 'lineBottom'}></span>
            </div>
          </div>
          <button onClick={() => navigate("/")}>
            <img src={images.logo} alt="logo"/>
          </button>
        </div>
        {access ? (
          <div
            className="header_wrap-right_user header_wrap-right_lngBtns"
          >
            <button
              onClick={() => setNotificationModal(!notificationModal)}
              className={'notificationBtn'}
            >
              <span>{message?.count}</span>
              <NotificationIcon/>
            </button>
            <div className="header_wrap-right_user-cap" onClick={() => navigate("/profile")}>
              <span>{userDetail?.first_name?.charAt(0)}.</span>
              <span>{userDetail?.sur_name?.charAt(0)}.</span>
            </div>
            <div className="header_wrap-right_user-name" onClick={() => navigate("/profile")}>
              {userDetail?.first_name}
            </div>
            <div className="header_wrap-right_lngBtns">
              <select value={language} onChange={(event) => changeLng(event.target.value)}>
               <option value="uz">UZ</option>
               {/* <option value="ru">РУ</option> */}
               {/* <option value="en">EN</option> */}
              </select>
            </div>
          </div>
        ) : (
          <>
            <div className="header_wrap-right_lngBtns">
              <button className={'login'} onClick={getAuth}>
                {t("loginToSystem")}
              </button>
              <select value={language} onChange={(event) => changeLng(event.target.value)}>
               <option value="uz">UZ</option>
               {/* <option value="ru">РУ</option> */}
               {/* <option value="en">EN</option> */}
              </select>
            </div>
          </>
        )}
      </div>
      <div style={{opacity: notificationModal ? 1 : 0, pointerEvents: notificationModal ? 'all' : 'none'}}
           className='notificationModal'>
        <ul>
          {message?.datas?.map((item) => (
            <div
              onClick={() => navigation(item.id, item.type)}
              key={item.id}
            >
              {item.msg?.slice(0, 50)}...
            </div>
          ))}
        </ul>
      </div>
      <div
        className='nav-overlay'
        style={{
          top: navOpen ? "100px" : `-${window.innerHeight}px`,
          transitionDelay: navOpen ? "0s" : "0s",
          background: navOpen ? '#fff' : '',
          height: navOpen ? `${window.innerHeight}px` : 0
        }}
      >
        <ul className='nav-links'>
          <li className="nav-item" onClick={() => {
            navigate("/")
            setNavOpen(!navOpen)
          }}>
            <div className={"sidebar_menu-item"}>
              <div
                className="a"
                style={{
                  top: navOpen ? "0" : "150px",
                  transitionDelay: navOpen ? `0.3s` : "0s"
                }}
              >
                {location.pathname !== "/" && <div className="hover"></div>}
                <div
                  className={
                    location.pathname === "/"
                      ? "sidebar_menu-item-active"
                      : "sidebar_menu-item-icon"
                  }
                >
                  {location.pathname === "/" ? (
                    <AllIcon bgColor="#ffffff" iconColor="#0E0E4B"/>
                  ) : (
                    <AllIcon bgColor="#0E0E4B" iconColor="#ffffff"/>
                  )}
                </div>
                <div className="sidebar_menu-item-name">{t("allServices")}</div>
                <div className="nav-item-wrapper"></div>
              </div>
            </div>
          </li>
          {
            type === 'profile' && arr?.map((el, idx) => (
              <>
                <li
                  className={"nav-item"}
                  key={el.id}
                  onClick={() => {
                    navigate(el.slug)
                    setNavOpen(!navOpen)
                  }}
                >
                  <div className={"sidebar_menu-item"}>
                    <div
                      className='a'
                      style={{
                        top: navOpen ? "0" : "120px",
                        transitionDelay: navOpen ? `${(idx + 1) * 0.3}s` : "0s"
                      }}
                    >
                      {location.pathname !== `${el.slug}` && <div className="hover"></div>}
                      <div
                        className={
                          location.pathname === `${el.slug}`
                            ? "sidebar_menu-item-active"
                            : "sidebar_menu-item-icon"
                        }
                      >
                        {location.pathname === `${el.slug}`
                          ? <div>{el.activeIcon}</div>
                          : <div>{el.icon}</div>
                        }
                      </div>
                      <div className="sidebar_menu-item-name">{el.name}</div>
                    </div>
                  </div>
                  <div className="nav-item-wrapper"></div>
                </li>
              </>
            ))}
          {type === 'profile' && (
            <li className="nav-item" onClick={logout}>
              {location.pathname !== "/" && <div className="hover"></div>}
              <div className={'sidebar_menu-item'}>
                <div className={"sidebar_menu-item-icon"}>
                  <LogoutIcon bgColor="#0E0E4B" iconColor="#ffffff"/>
                </div>
                <div className="sidebar_menu-item-name">{t("logout")}</div>
              </div>
              <div className="nav-item-wrapper"></div>
            </li>
          )}
          {type === 'main' && (
            groupList && groupList?.map((el: iGroupList, idx: number) => (
              <li
                className={el.is_visible_in_front == true ? "nav-item" : "visually-hidden"}
                key={el.id}
                onClick={() => handleNavigate(el.slug, el.id)}
                style={{
                  display: el.is_visible_in_front == true ? 'block' : 'none'
                }}
              >
                <div className={el.is_visible_in_front == true ? "sidebar_menu-item" : "visually-hidden"}>
                  <div
                    className='a'
                    style={{
                      top: navOpen ? "0" : "120px",
                      transitionDelay: navOpen ? `${(idx + 1) * 0.3}s` : "0s"
                    }}
                  >
                    {location.pathname !== `/${el.slug}` && <div className="hover"></div>}
                    <div
                      className={
                        location.pathname === `/${el.slug}`
                          ? "sidebar_menu-item-active"
                          : "sidebar_menu-item-icon"
                      }
                    >
                      {location.pathname === `/${el.slug}`
                        ? <img src={el.active_icon} alt=""/>
                        : <img src={el.inactive_icon} alt=""/>
                      }
                    </div>
                    <div className="sidebar_menu-item-name">{el.name}</div>
                  </div>
                </div>
                <div className="nav-item-wrapper"></div>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  )
};

export default HeaderMobile;
