import React, { useState, useTransition } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import Play from "../../images/Play";

type Props = {
  close: () => void;
  video_instructions: any;
};

const SimpleModal: React.FC<Props> = ({ close, video_instructions }) => {
  const [video, setVideo] = useState("");
  const { t } = useTranslation();

  const handleClick = (e: string, b: string) => {
    b ? setVideo(b) : setVideo(e);
  };
  console.log(video, "123");
  return (
    <div className="modalWrap">
      <OutsideClickHandler onOutsideClick={close}>
        <div
          style={{ backgroundColor: "#fff" }}
          className={video === "" ? "p-1" : "hidden"}
        >
          <table className={"table table-striped w-100 m-auto"}>
            <thead style={{ backgroundColor: "#0e0e4b", color: "#fff" }}>
              <tr>
                <td colSpan={2}>{t("video_instruction_list")}:</td>
              </tr>
            </thead>
            <tbody>
              {video_instructions.map((e: any) => (
                <tr
                  key={e.id}
                  onClick={() => handleClick(e.video_doc, e.video_file)}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    {/* <button
                      className={"btn btn-outline-primary my-2"}
                      onClick={() => setVideo(e.video_doc)}
                    >
                      {t("see")}
                    </button> */}
                    <Play />
                  </td>
                  <td className="pt-3">{e.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={video !== "" ? "modalWrap_videowrap" : "hidden"}>
          {video.startsWith("https://www.youtube.com") ? (
            <iframe
              width="100%"
              height={500}
              src={video}
              title="video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <div style={{width: "80vw", margin: "0 auto"}}><video width={"100%"} height={500} controls autoPlay src={video} /></div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default SimpleModal;
