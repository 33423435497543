import React from "react";

const ShowReqIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9375 23.5625C20.9385 23.5625 21.75 22.751 21.75 21.75C21.75 20.749 20.9385 19.9375 19.9375 19.9375C18.9365 19.9375 18.125 20.749 18.125 21.75C18.125 22.751 18.9365 23.5625 19.9375 23.5625Z"
        fill="white"
      />
      <path
        d="M26.9854 21.2778C26.4255 19.8513 25.4592 18.6204 24.2064 17.7378C22.9536 16.8552 21.4693 16.3596 19.9375 16.3125C18.4057 16.3596 16.9214 16.8552 15.6686 17.7378C14.4158 18.6204 13.4495 19.8513 12.8896 21.2778L12.6875 21.75L12.8896 22.2222C13.4495 23.6487 14.4158 24.8796 15.6686 25.7622C16.9214 26.6448 18.4057 27.1404 19.9375 27.1875C21.4693 27.1404 22.9536 26.6448 24.2064 25.7622C25.4592 24.8796 26.4255 23.6487 26.9854 22.2222L27.1875 21.75L26.9854 21.2778ZM19.9375 25.375C19.2205 25.375 18.5197 25.1624 17.9236 24.7641C17.3274 24.3658 16.8628 23.7996 16.5884 23.1372C16.3141 22.4748 16.2423 21.746 16.3822 21.0428C16.522 20.3396 16.8673 19.6937 17.3742 19.1867C17.8812 18.6798 18.5271 18.3345 19.2303 18.1947C19.9335 18.0548 20.6623 18.1266 21.3247 18.4009C21.9871 18.6753 22.5533 19.1399 22.9516 19.7361C23.3499 20.3322 23.5625 21.033 23.5625 21.75C23.5613 22.711 23.179 23.6324 22.4994 24.3119C21.8199 24.9915 20.8985 25.3738 19.9375 25.375ZM6.34375 15.4062H10.875V17.2188H6.34375V15.4062ZM6.34375 10.875H17.2188V12.6875H6.34375V10.875ZM6.34375 6.34375H17.2188V8.15625H6.34375V6.34375Z"
        fill="white"
      />
      <path
        d="M19.9375 1.8125H3.625C3.14474 1.81393 2.68455 2.00535 2.34495 2.34495C2.00535 2.68455 1.81393 3.14474 1.8125 3.625V25.375C1.81393 25.8553 2.00535 26.3154 2.34495 26.655C2.68455 26.9946 3.14474 27.1861 3.625 27.1875H10.875V25.375H3.625V3.625H19.9375V13.5938H21.75V3.625C21.7486 3.14474 21.5571 2.68455 21.2175 2.34495C20.8779 2.00535 20.4178 1.81393 19.9375 1.8125Z"
        fill="white"
      />
    </svg>
  );
};

export default ShowReqIcon;
