import { combineReducers } from "redux";
import { accountsReducer } from "./accountsReducer";
import { AuthReducer } from "./authReducer";
import {
  contractDetailReducer,
  contractsReducer,
  userDetailReducer,
} from "./contractsReducer";
import { languageReducer } from "./languageReducer";
import {userServiceReducer} from "./changeReducer";
import {vpsReducer} from "./vpsReducer";
import {emailReducer} from "./EMailReducer";

export const rootReducer = combineReducers({
  auth: AuthReducer,
  contracts: contractsReducer,
  user: userDetailReducer,
  accounts: accountsReducer,
  contractDetails: contractDetailReducer,
  language: languageReducer,
  userService: userServiceReducer,
  vps: vpsReducer,
  email: emailReducer
});
