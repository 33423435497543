import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import Sidebar from "../../components/Sidebar/Sidebar";
import {getMessage} from "../../store/actions/authActions";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import LoadingPage from "../LoadingPage/LoadingPage";
import ModalMsg from "./ModalMsg";
import DoubleCheck from "../../images/DoubleCheck";
import Table from "@mui/material/Table";
import Footer from "../../components/Footer/Footer";
import Check from "../../images/Check";

const NewsPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  
  const {access, message, loading} = useAppSelector((state) => state.auth);
  
  const [modalMsg, setModalMsg] = useState<boolean>(false)
  const [id, setId] = useState<string | null>('')
  const [type, setType] = useState<number | null>(null)
  
  useEffect(() => {
    dispatch(getMessage(access, true))
    if (location.state !== null) {
      setId(location?.state?.id)
      setType(location?.state?.type)
      setModalMsg(!modalMsg)
    }
  }, [access, dispatch, location?.state]);
  
  if (loading) return <LoadingPage/>
  
  return (
    <>
      <div className='newsPage'>
        <Sidebar active={location.pathname} type="profile"/>
        <div className='newsPage_wrap'
             style={{height: !(message?.datas && message.datas.length >= 8) ? `calc(${window.innerHeight}px - 280px)` : '100%'}}>
          <TableContainer>
            <Table className='muiTable'>
              <TableHead className='muiTable_head'>
                <TableRow>
                  <TableCell className='muiTable_head-cell'>№</TableCell>
                  <TableCell className='muiTable_head-cell'>{t('news')}</TableCell>
                  <TableCell className='muiTable_head-cell'>{t("contractDate")}</TableCell>
                  <TableCell className='muiTable_head-cell'>{t("manage")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {message?.datas?.map((item, i) => (
                  <TableRow className="muiTable_body-row position-relative" key={item.id}>
                    <TableCell className="muiTable_body-row-cell">{i + 1}</TableCell>
                    <TableCell className="muiTable_body-row-cell"
                               style={{width: '50%'}}>{item.msg?.slice(0, 100)}...</TableCell>
                    <TableCell
                      className="muiTable_body-row-cell">{moment(item.created_time).format('DD-MM-YYYY')}</TableCell>
                    <TableCell
                      className="muiTable_body-row-cell"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setId(item.id)
                        setType(item.type)
                        setModalMsg(!modalMsg)
                      }}
                    >
                      {item.is_active ? <Check/> : <DoubleCheck/>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="newsPage_div"></div>
      </div>
      {modalMsg &&
        <ModalMsg
          close={() => {
            setModalMsg(!modalMsg)
            dispatch(getMessage(access, true))
          }}
          id={id}
          type={type}
        />
      }
      {/* <Footer/> */}
    </>
  );
};

export default NewsPage;