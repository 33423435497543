import React from "react";

type Props = {
  color: string;
}

const SavedIcon: React.FC<Props> = ({color}) => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H15C16.6569 0 18 1.34315 18 3V23L9 17L0 23V3Z"
        fill={color}
      />
    </svg>
  );
};

export default SavedIcon;
