import {ReducerActions} from "../utilsTypes";
import * as changeTypes from "../actionTypes/changeTypes";

interface UserServiceTypes {
  change: any;
  serv: any;
}
const userServiceState: UserServiceTypes = {
  change: '',
  serv: '',
};
export const userServiceReducer = (
  state = userServiceState,
  action: ReducerActions
): UserServiceTypes => {
  const { type, payload } = action;
  switch (type) {
    case changeTypes.TYPING:
      return {
        serv: {
          ...state.serv,
          [payload.name]: payload.value
        },
        change: {
          ...state.change,
          [payload.name]: payload.value
        },
      }
      case changeTypes.INITIAL_STATE:
        return userServiceState
    default:
      return state;
  }
};
