import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ServiceBlock from "../../components/ServiceBlock/ServiceBlock";
import Sidebar from "../../components/Sidebar/Sidebar";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {deleteSavedService, getSavedServices} from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import {useTranslation} from "react-i18next";
import Footer from "../../components/Footer/Footer";

const FavoritePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {access} = useAppSelector(state => state.auth);
  const {savedServices, loading} = useAppSelector(state => state.contracts);

  const handleDeleteSaveService = (id: number) => {
    dispatch(deleteSavedService(id, access));
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(getSavedServices(access));
  }, [])
  
  if (loading) return <LoadingPage/>;

  return (
    <>
      <div className="favorite">
        <Sidebar active={location.pathname} type="profile"/>
        <div className="favorite_wrap" style={{height: savedServices.length >= 6 ? '100%' : `calc(${window.innerHeight}px - 230px)`}}>
          {/*<div className="favorite_wrap-titleWrap">*/}
          {/*  <div className="favorite_wrap-titleWrap-title">*/}
          {/*    {t("savedServices")}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="favorite_wrap-services">
            {savedServices.map((el) => (
              <ServiceBlock
                key={el.id}
                name={el.name}
                descr={el.description}
                icon={el.image}
                favorite={el.is_saved}
                visible={el.group.is_visible_in_front}
                token={access}
                setSaved={() => handleDeleteSaveService(el.id)}
                navigate={() => navigate(`/service/${el.id}`, {
                  state: {
                    slug: el
                  }
                })}
              />
            ))}
          </div>
        </div>
        <div className="profile_div"></div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default FavoritePage;
