import React from "react";

type Props = {
  color: string;
};

const Arrow: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="24"
      height="8"
      viewBox="0 0 24 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64644L20.1716 0.464464C19.9763 0.269202 19.6597 0.269202 19.4645 0.464464C19.2692 0.659727 19.2692 0.976309 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.73079 19.9763 7.73079 20.1716 7.53553L23.3536 4.35355ZM1 4.5L23 4.5L23 3.5L1 3.5L1 4.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;
