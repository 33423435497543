import React from "react";

type Props = {
  bgColor: string;
  iconColor: string;
};

const MyInfoIcon: React.FC<Props> = ({ bgColor, iconColor }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_225)">
        <rect x="4" width="47" height="47" rx="10" fill={bgColor} />
        <rect
          x="4.5"
          y="0.5"
          width="46"
          height="46"
          rx="9.5"
          stroke="url(#paint0_linear_1_225)"
        />
      </g>
      <g clipPath="url(#clip0_1_225)">
        <path
          d="M27.5131 27.3234C26.3377 27.3234 25.3818 26.3417 25.3818 25.1275V21.8467C25.3818 20.6325 26.3377 19.6509 27.5131 19.6509C28.6885 19.6509 29.6444 20.6325 29.6444 21.8467V25.1275C29.6444 26.3417 28.6885 27.3234 27.5131 27.3234Z"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M33.9455 25.4504C33.6872 28.8733 30.8972 31.56 27.513 31.56C23.948 31.56 21.0547 28.5891 21.0547 24.9208V22.0791C21.0547 18.4108 23.948 15.4399 27.513 15.4399C30.8584 15.4399 33.6097 18.062 33.9326 21.4075"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M18.8717 17.3258C20.4733 14.0967 23.7542 11.875 27.5 11.875C32.8217 11.875 37.1875 16.3571 37.1875 21.8338V25.1533C37.1875 30.63 32.8346 35.1121 27.5 35.1121C22.1783 35.1121 17.8125 30.63 17.8125 25.1533V21.8338"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_225"
          x="0"
          y="0"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_225"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_225"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_225"
          x1="4"
          y1="0"
          x2="51"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={iconColor} />
          <stop offset="0.447916" stopColor={iconColor} stopOpacity="0" />
          <stop offset="1" stopColor={iconColor} />
        </linearGradient>
        <clipPath id="clip0_1_225">
          <rect
            width="31"
            height="31"
            fill={iconColor}
            transform="translate(12 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MyInfoIcon;
