import React from "react";
import NotSavedIcon from "../../images/notSavedIcon";
import SavedIcon from "../../images/savedIcon";

type Props = {
  name: string;
  descr: string;
  navigate: () => void;
  favorite?: boolean;
  icon?: string;
  setSaved?: () => void;
  token?: any;
  visible: boolean,
  key: number
};

const ServiceBlock: React.FC<Props> = ({
  name,
  descr,
  navigate,
  favorite = false,
  icon,
  setSaved,
  token,
  visible,
  key
}) => {

  const handleNavigate = () => {
    if(name === "Hosting") {
      return null;
    } else
    return navigate()
  }

  return (
    <div
      key={Math.floor(key)}
      className="service"
      style={{
        opacity: name === "Hosting" ? 0.5 : 1,
        display: visible ? 'block' : 'none'
      }}
    >
      <div className="service_head">
        <img src={icon} alt="service-icon" onClick={handleNavigate} />
        {token && (
          <>
            <div className="hover" onClick={setSaved}>
              {!favorite ? (
                <NotSavedIcon color="#0E0E4B" />
              ) : (
                <SavedIcon color="#0E0E4B" />
              )}
            </div>
            <div className="not-hover" onClick={setSaved}>
              {!favorite ? (
                <NotSavedIcon color="#ffffff" />
              ) : (
                <SavedIcon color="#ffffff" />
              )}
            </div>
          </>
        )}
      </div>
      <div className="title" onClick={handleNavigate}>
        {name}
      </div>
      <div className="text" onClick={handleNavigate}>
        {descr.slice(0, 33)}{descr.length > 0 && "..."}
      </div>
      {/*<div className="link" onClick={handleNavigate}>*/}
      {/*  <span>{t("inDetail")}</span>*/}
      {/*  <div className="link_hover">*/}
      {/*    <Arrow color="#ffffff" />*/}
      {/*  </div>*/}
      {/*  <div className="link_not-hover">*/}
      {/*    <Arrow color="#0E0E4B" />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default ServiceBlock;
