export const ACCOUNTS_LOADING = "ACCOUNTS_LOADING";
export const ACCOUNTS_ERROR = "ACCOUNTS_ERROR";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const UPDATE_FIZUSER = "UPDATE_FIZUSER";
export const UPDATE_YURUSER = "UPDATE_YURUSER";
export const GET_BANK = "GET_BANK";
export const GET_CURRENT_TIME = "GET_CURRENT_TIME";
export const CURRENT_TIME_LOADING = "CURRENT_TIME_LOADING";
export const MESSAGE_DETAIL_START = "MESSAGE_DETAIL_START";
export const MESSAGE_DETAIL_FULL = "MESSAGE_DETAIL_FULL";
export const MESSAGE_DETAIL_ERROR = "MESSAGE_DETAIL_ERROR";
