import {Paper, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import React, {useEffect} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {useAppSelector} from "../../hooks/redux";
import LoadingPage from "../../pages/LoadingPage/LoadingPage";
import {useTranslation} from "react-i18next";

type Props = {
  close: () => void;
};

const VMmodal: React.FC<Props> = ({close}) => {
  
  const {myVpsDetail, loading} = useAppSelector((state) => state.vps)
  const {t} = useTranslation()
  
  const status = () => {
    if (myVpsDetail?.responce?.info.cstatus === "0") {
      return t('vm_status_0')
    } else if (myVpsDetail?.responce.info.cstatus === "1") {
      return t('vm_status_1')
    } else if (myVpsDetail?.responce.info.cstatus === "2") {
      return t('vm_status_2')
    } else if (myVpsDetail?.responce.info.cstatus === "3") {
      return t('vm_status_3')
    } else if (myVpsDetail?.responce.info.cstatus === "4") {
      return t("vm_status_4")
    }
  }
  
  useEffect(() => {
    status()
  }, []);
  
  if (loading) return <LoadingPage/>
  
  return (
    <div className="modalWrap">
      <div className="modalWrap_textWrap">
        {(myVpsDetail?.err_id === 0 && myVpsDetail?.responce.info) && (
          <>
            <h2>{myVpsDetail?.responce.info.name}</h2>
            <TableContainer component={Paper} style={{marginTop: 30, display: "flex", alignItems: "flex-start"}}>
              <Table style={{marginRight: 50}} className="muiTable vm_modal">
                <TableRow>
                  <TableCell className="muiTable_head-cell">Xotira turi</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.disk_t}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">HDD</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.disk_s} GB</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Status</TableCell>
                  <TableCell className="muiTable_head-cell">{status()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Sanasi</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.cbdate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Ip</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.inet_ip}</TableCell>
                </TableRow>
                {/*<TableRow>*/}
                {/*  <TableCell className="muiTable_head-cell">Mac</TableCell>*/}
                {/*  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.mac}</TableCell>*/}
                {/*</TableRow>*/}
              </Table>
              
              <Table style={{marginRight: 50}} className="muiTable vm_modal">
                {/*<TableRow>*/}
                {/*  <TableCell className="muiTable_head-cell">VM_CBDATE</TableCell>*/}
                {/*  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info[0].vm_cbdate}</TableCell>*/}
                {/*</TableRow>*/}
                <TableRow>
                  <TableCell className="muiTable_head-cell">Ram</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.ram}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Cpu</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.cpu}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Internet</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.inet} Mb/s</TableCell>
                </TableRow>
                {myVpsDetail.responce.info.disks !== undefined && myVpsDetail.responce.info.disks.length !== 0 ? myVpsDetail.responce.info.disks.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>Qoshimcha Xotira N{i + 1}</TableCell>
                    <TableCell className="muiTable_head-cell">
                      {item.name}, {item.type}, {item.size}Gb
                    </TableCell>
                  </TableRow>
                )) : (
                  <></>
                )}
                {/*<TableRow>*/}
                {/*  <TableCell className="muiTable_head-cell">Xotiralar</TableCell>*/}
                {/*  {myVpsDetail.responce.info.disks.length !== 0 ? myVpsDetail.responce.info.disks.map((item, i) => (*/}
                {/*    <TableCell className="muiTable_head-cell">*/}
                {/*      {item.name}, {item.type}, {item.size} Gb*/}
                {/*    </TableCell>*/}
                {/*  )) : (*/}
                {/*    <></>*/}
                {/*  )}*/}
                {/*</TableRow>*/}
              </Table>
              
              <Table className="muiTable vm_modal">
                <TableRow>
                  <TableCell className="muiTable_head-cell">TasIx</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.tasix} Mb/s</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Imut</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.imut}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">Operatsion tizim nomi</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.os_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="muiTable_head-cell">{t("operating_system")}</TableCell>
                  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.cas_ostype}</TableCell>
                </TableRow>
                {/*<TableRow>*/}
                {/*  <TableCell className="muiTable_head-cell">Vm Nomi</TableCell>*/}
                {/*  <TableCell className="muiTable_head-cell">{myVpsDetail.responce.info.net_name}</TableCell>*/}
                {/*</TableRow>*/}
              </Table>
            </TableContainer>
          </>
        )}
        
        <div className="modalWrap_block-btnBlock" style={{justifyContent: 'center'}}>
          <button onClick={close}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default VMmodal;
