import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Steps from "../../components/Steps/Steps";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Arrow2 from "../../images/arrow2";
import {
  getDataCenterCalculate, getDataCenterList,
  getTarifsElementsDevices, refreshUserDetail,
} from "../../store/actions/contractActions";
import {useTranslation} from "react-i18next";
import {
  getBank,
} from "../../store/actions/accountActions";
import {UserDetailHandler} from "../../hooks/handlers";
import DropIcon from "../../images/dropIcon";
import {iCalculateCoLocation} from "../../store/models";
import ReverseIcon from "../../images/ReverseIcon";

const ContractInfoPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  
  const dataStateJSON = localStorage.getItem('coLocation_data')
  const service_id = localStorage.getItem('service_id')
  const {group_id, name} = location.state;
  
  const {
    vpsConfig,
  } = useAppSelector(state => state.vps)
  const {calculateDataCenter, listDataCenter} = useAppSelector(
    (state) => state.contracts
  );
  const {access} = useAppSelector((state) => state.auth);
  const {userDetail} = useAppSelector((state) => state.user);
  
  const [data, setData] = useState<iCalculateCoLocation[]>(dataStateJSON ? JSON.parse(dataStateJSON || '[]') : [
    {data_center: '', mounting_type: '', amount: null, status: 1}
  ])
  
  const [selectedCombinations, setSelectedCombinations] = useState<any>({});
  
  const [openContract, setOpenContract] = useState<boolean>(true);
  const [vatNumber, setVatNumber] = useState<string | undefined>(userDetail?.vatNumber)
  const [stir, setStir] = useState<string | undefined>(userDetail?.tin)
  
  const [openRek, setOpenRek] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userDetail?.mob_phone_no
  );
  const [address, setAddress] = useState<string | undefined>(
    userDetail?.per_adr
  );
  const [email, setEmail] = useState<string | undefined>(userDetail?.email)
  const [mfo, setMfo] = useState<string | undefined>(userDetail?.bank_mfo?.mfo);
  const [xxtut, setXxtut] = useState<string | undefined>(userDetail?.xxtut);
  const [ktut, setKtut] = useState<string | undefined>(userDetail?.ktut);
  const [ifut, setIfut] = useState<string | undefined>(userDetail?.oked);
  const [checkingAccount, setCheckingAccount] = useState<string | undefined>(
    userDetail?.paymentAccount
  );
  const [bank, setBank] = useState<string | undefined>("");
  const [directorName, setDirectorName] = useState<string | undefined>(
    userDetail?.director_firstname
  );
  const [directorSurname, setDirectorSurname] = useState<string | undefined>(
    userDetail?.director_lastname
  );
  const [directorMidname, setDirectorMidname] = useState<string | undefined>(
    userDetail?.director_middlename
  );
  
  useEffect(() => {
    if (vpsConfig?.error_code === 1) {
      const dataObjects = vpsConfig?.colocation?.map((item: any) => ({
        data_center: item?.data_center,
        mounting_type: item?.mounting_type,
        amount: item?.amount,
        status: 4,
        id: item?.id
      }))
      setData(dataObjects)
      getCalculate(dataObjects)
      localStorage.setItem('coLocation_data', JSON.stringify(dataObjects))
    }
  }, [vpsConfig])
  
  useEffect(() => {
    if (userDetail?.bank_mfo) {
      dispatch(getBank(access, mfo));
    }
  }, [access, dispatch, mfo, userDetail?.bank_mfo]);
  
  useEffect(() => {
    if (dataStateJSON) {
      const storedServerState = JSON.parse(dataStateJSON ? dataStateJSON : '[]');
      setData(storedServerState);
    }
  }, [dataStateJSON]);
  
  useEffect(() => {
    dispatch(getDataCenterList(access))
    dispatch(getTarifsElementsDevices(group_id, access));
    // getCalculate(dataStateJSON ? JSON.parse(dataStateJSON ? dataStateJSON : '[]') : data)
  }, [access, dispatch, group_id]);
  
  useEffect(() => {
    if (!handleValidateForCalculate()) return getCalculate(dataStateJSON ? JSON.parse(dataStateJSON ? dataStateJSON : '[]') : data)
  }, [data, dataStateJSON]);
  
  const sendMfo = () => {
    dispatch(getBank(access, mfo));
  };
  
  const handleDataAdd = () => {
    const abc = [...data, {data_center: '', mounting_type: '', amount: null, status: 1, id: ''}]
    localStorage.setItem('coLocation_data', JSON.stringify(abc))
    setData(abc)
  }
  
  const timeoutId: any = useRef(null)
  const getCalculate = (data: any) => {
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      dispatch(getDataCenterCalculate(access, data, handleValidateForCalculate()))
    }, 200)
  }
  
  const handleDeleteData = (i: number) => {
    const deletedData: iCalculateCoLocation[] = [...data]
    if (vpsConfig?.error_code === 1 && deletedData[i].id !== '') {
      deletedData[i].status = 2
      localStorage.setItem('coLocation_data', JSON.stringify(deletedData))
      setData(deletedData)
      getCalculate(deletedData)
    } else {
      deletedData.splice(i, 1)
      setData(deletedData)
      getCalculate(deletedData)
    }
  }
  
  const recoveryConfig = (i: number) => {
    const updatedData = [...data]
    if (
      updatedData[i].data_center !== vpsConfig?.colocation[i].data_center ||
      updatedData[i].amount !== vpsConfig?.colocation[i].amount ||
      updatedData[i].mounting_type !== vpsConfig?.colocation[i].mounting_type
    ) {
      updatedData[i].status = 3
      localStorage.setItem('coLocation_data', JSON.stringify(updatedData))
      setData(updatedData)
      getCalculate(updatedData)
    } else {
      updatedData[i].status = 4
      localStorage.setItem('coLocation_data', JSON.stringify(updatedData))
      setData(updatedData)
      getCalculate(updatedData)
    }
  }
  
  const handleChangeData = (e: any, index: number) => {
    const {name, value} = e.target;
    let newData = [...data];
    newData[index] = {...newData[index], [name]: value};
    localStorage.setItem('coLocation_data', JSON.stringify(newData))
    
    if (name === 'amount') {
      newData[index].amount = Number(value)
      localStorage.setItem('coLocation_data', JSON.stringify(newData))
    }
    
    let combination = `${newData[index].data_center}-${newData[index].mounting_type}`;
    let newSelectedCombinations = {...selectedCombinations};
    if (name === "mounting_type") {
      let oldCombination = `${newData[index].data_center}-${data[index].mounting_type}`;
      delete newSelectedCombinations[oldCombination];
    }
    newSelectedCombinations[combination] = index;
    
    if (vpsConfig?.error_code === 1 && newData[index].status === 4) {
      newData[index].status = 3
      localStorage.setItem('coLocation_data', JSON.stringify(newData))
      getCalculate(newData)
    }
    
    setData(newData);
    setSelectedCombinations(newSelectedCombinations);
    getCalculate(newData)
  };
  
  const checkForDuplicateSelections = () => {
    let hasDuplicates = false;
    let combinations: any = {};
    
    for (let i = 0; i < data.length; i++) {
      let combination = `${data[i].data_center}-${data[i].mounting_type}`;
      if (combinations[combination]) {
        hasDuplicates = true;
        break;
      }
      combinations[combination] = true;
    }
    
    return hasDuplicates;
  };
  
  const handleValidateForCalculate = () => {
    if (checkForDuplicateSelections()) {
      return true;
    }
    for (const currentData of data) {
      if (
        !currentData?.amount ||
        !currentData?.data_center ||
        !currentData?.mounting_type
      ) {
        return true
      }
    }
    return false
  }
  
  const handleValidate = () => {
    if (checkForDuplicateSelections()) {
      return true;
    }
    for (const currentData of data) {
      if (
        !calculateDataCenter?.success ||
        !currentData?.amount ||
        !currentData?.data_center ||
        !currentData?.mounting_type
      ) {
        return true
      }
    }
    return false
  }
  
  const handleCancel = () => {
    localStorage.removeItem('coLocation_data')
    navigate('/')
  };
  
  const handleCreateContract = () => {
    navigate("/agreement", {state: {group_id, service_id, name}});
    localStorage.setItem('coLocation_data', JSON.stringify(data))
    localStorage.setItem('service_id', group_id)
  };
  
  const valid = (e: any, setState: any) => {
    const re = /^[a-zA-Z' ]*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState(e.target.value);
    }
  };
  
  const handleValidateYurUser = () => {
    if (!phoneNumber?.length) return true;
    if (!mfo?.length) return true;
    if (!ifut?.length) return true;
    if (!checkingAccount?.length) return true;
    if (!bank?.length) return true;
    if (!directorName?.length) return true;
    if (!directorSurname?.length) return true;
    if (!directorMidname?.length) return true;
    if (!email?.length) return true;
  };
  const handleValidateFizUser = () => {
    if (!phoneNumber?.length) return true;
    if (!address?.length) return true;
    if (!email?.length) return true;
  };
  
  const sendStir = () => {
    dispatch(refreshUserDetail(access)).then(() => window.location.reload())
  }
  const sendVatNumber = () => {
    dispatch(refreshUserDetail(access)).then(() => window.location.reload())
  }
  
  return (
    <div className="container">
      <div className="request">
        <Steps activeStep={2}/>
        <div className="request_content">
          <div>
            <h2 className="contractInfo_title">{t("props")}</h2>
            <div className="contractInfo_drop">
              <div
                className="contractInfo_drop-head"
                onClick={() => setOpenRek(!openRek)}
              >
                <div className="contractInfo_drop-head-title">{t("props")}</div>
                <div
                  className="contractInfo_drop-head-icon"
                  style={{transform: openRek ? "rotate(-180deg)" : ""}}
                >
                  <DropIcon/>
                </div>
              </div>
              {openRek && (
                <>
                  <UserDetailHandler
                    setStir={(e: any) => setStir(e.target.value)}
                    sendStir={sendStir}
                    vatNumber={vatNumber}
                    setVatNumber={(e: any) => setVatNumber(e.target.value)}
                    sendVatNumber={sendVatNumber}
                    email={email}
                    setEmail={(e: any) => setEmail(e.target.value)}
                    sendMfo={sendMfo}
                    detail={userDetail}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={(e: any) => setPhoneNumber(e.target.value)}
                    address={address}
                    setAddress={(e: any) => setAddress(e.target.value)}
                    mfo={mfo}
                    setMfo={(e: any) => setMfo(e.target.value)}
                    xxtut={xxtut}
                    setXxtut={(e: any) => setXxtut(e.target.value)}
                    ktut={ktut}
                    setKtut={(e: any) => setKtut(e.target.value)}
                    ifut={ifut}
                    setIfut={(e: any) => setIfut(e.target.value)}
                    checkingAccount={checkingAccount}
                    setCheckingAccount={(e: any) => setCheckingAccount(e.target.value)}
                    bank={bank}
                    setBank={(e: any) => valid(e, setBank)}
                    directorName={directorName}
                    setDirectorName={(e: any) => valid(e, setDirectorName)}
                    directorSurname={directorSurname}
                    setDirectorSurname={(e: any) => valid(e, setDirectorSurname)}
                    directorMidname={directorMidname}
                    setDirectorMidname={(e: any) => valid(e, setDirectorMidname)}
                  />
                  <div className="profile_wrap-form-block-btn">
                    <button
                      className={'px-3'}
                      onClick={() => navigate('/profile')}
                      disabled={false}
                      style={{opacity: handleValidateYurUser() === undefined ? 1 : 1, whiteSpace: 'nowrap'}}
                    >
                      {t("edit")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{marginTop: 20}}>
            <h2 className='contractInfo_title'>{t("contract_info")}</h2>
            <div className='contractInfo_drop'>
              <div
                className="contractInfo_drop-head"
                onClick={() => setOpenContract(!openContract)}
              >
                <div className="contractInfo_drop-head-title">{t("contract_info")}</div>
                <div
                  className="contractInfo_drop-head-icon"
                  style={{transform: openContract ? "rotate(-180deg)" : ""}}
                >
                  <DropIcon/>
                </div>
              </div>
              
              {openContract && (
                <div>
                  {data?.map((el, i) => (
                    <div key={i} style={{marginTop: 40}}>
                      <div style={{display: 'flex', marginLeft: 'auto', width: '3%'}}>
                        {el.status === 2 && (
                          <button
                            className='delete_btn'
                            onClick={() => recoveryConfig(i)}
                          >
                            <ReverseIcon/>
                          </button>
                        )}
                      </div>
                      {el.status !== 2 && (
                        <>
                          <div style={{display: 'flex', marginLeft: 'auto', width: '3%'}}>
                            {el.status !== 2 && (
                              <button
                                style={{
                                  background: 'transparent',
                                  outline: 'none',
                                  border: 'none'
                                }}
                                className='delete_btn'
                                onClick={() => handleDeleteData(i)}
                                disabled={data.length === 1}
                              >
                                <svg width="22" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_594_1386)">
                                    <path
                                      d="M18.0934 22.6663H5.90675C5.65021 22.6603 5.39738 22.6037 5.1627 22.4999C4.92803 22.3961 4.7161 22.2471 4.53903 22.0614C4.36197 21.8756 4.22324 21.6568 4.13076 21.4175C4.03829 21.1781 3.99388 20.9229 4.00008 20.6663V7.48633H5.33342V20.6663C5.32705 20.7478 5.33685 20.8297 5.36226 20.9074C5.38767 20.985 5.42818 21.0569 5.48146 21.1189C5.53474 21.1808 5.59974 21.2316 5.67273 21.2684C5.74572 21.3051 5.82525 21.3271 5.90675 21.333H18.0934C18.1749 21.3271 18.2544 21.3051 18.3274 21.2684C18.4004 21.2316 18.4654 21.1808 18.5187 21.1189C18.572 21.0569 18.6125 20.985 18.6379 20.9074C18.6633 20.8297 18.6731 20.7478 18.6667 20.6663V7.48633H20.0001V20.6663C20.0063 20.9229 19.9619 21.1781 19.8694 21.4175C19.7769 21.6568 19.6382 21.8756 19.4611 22.0614C19.2841 22.2471 19.0721 22.3961 18.8375 22.4999C18.6028 22.6037 18.35 22.6603 18.0934 22.6663Z"
                                      fill="#0E0E4B"/>
                                    <path
                                      d="M20.5201 5.99935H3.33341C3.1566 5.99935 2.98703 5.92911 2.86201 5.80409C2.73699 5.67906 2.66675 5.50949 2.66675 5.33268C2.66675 5.15587 2.73699 4.9863 2.86201 4.86128C2.98703 4.73625 3.1566 4.66602 3.33341 4.66602H20.5201C20.6969 4.66602 20.8665 4.73625 20.9915 4.86128C21.1165 4.9863 21.1867 5.15587 21.1867 5.33268C21.1867 5.50949 21.1165 5.67906 20.9915 5.80409C20.8665 5.92911 20.6969 5.99935 20.5201 5.99935Z"
                                      fill="#0E0E4B"/>
                                    <path d="M14 8.66602H15.3333V18.666H14V8.66602Z" fill="#0E0E4B"/>
                                    <path d="M8.66675 8.66602H10.0001V18.666H8.66675V8.66602Z" fill="#0E0E4B"/>
                                    <path
                                      d="M15.3334 3.90732H14.0667V2.66732H9.93342V3.90732H8.66675V2.66732C8.66632 2.32495 8.79761 1.99553 9.03342 1.74732C9.26922 1.4991 9.59147 1.3511 9.93342 1.33398H14.0667C14.4087 1.3511 14.7309 1.4991 14.9668 1.74732C15.2026 1.99553 15.3338 2.32495 15.3334 2.66732V3.90732Z"
                                      fill="#0E0E4B"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_594_1386">
                                      <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                            )}
                          </div>
                          <div className={'pay'} style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                            <label htmlFor="pay">{t("address")}</label>
                            <select
                              className={'select'}
                              value={el.data_center}
                              onChange={(e) => handleChangeData(e, i)}
                              name="data_center"
                              id="address"
                            >
                              <option value="">{t("select")}</option>
                              {listDataCenter && listDataCenter.map((item, index: number) => (
                                <option value={item.id} key={index}>{item.display_name}</option>
                              ))}
                            </select>
                          </div>
                          <div className={'pay'} style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                            <label htmlFor="tariff">{t("selectServiceType")}</label>
                            <select
                              className={'select'}
                              value={el.mounting_type}
                              onChange={(e) => handleChangeData(e, i)}
                              name="mounting_type" id="tariff"
                            >
                              <option value="">{t("select")}</option>
                              <option value="RACK">Rack</option>
                              <option value="UNIT">Unit</option>
                            </select>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px'}}>
                            <label htmlFor="count">{t("enterCount")}</label>
                            <input
                              name={'amount'}
                              value={el.amount || ''}
                              onChange={(e) => handleChangeData(e, i)}
                              type="text"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {checkForDuplicateSelections() && (
                    <div style={{marginTop: 10, color: 'red'}}>
                      {t("cant_choose_same_err_msg")}
                    </div>
                  )}
                  <div style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '15px',
                    justifyContent: 'space-between',
                    alignItems: 'end'
                  }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '15px',
                      }}
                      className={'request_content-buttons'}
                    >
                      <button
                        onClick={handleDataAdd}
                        disabled={handleValidate()}
                        style={{opacity: handleValidate() ? 0.5 : 1}}
                      >
                        {t("add")}
                      </button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '15px',
                        width: '20%'
                      }}
                    >
                      <label htmlFor="count">{t("total")}</label>
                      <input
                        value={`${calculateDataCenter?.price !== undefined ? calculateDataCenter?.price : ''} ${t("uzs")}` || ''}
                        disabled={true} type="text"/>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="request_content-buttons">
            <button onClick={handleCancel}>
              <span>{t("cancel")}</span>
            </button>
            <button
              onClick={handleCreateContract}
              disabled={handleValidate()}
              style={{opacity: handleValidate() ? 0.5 : 1}}
            >
              <span>{t("next")}</span>
              <Arrow2 color="white"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractInfoPage;
