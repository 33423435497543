import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {images} from "../../images/images";
import {useAppSelector} from "../../hooks/redux";
import InputMask from "react-input-mask";
import {axios2} from "../../axios";
import StatusModal from "../../components/Modal/StatusModal";
import LoadingPage from "../LoadingPage/LoadingPage";
import { useTranslation } from "react-i18next";

declare global {
  interface FormDataValue {
    uri: string | number;
    name: string | number;
    type: string | number;
  }

  interface FormData {
    append(name: string, value: FormDataValue, fileName?: string): void;

    set(name: string, value: FormDataValue, fileName?: string): void;
  }
}

const Application: React.FC = () => {

  const {userDetail} = useAppSelector(state => state.user)
  const {serviceDetail} = useAppSelector(state => state.contracts)
  const {access} = useAppSelector((state) => state.auth);

  const navigate = useNavigate()
  const {t} = useTranslation()

  const [name, setName] = useState<string>(userDetail?.u_type === "Fizik" ? userDetail?.full_name : userDetail?.name as string)
  const [phoneNumber, setPhoneNumber] = useState<string>(userDetail?.u_type === "Fizik" ? userDetail?.mob_phone_no : userDetail?.mob_phone_no as string);
  const [email, setEmail] = useState<string>(userDetail?.u_type === "Fizik" ? userDetail?.email : userDetail?.email as string)
  const [message, setMessage] = useState<string>('')
  const [file, setFile] = useState<any>(null)
  const [fileName, setFileName] = useState<string>('')
  const [modal, setModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!name || !email || !phoneNumber || !message || !file) return alert('All input field')
    const formData: FormData = new FormData()
    formData.append('file', file)
    setLoading(true)
    try {
      let response = await axios2.post('main/send-message', {
        name: name,
        phone: phoneNumber,
        email: email,
        message: message,
        service: `${serviceDetail?.id}`,
        file : file
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access}`
        }
      })
      if (response.status === 201) {
        setLoading(false)
        setModal(true)
      } else {
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleValidate = () => {
    if (!name) return true
    if (!phoneNumber) return true
    if (!email) return true
    if (!message) return true
    if (!file) return true
    else return false
  }

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0])
    setFileName(e.target.files[0].name)
  }

  if(loading) return <LoadingPage/>

  return (
    <div className='application'>
      <div className="left">
        <h1 className="title">{t("advantages")}</h1>
        <div className="text">
          <div>
            <span>30+</span>
            <h1>{t("experience")}</h1>
          </div>
          <div>
            <span><img src={images.businessman} alt=""/></span>
            <h1>{t("professional_team")}</h1>
          </div>
          <div>
            <span><img src={images.vector} alt=""/></span>
            <h1>{t("improvement")}</h1>
          </div>
          <div>
            <span><img src={images.handshake} alt=""/></span>
            <h1>{t("individual_approach")}</h1>
          </div>
          <div>
            <span><img src={images.webAnalysis} alt=""/></span>
            <h1>{t("range_of_services")}</h1>
          </div>
          <div>
            <span><img src={images.sitemap} alt=""/></span>
            <h1>{t("technological_infrastructure")}</h1>
          </div>
          <div>
            <span><img src={images.support} alt=""/></span>
            <h1>{t("level_of_service")}</h1>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='content'>
          <form onSubmit={handleSubmit}>
            <div className="box">
              <h1 className="title">{t("application_info")}</h1>
              <div className='input_name'>
                <label htmlFor="hello">{t("organization_name")}</label>
                <input
                  id='hello'
                  type="text"
                  className='input'
                  value={name}
                  onChange={(e) => setName(e?.target?.value)}
                />
              </div>
              <div className='input_phone'>
                <label htmlFor="phone">{t("phone")}</label>
                <InputMask
                  onChange={(e) => setPhoneNumber(e?.target?.value)}
                  mask='+999 99 999 99 99'
                  type="text"
                  id='phone'
                  className='input'
                  value={phoneNumber}
                />
              </div>
              <div className='input_email'>
                <label htmlFor="email">{t("email")}</label>
                <input
                  type="email"
                  id='email'
                  className='input'
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                />
              </div>
              <div className='textarea'>
                <label htmlFor="message">{t("text")}</label>
                <textarea
                  value={message} onChange={(e) => setMessage(e?.target?.value)} id='message'
                  className='input'
                />
              </div>
              <div style={{marginLeft: 'auto', display: 'flex', alignItems: "center"}}>
                {fileName}
                <label className="custom-file-upload" style={{marginLeft: '10px'}}>
                  <input id="max_id" type="hidden" name="MAX_FILE_SIZE" value="5245880"/>
                  <input
                    onChange={handleFileChange}
                    accept='.doc,.pdf,.docx'
                    type="file"
                    style={{display: 'none'}}
                  />
                  {t("file_attachment")}
                </label>
              </div>
            </div>
            <div className='actions'>
              <button className='btn cancel' onClick={() => navigate('/')}>{t("cancel")}</button>
              <button className='btn send' type='submit' disabled={handleValidate()}>{t("send")}</button>
            </div>
          </form>
        </div>
      </div>
      {modal &&
        <StatusModal
          type="application"
          close={() => setModal(false)}
          navigation={() => navigate("/")}
        />
      }
    </div>
  )
}

export default Application
