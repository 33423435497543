import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4999 21.0833V12.4583M11.4999 21.0833L9.10408 19.1666M11.4999 21.0833L13.8957 19.1666M4.82416 8.73706C3.91778 8.96514 3.12588 9.51634 2.59724 10.2871C2.0686 11.0579 1.83961 11.9952 1.95329 12.9229C2.06697 13.8506 2.5155 14.7048 3.21461 15.3251C3.91372 15.9454 4.81528 16.2891 5.74991 16.2916H6.70825"
        stroke="#0E0E4B"
        strokeWidth="1.91667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1703 6.84039C14.8428 5.54336 14.0343 4.4194 12.9088 3.69635C11.7832 2.9733 10.4249 2.70527 9.10909 2.94659C7.79328 3.18791 6.61846 3.92053 5.82278 4.99593C5.0271 6.07132 4.6701 7.40904 4.82414 8.73789C4.82414 8.73789 4.97076 9.58314 5.27072 10.0623"
        stroke="#0E0E4B"
        strokeWidth="1.91667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2918 16.2916C16.9684 16.2911 17.6373 16.1474 18.2544 15.8698C18.8714 15.5922 19.4227 15.1871 19.8719 14.6811C20.3211 14.1751 20.6581 13.5797 20.8606 12.9341C21.0631 12.2885 21.1266 11.6073 21.0469 10.9354C20.9672 10.2635 20.746 9.61609 20.3981 9.03579C20.0501 8.4555 19.5832 7.95547 19.0281 7.56863C18.473 7.18179 17.8422 6.9169 17.1773 6.7914C16.5124 6.6659 15.8285 6.68263 15.1706 6.8405L13.896 7.18742"
        stroke="#0E0E4B"
        strokeWidth="1.91667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
