import axios from "axios";
import i18n from "../resources/i18n";
const tin_or_pin = localStorage.getItem('tin_or_pin') ? JSON.parse(localStorage.getItem('tin_or_pin') || "") : undefined

export default axios.create({
    baseURL: `https://api2.unicon.uz/${i18n.language}/api/`,
    // baseURL: `https://dcid.unicon.uz/${i18n.language}/api/`,
    headers: { "PINORTIN": tin_or_pin }
});

export const axios2 = axios.create({
    baseURL: `https://api2.unicon.uz/${i18n.language}`,
    // baseURL: `https://dcid.unicon.uz/${i18n.language}`,
    headers: { "PINORTIN": tin_or_pin }
});