import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import './VpsPage.scss'
import {
  getOperationSystems, getOperationSystemsDetail,
  getVpsTariffs,
  postVpsBilling
} from "../../store/actions/vpsActions";
import {Server} from "../../store/models";
import {axios2} from "../../axios";
import VpsContractInfo from "./VpsContractInfo";

const VpsAgreementAdditionalPage: React.FC = () => {
  
  const dispatch = useAppDispatch();
  
  const {access} = useAppSelector((state) => state.auth);
  const {
    operationSystemsDetail,
    vpsConfig,
  } = useAppSelector(state => state.vps)
  
  useEffect(() => {
    dispatch(getVpsTariffs(access))
    dispatch(getOperationSystems(access))
    window.scrollTo(0, 0)
  }, [])
  
  const navigate = useNavigate()
  
  const [server, setServer] = useState<Server[]>([
    {
      tariff: null,
      cpu: null,
      ram: null,
      system_storage: null,
      system_storage_disk: null,
      billing_status: 4,
      internet: null,
      is_imut: false,
      tasix: null,
      imut: null,
      count_vm: 1,
      image_id: null,
      account_id: null,
      os_type_id: '',
      data_disks: [{
        storage: null,
        storage_disk: null,
        name: null,
        id: null,
        vm_id: null,
        status: null
      }],
      vm_systems: [{
        ipv_address: true,
        vm_name: '',
        account_id: null,
        vm_id: null,
        id: null,
      }]
    }
  ])
  
  const timeoutId = useRef<any>()
  const postBilling = (value: Server[]) => {
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      dispatch(postVpsBilling(access, {vms: value}, handleSecondValidate()))
    }, 500)
  }
  
  useEffect(() => {
    if (vpsConfig) {
      const serverObjects = vpsConfig?.configurations?.map((configuration: any, index: number) => ({
        tariff: null,
        cpu: configuration.cpu,
        account_id: configuration.account_id,
        ram: configuration.ram,
        internet: configuration.internet,
        is_imut: configuration.imut ? true : false,
        tasix: configuration.tasix,
        imut: configuration.imut,
        system_storage: configuration.system_storage,
        system_storage_disk: configuration.system_storage_disk,
        data_disks: configuration.data_disks,
        billing_status: configuration.billing_status,
        count_vm: 1,
        image_id: configuration.image_id,
        os_type_id: configuration.os_type_id,
        id: configuration.id,
        vm_systems: [
          {
            ipv_address: configuration.ipv_address,
            vm_name: configuration.name,
            account_id: configuration.account_id,
            vm_id: configuration.vm_id,
            // id: configuration.id,
          },
        ],
      }))
      setServer(serverObjects);
      
      postBilling(serverObjects)
      localStorage.setItem('server', JSON.stringify(serverObjects))
      for (let i = 0; i < serverObjects.length; i++) {
        if (serverObjects[i].os_type_id) {
          dispatch(getOperationSystemsDetail(access, serverObjects[i].os_type_id))
        }
      }
    } else {
      navigate('/')
    }
  }, [navigate, vpsConfig])
  
  useEffect(() => {
    fetchData().then(r => r)
  }, [])
  
  const handleSecondValidate = () => {
    const uniqueVmNamesPerServer: any = new Set();
    for (const currentServer of server) {
      const uniqueVmNames: any = new Set();
      if (
        currentServer.billing_status !== 3 &&
        (!currentServer?.cpu ||
          !currentServer?.ram ||
          (currentServer?.is_imut && !currentServer?.imut) ||
          !currentServer?.system_storage ||
          !currentServer?.system_storage_disk ||
          currentServer?.vm_systems?.some((s) => !s.vm_name) ||
          currentServer?.vm_systems?.some((value) => value.ipv_address && (!currentServer?.internet || !currentServer?.tasix)) ||
          (currentServer?.data_disks?.length !== 0 && currentServer?.data_disks.some((s) => !s.storage || !s.storage_disk)) ||
          // ((currentServer?.internet || currentServer?.tasix) && currentServer?.vm_systems.some((value) => !value.ipv_address)) ||
          !currentServer?.image_id ||
          currentServer?.vm_systems?.some((s) => {
            const vmName = s.vm_name.trim().toLowerCase(); // Trim and convert to lowercase
            if (uniqueVmNames.has(vmName) || uniqueVmNamesPerServer.has(vmName)) {
              return true; // Duplicate vm_name found within this server or across servers
            }
            uniqueVmNames.add(vmName);
            uniqueVmNamesPerServer.add(...uniqueVmNames)
            return false;
          }) ||
          // vpsBilling.length === 0 ||
          Number(currentServer?.cpu) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_cpu) ||
          Number(currentServer?.ram) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_ram) ||
          Number(currentServer?.system_storage_disk) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_disk))
      ) {
        return true;
      }
      
    }
    
    return false;
  }
  
  async function fetchData() {
    try {
      await axios2.get('vps/contract/finish', {
        headers: {Authorization: `Bearer ${access}`}
      })
    } catch (e) {
      console.log(e)
    }
  }
  
  return (
    <div className='request'>
      <div className='request_content'>
        <VpsContractInfo/>
      </div>
    </div>
  )
}

export default VpsAgreementAdditionalPage