export enum Algorithms {
  UZDST1 = 'UZDST1',
  UZDST2 = 'UZDST2',
  RSA = 'RSA',
  UZDST1_EXAT = 'UZDST1_EXAT'
}

export namespace Algorithms {
  // eslint-disable-next-line consistent-return
  export function string(algorithm: Algorithms) {
    // eslint-disable-next-line default-case
    switch (algorithm) {
      case Algorithms.UZDST1:
        return 'UZDST I';
      case Algorithms.UZDST2:
        return 'UZDST II';
      case Algorithms.RSA:
        return 'RSA';
      case Algorithms.UZDST1_EXAT:
        return 'UZDST I (E-XAT)';
    }
  }

  export function list() {
    return [
      Algorithms.UZDST1,
      Algorithms.UZDST2,
      Algorithms.RSA,
      Algorithms.UZDST1_EXAT,
    ]
  }

  export function options() {
    return [
      { label: Algorithms.string(Algorithms.UZDST1), value: Algorithms.UZDST1 },
      { label: Algorithms.string(Algorithms.UZDST2), value: Algorithms.UZDST2 },
      { label: Algorithms.string(Algorithms.RSA), value: Algorithms.RSA },
      { label: Algorithms.string(Algorithms.UZDST1_EXAT), value: Algorithms.UZDST1_EXAT },
    ]
  }
  export function get(algorithm: Algorithms) {
    let kalg = '';
    let palg = '';

    // eslint-disable-next-line default-case
    switch (algorithm) {
      case Algorithms.UZDST1:
        kalg = '1.2.860.3.15.1.1.1.1';
        palg = '1.2.860.3.15.1.1.1.1.3';
        break;
      case Algorithms.UZDST2:
        kalg = '1.2.860.3.15.1.1.2.1';
        palg = '1.2.860.3.15.1.1.2.1.1';
        break;
      case Algorithms.RSA:
        kalg = '1.2.840.113549.1.1.1';
        break;
      case Algorithms.UZDST1_EXAT:
        kalg = '1.2.860.1.7.2';
        break;
    }

    return [kalg, palg]
  }
}
