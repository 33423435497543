import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { ProfileUserDetailHandler } from "../../hooks/handlers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  getBank,
  updateFizuser,
  updateYuruser,
} from "../../store/actions/accountActions";
import {getFizUserDetail, refreshUserDetail} from "../../store/actions/contractActions";
import { useTranslation } from "react-i18next";
import SavedModal from "../../components/Modal/SavedModal";
import Footer from "../../components/Footer/Footer";
import LoadingPage from "../LoadingPage/LoadingPage";

const ProfilePage: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { userDetail, loading } = useAppSelector((state) => state.user);
  const { access } = useAppSelector((state) => state.auth);
  const { bankInfo } = useAppSelector((state) => state.accounts);

  useEffect(() => {
    dispatch(getFizUserDetail(access))
    if (userDetail?.bank_mfo) {
      dispatch(getBank(access, mfo));
    }
  }, [access]);

  const sendMfo = () => {
    dispatch(getBank(access, mfo));
  };
  
  const [saveModal, setSaveModal] = useState<boolean>(false)

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userDetail?.mob_phone_no
  );
  const [address, setAddress] = useState<string | undefined>(
    userDetail?.per_adr
  );
  const [email, setEmail] = useState<string | undefined>(userDetail?.email)
  const [lang, setLang] = useState<string | undefined>(userDetail?.lang)
  const [mfo, setMfo] = useState<string | undefined>(userDetail?.bank_mfo?.mfo);
  const [xxtut, setXxtut] = useState<string | undefined>(userDetail?.xxtut);
  const [ktut, setKtut] = useState<string | undefined>(userDetail?.ktut);
  const [ifut, setIfut] = useState<string | undefined>(userDetail?.oked);
  const [checkingAccount, setCheckingAccount] = useState<string | undefined>(
    userDetail?.paymentAccount
  );
  const [bank, setBank] = useState<string | undefined>("");
  const [directorName, setDirectorName] = useState<string | undefined>(
    userDetail?.director_firstname
  );
  const [directorSurname, setDirectorSurname] = useState<string | undefined>(
    userDetail?.director_lastname
  );
  const [directorMidname, setDirectorMidname] = useState<string | undefined>(
    userDetail?.director_middlename
  );
  const [stir, setStir] = useState<string | undefined>(userDetail?.tin)
  const [vatNumber, setVatNumber] = useState<string | undefined>(userDetail?.vatNumber)

  const saveData = () => {
    const yurBody = {
      mob_phone_no: phoneNumber,
      address,
      xxtut,
      ktut,
      oked: ifut,
      paymentAccount: checkingAccount?.replace(/[_\s]/g, ''),
      bank_mfo: bankInfo?.id,
      director_firstname: directorName,
      director_lastname: directorSurname,
      director_middlename: directorMidname,
      email,
      lang,
      vatNumber
    };
    const fizBody = {
      mob_phone_no: phoneNumber,
      per_adr: address,
      email,
      lang
    };
    if (userDetail?.u_type === "Fizik") {
      dispatch(updateFizuser(access, fizBody, userDetail?.id)).then((res: any) => {
        if (res?.status ===  200) {
          setSaveModal(!saveModal)
        }
      })
    }
    if (userDetail?.u_type === "Yuridik") {
      dispatch(updateYuruser(access, yurBody, userDetail?.id)).then((res: any) => {
        if (res?.status === 200) {
          setSaveModal(!saveModal)
        }
      })
    }
    setTimeout(() => {
      dispatch(getFizUserDetail(access));
    }, 2000);
  };

  const valid = (e: any, setState: any) => {
    setState(e.target.value)
    // const re = /^[a-zA-Z ]+$/;
    // if (e.target.value === "" || re.test(e.target.value)) {
    //   setState(e.target.value);
    // }
  };
  
  const sendStir = () => {
    dispatch(refreshUserDetail(access))
  }
  const sendVatNumber = () => {
    dispatch(refreshUserDetail(access))
  }
  
  if (loading) return <LoadingPage/>

  return (
    <>
      <div className="profile">
        <Sidebar active={location.pathname} type="profile" />
        <div className="profile_wrap" style={{height: `calc(${window.innerHeight}px - ${userDetail?.u_type !== 'Yuridik' && "200px"})`}}>
          {/*<div className="profile_wrap-titleWrap">*/}
          {/*  <div className="profile_wrap-titleWrap-title">{t("userInfo")}</div>*/}
          {/*</div>*/}
          <ProfileUserDetailHandler
            sendStir={sendStir}
            setStir={(e: any) => setStir(e.target.value)}
            vatNumber={vatNumber}
            setVatNumber={(e: any) => setVatNumber(e.target.value)}
            lang={lang}
            setLang={(e: any) => setLang(e.target.value)}
            email={email}
            setEmail={(e: any) => setEmail(e.target.value)}
            detail={userDetail}
            saveData={saveData}
            sendMfo={sendMfo}
            phoneNumber={phoneNumber}
            setPhoneNumber={(e: any) => setPhoneNumber(e.target.value)}
            address={address}
            setAddress={(e: any) => setAddress(e.target.value)}
            mfo={mfo}
            setMfo={(e: any) => setMfo(e.target.value)}
            xxtut={xxtut}
            setXxtut={(e: any) => setXxtut(e.target.value)}
            ktut={ktut}
            setKtut={(e: any) => setKtut(e.target.value)}
            ifut={ifut}
            setIfut={(e: any) => setIfut(e.target.value)}
            checkingAccount={checkingAccount}
            setCheckingAccount={(e: any) => setCheckingAccount(e.target.value)}
            bank={bankInfo?.bank_name}
            setBank={(e: any) => valid(e, setBank)}
            directorName={directorName}
            setDirectorName={(e: any) => valid(e, setDirectorName)}
            directorSurname={directorSurname}
            setDirectorSurname={(e: any) => valid(e, setDirectorSurname)}
            directorMidname={directorMidname}
            setDirectorMidname={(e: any) => valid(e, setDirectorMidname)}
          />
        </div>
        <div className="profile_div"></div>
        {saveModal && <SavedModal close={() => setSaveModal(!saveModal)} />}
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default ProfilePage;
