import React from "react";

type Props = {
  color?: string;
}

const Arrow2 = ({color = "#0E0E4B"}: Props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.39807 10.5293C1.54836 10.5293 0.859529 11.2182 0.859529 12.0679C0.859529 12.9176 1.54836 13.6064 2.39807 13.6064V10.5293ZM29.1284 13.1558C29.7292 12.5549 29.7292 11.5808 29.1284 10.98L19.3371 1.18874C18.7363 0.587897 17.7621 0.587897 17.1613 1.18874C16.5605 1.78957 16.5605 2.76372 17.1613 3.36456L25.8646 12.0679L17.1613 20.7712C16.5605 21.372 16.5605 22.3462 17.1613 22.947C17.7621 23.5478 18.7363 23.5478 19.3371 22.947L29.1284 13.1558ZM2.39807 13.6064H28.0404V10.5293H2.39807V13.6064Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow2;
