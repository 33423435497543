import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "../../images/deleteIcon";
import PlusIcon from "../../images/plusIcon";
import ShowIcon from "../../images/showIcon";
import DownloadIcon from "../../images/DownloadIcon";
import {iUserContracts} from "../../store/models";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
  deleteContract
} from "../../store/actions/contractActions";
import {changeAction} from "../../store/actions/changeActions";
import {createVpsContractAdditionalAgreement, postFinishVps} from "../../store/actions/vpsActions";
import ThirdStepIcon from "../../images/3step";
import * as vpsActionTypes from '../../store/actionTypes/vpsActionTypes'
import {axios2} from "../../axios";
import {handleType} from "../../store/actions/handleType";
import {HooksCommission} from "../eImzoConfig";
import {images} from "../../images/images";
import SignatureModal from "../Modal/SignatureModal";
import * as vpsTypes from "../../store/actionTypes/vpsActionTypes";


type Props = {
  data: iUserContracts[];
  num: number;
  setNum: any,
  filter: string,
  setFilter: any
};

const MuiTable: React.FC<Props> = ({data, setFilter}) => {
  const {AppLoad, sign, error, err_msg, setError, setErrMsg} = HooksCommission()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  
  const [accardionSelected, setAccardionSelected] = useState(null)
  
  const [modalExpired, setModalExpired] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)

  const [error_code, setErrorCode] = useState<any>(null)
  
  const [expiration_date, setExpiredDateVps] = useState<string>('')
  
  const change = useAppSelector((state) => state.userService.change)
  const {access} = useAppSelector((state) => state.auth);
  const {userDetail} = useAppSelector(state => state.user)
  const {services} = useAppSelector(
    (state) => state.contracts
  );
  const {vpsFinishDocument} = useAppSelector((state) => state.vps)

  const toggle = (i: any) => {
    if (accardionSelected === i) {
      return setAccardionSelected(null)
    }
    setAccardionSelected(i)
  }
  
  const changeInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      changeAction({
        name: e.target.name,
        value: e.target.value
      })
    )
    setFilter(e.target.value)
  }
  
  const getDownload = (hashcode: string, item: any) => {
    if (item?.service?.name === "Co-location") {
      if (item?.old_contract?.has_old_contract) {
        window.location.replace(
          `https://api2.unicon.uz/${change.userSelectService === undefined ? 'e-xat' : change.userSelectService}/contract/${hashcode}`
        );
      } else {
        window.location.replace(
          `https://api2.unicon.uz/colocation/contract/${hashcode}`
        );
      }
    } else {
      window.location.replace(
        `https://api2.unicon.uz/${change.userSelectService === undefined ? 'e-xat' : change.userSelectService}/contract/${hashcode}`
      );
    }
  };
  
  const handleDeleteContract = (contract: number) => {
    dispatch(deleteContract(access, contract));
    window.location.reload();
  };
  
  const handleDateFormat = (date: string) => {
    return moment(date).format("DD.MM.YYYY");
  };
  
  const vpsExpiredContract = () => {
    setModalExpired(true)
  }
  
  useEffect(() => {
    fetchData().then(r => r)
    AppLoad()
  }, [])
  
  useEffect(() => {
    fetchData().then(r => r)
  }, [change]);
  
  async function fetchData() {
    try {
      if (change?.userSelectService !== undefined) {
        const res = await axios2.get(`${change?.userSelectService}/contract/finish`, {
          headers: {Authorization: `Bearer ${access}`}
        })
        setErrorCode(res.data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  
  const dataFinish = {
    expiration_date: expiration_date !== '' ? new Date(expiration_date).toISOString() : '',
    save: 0,
    is_back_office: false
  }
  
  async function getSignature() {
    try {
      setLoader(true)
      const response = await axios2.post(
        `${change?.userSelectService}/contract/finish`,
        {
          expiration_date: expiration_date !== '' ? new Date(expiration_date).toISOString() : '',
          save: 1,
          is_back_office: false
        },
        {
          headers: {Authorization: `Bearer ${access}`}
        })
      if (response?.data?.id) {
        sign(response?.data?.base64file, change?.userSelectService, response?.data?.id)
      } else {
        alert('Xatolik')
      }
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  }

  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    setModalExpired(false)
    setExpiredDateVps('')
    dispatch(handleType(vpsTypes.CLEAR_VPS_BILLING, null))
  }
  
  const handleNavigate = (id: any) => {
    navigate(`/show-my-request${change?.userSelectService === undefined ? '' : '-' + change?.userSelectService}/${id}`, {state: {name: change?.userSelectService}})
  }
  
  // if (loading) return <LoadingPage/>;
  
  return (
    <>
      <div className="filter_wrap">
        <select
          className="filter_wrap__select"
          style={{width: 250}}
          name='userSelectService'
          value={change.userSelectService === undefined ? 'e-xat' : change.userSelectService}
          onChange={changeInput}
        >
          {services && services?.map((item) => (
            <option value={item.slug} key={item.id}>{item.name}</option>
          ))}
        </select>
        {(change?.userSelectService === 'vps' || change?.userSelectService === 'colocation') && (
          <button
            style={{
              padding: '10px 15px',
              border: "none",
              outline: 'none',
              background: '#0E0E4B',
              color: '#fff',
              borderRadius: '10px',
              opacity: !error_code?.success ? 0.5 : 1
            }}
            disabled={!error_code?.success}
            onClick={vpsExpiredContract}
          >
            {change?.userSelectService.toUpperCase()} bo'yicha shartnomani to'liq yakunlash
          </button>
        )}
      </div>
      <TableContainer component={Paper} style={{marginTop: 30}}>
        <Table className="muiTable">
          <TableHead className="muiTable_head">
            <TableRow>
              <TableCell className="muiTable_head-cell">
                <pre>    </pre>
              </TableCell>
              <TableCell className="muiTable_head-cell">№</TableCell>
              <TableCell className="muiTable_head-cell">
                {t("contractNumber")}
              </TableCell>
              <TableCell className="muiTable_head-cell">
                {t("serviceName")}
              </TableCell>
              <TableCell className="muiTable_head-cell">
                {t("paymentAmount")}
              </TableCell>
              <TableCell className="muiTable_head-cell">
                {t("contractDate")}
              </TableCell>
              <TableCell className="muiTable_head-cell">
                {t("contractStatus")}
              </TableCell>
              <TableCell className="muiTable_head-cell">{t("manage")}</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody className="muiTable_body">
            {data !== undefined && data?.map((el: any, i) => {
              return (
                <>
                  <TableRow className="muiTable_body-row position-relative" key={i}>
                    <TableCell className="muiTable_body-row-cell">
                      {el?.child_contracts?.length > 0 ?
                        <button onClick={() => toggle(i)}
                                className={accardionSelected === i ? 'arrow_acardion arrow_acardion--active position-absolute' : 'arrow_acardion position-absolute'}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                            <path
                              d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"
                              fill="rgba(60,75,100,1)"></path>
                          </svg>
                        </button> : ''
                      }
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell">
                      {i + 1}
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell">
                      {el.contract_number}
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell">
                      {el.service.name}
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell">
                      {el.contract_cash.slice(0, -3)} {t("uzs")}
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell">
                      {handleDateFormat(el.contract_date)}
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell">
                      {el.contract_status?.name ? el.contract_status?.name : el.contract_status}
                    </TableCell>
                    <TableCell className="muiTable_body-row-cell" style={{borderBottom: 'none'}}>
                      {/*navigate(`/show-my-request-${change?.userSelectService !== undefined && change?.userSelectService}/${el.id}`)*/}
                      <button
                        title="batafsil"
                        onClick={() => handleNavigate(el.id)}
                        disabled={
                          el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" ? true : false
                        }
                        style={{
                          opacity: el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" ? 0.5 : 1,
                          cursor:
                            el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan"
                              ? "auto"
                              : "pointer",
                        }}
                      >
                        <ShowIcon/>
                      </button>
                      <button
                        title="yuklash"
                        onClick={() => getDownload(el.hashcode, el)}
                        disabled={
                          el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" || (change?.userSelectService === 'e-xat' || change?.userSelectService === undefined) && el.contract_status === 'Yangi'
                            ? true
                            : false
                        }
                        style={{
                          opacity:
                            el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" || el.contract_status === "Rad etilgan" || el.contract_status === "Bekor qilingan"
                              ? 0.5
                              : 1,
                          cursor:
                            el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" || el.contract_status === "Rad etilgan" || el.contract_status === "Bekor qilingan"
                              ? "auto"
                              : "pointer",
                        }}
                      >
                        <DownloadIcon/>
                      </button>
                      <button
                        title="o'chirish"
                        onClick={() => handleDeleteContract(el.id)}
                        disabled={
                          el.contract_status.name !== "Yangi" ? true : false
                        }
                        style={{
                          opacity: el.contract_status.name !== "Yangi" ? 0.5 : 1,
                          cursor:
                            el.contract_status.name !== "Yangi"
                              ? "auto"
                              : "pointer",
                        }}
                      >
                        <DeleteIcon/>
                      </button>
                      {change.userSelectService === 'vps' && (
                        <button
                          style={{
                            opacity: el.contract_status === "Aktiv" ? 1 : 0.5,
                            cursor:
                              el.contract_status === "Aktiv"
                                ? "pointer"
                                : "auto",
                          }}
                          disabled={el.contract_status === 'Aktiv' ? false : true}
                          onClick={() => {
                            dispatch(
                              createVpsContractAdditionalAgreement(access, userDetail?.bank_mfo ? userDetail.tin : userDetail?.pin, navigate, 'vps')
                            ).then((res: any) => {
                              if (res.error_code === 3) {
                                alert("Sizga yangi shartnoma tuzish mumkin emas! Shartnomalarim bo'limidan shartnoma holatini tekshiring")
                                navigate('/my-request')
                              } else if (res.error_code === 1) {
                                localStorage.setItem('error_code', JSON.stringify(res.error_code))
                                localStorage.setItem('service_id', JSON.stringify(el?.service?.id))
                                navigate('/vpsAgreementAdditional', {
                                  state: {
                                    service_id: el?.service?.id,
                                    slug: el?.service?.slug
                                  },
                                })
                              }
                            })
                          }}
                        >
                          <PlusIcon/>
                        </button>
                      )}
                      {change.userSelectService === 'vps' && (
                        <button
                          style={{
                            opacity: el.contract_status === "To'lov kutilmoqda" || el.contract_status === "Aktiv" ? 1 : 0.5,
                            cursor:
                              el.contract_status === "To'lov kutilmoqda" || el.contract_status === "Aktiv"
                                ? "pointer"
                                : "auto",
                          }}
                          disabled={el.contract_status === "To'lov kutilmoqda" || el.contract_status === "Aktiv" ? false : true}
                          onClick={async () => {
                            await axios2.post(`billing/get-invoice?type=vps`, {contract_id: el.contract_id}, {headers: {Authorization: `Bearer ${access}`}}).then((res) => {
                              const blob = new Blob([res.data], {type: 'application/pdf'});
                              const url = URL.createObjectURL(blob);
                              const a = document.createElement('a');
                              a.href = url;
                              a.download = `${userDetail?.pin}.pdf`;
                              a.textContent = 'Download PDF';
                              document.body.appendChild(a);
                              a.click()
                              document.body.removeChild(a);
                            })
                          }}
                        >
                          <img src={images.paymentImg} alt=""/>
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                  
                  {accardionSelected === i ? el.child_contracts.map((el: any, idx: number) => (
                    <TableRow className="muiTable_body-row" key={idx}>
                      <TableCell className="muiTable_body-row-cell"></TableCell>
                      <TableCell className="muiTable_body-row-cell">
                        {`${i + 1}.${idx + 1} `}
                      </TableCell>
                      <TableCell className="muiTable_body-row-cell">
                        {el.contract_number}
                      </TableCell>
                      <TableCell className="muiTable_body-row-cell">
                        {el.service.name}
                      </TableCell>
                      <TableCell className="muiTable_body-row-cell">
                        {el.contract_cash.slice(0, -3)} {t("uzs")}
                      </TableCell>
                      <TableCell className="muiTable_body-row-cell">
                        {handleDateFormat(el.contract_date)}
                      </TableCell>
                      <TableCell className="muiTable_body-row-cell">
                        {el.contract_status?.name ? el.contract_status?.name : el.contract_status}
                      </TableCell>
                      <TableCell className="muiTable_body-row-cell" style={{borderBottom: 'none'}}>
                        <button
                          title="batafsil"
                          onClick={() => navigate(`/show-my-request-${change.userSelectService !== undefined && change?.userSelectService}/${el.id}`)}
                          disabled={
                            el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" ? true : false
                          }
                          style={{
                            opacity: el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" ? 0.5 : 1,
                            cursor:
                              el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan"
                                ? "auto"
                                : "pointer",
                          }}
                        >
                          <ShowIcon/>
                        </button>
                        <button
                          title="yuklash"
                          onClick={() => getDownload(el.hashcode, el)}
                          disabled={
                            el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" || (change?.userSelectService === 'e-xat' || change?.userSelectService === undefined) && el.contract_status === 'Yangi'
                              ? true
                              : false
                          }
                          style={{
                            opacity:
                              el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" || el.contract_status === "Rad etilgan" || el.contract_status === "Bekor qilingan"
                                ? 0.5
                                : 1,
                            cursor:
                              el.contract_status.name === "Rad etilgan" || el.contract_status.name === "Bekor qilingan" || el.contract_status === "Rad etilgan" || el.contract_status === "Bekor qilingan"
                                ? "auto"
                                : "pointer",
                          }}
                        >
                          <DownloadIcon/>
                        </button>
                        <button
                          title="o'chirish"
                          onClick={() => handleDeleteContract(el.id)}
                          disabled={
                            el.contract_status.name !== "Yangi" ? true : false
                          }
                          style={{
                            opacity: el.contract_status.name !== "Yangi" ? 0.5 : 1,
                            cursor:
                              el.contract_status.name !== "Yangi"
                                ? "auto"
                                : "pointer",
                          }}
                        >
                          <DeleteIcon/>
                        </button>
                        {change.userSelectService === 'vps' && (
                          <button
                            style={{
                              opacity: el.contract_status === "Aktiv" ? 1 : 0.5,
                              cursor:
                                el.contract_status === "Aktiv"
                                  ? "pointer"
                                  : "auto",
                            }}
                            disabled={el.contract_status === 'Aktiv' ? false : true}
                            onClick={() => {
                              dispatch(
                                createVpsContractAdditionalAgreement(access, userDetail?.bank_mfo ? userDetail.tin : userDetail?.pin, navigate, 'vps')
                              ).then((res: any) => {
                                if (res.error_code === 3) {
                                  alert("Sizga yangi shartnoma tuzish mumkin emas! Shartnomalarim bo'limidan shartnoma holatini tekshiring")
                                  navigate('/my-request')
                                } else if (res.error_code === 1) {
                                  localStorage.setItem('error_code', JSON.stringify(res.error_code))
                                  localStorage.setItem('service_id', JSON.stringify(el?.service?.id))
                                  navigate('/vpsAgreementAdditional', {
                                    state: {
                                      service_id: el?.service?.id,
                                      slug: el?.service?.slug
                                    },
                                  })
                                }
                              })
                            }}
                          >
                            <PlusIcon/>
                          </button>
                        )}
                        {change.userSelectService === 'vps' && (
                          <button
                            style={{
                              opacity: el.contract_status === "To'lov kutilmoqda" || el.contract_status === "Aktiv" ? 1 : 0.5,
                              cursor:
                                el.contract_status === "To'lov kutilmoqda" || el.contract_status === "Aktiv"
                                  ? "pointer"
                                  : "auto",
                            }}
                            disabled={el.contract_status === "To'lov kutilmoqda" || el.contract_status === "Aktiv" ? false : true}
                            onClick={async () => {
                              await axios2.post(`billing/get-invoice?type=vps`, {contract_id: el.contract_id}, {headers: {Authorization: `Bearer ${access}`}}).then((res) => {
                                const blob = new Blob([res.data], {type: 'application/pdf'});
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = `${userDetail?.pin}.pdf`;
                                a.textContent = 'Download PDF';
                                document.body.appendChild(a);
                                a.click()
                                document.body.removeChild(a);
                              })
                            }}
                          >
                            <img src={images.paymentImg} alt=""/>
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  )) : ''}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="modalWrap vps" style={{opacity: modalExpired ? 1 : 0, pointerEvents: modalExpired ? 'all' : 'none'}}>
        <div className="request_content modalWrap_textWrap">
          <div>
            <button
              style={{
                marginLeft: 'auto',
                display: 'flex',
                border: 'none',
                outline: 'none',
                fontSize: 20
              }}
              onClick={closeErrModal}
            >
              X
            </button>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
              <span>Shartnoma yakunlash sanasi</span>
              <input
                id={'date'}
                type="date"
                value={expiration_date}
                onChange={(e) => setExpiredDateVps(e.target.value)}
                style={{
                  marginBottom: 20,
                  padding: '10px 15px',
                  width: '50%'
                }}
                disabled={vpsFinishDocument !== ''}
              />
            </div>
            <button
              style={{
                padding: '10px 15px',
                border: "none",
                outline: 'none',
                background: '#0E0E4B',
                color: '#fff',
                borderRadius: '10px',
                opacity: expiration_date === '' ? 0.5 : 1
              }}
              disabled={expiration_date === ''}
              onClick={() => modalExpired && dispatch(postFinishVps(access, dataFinish, change?.userSelectService))}
            >
              Yuborish
            </button>
          </div>
          {vpsFinishDocument && (
            <div
              style={{
                height: '400px',
                overflow: 'scroll',
                padding: '5px',
                border: '1px solid #000',
                borderRadius: 10,
                marginBottom: 10
              }}
              className='vpsFinishDocument'
            >
              <div dangerouslySetInnerHTML={{__html: vpsFinishDocument}}/>
            </div>
          )}
          <div className="request_content-buttons">
            <div style={{opacity: vpsFinishDocument ? 1 : 0, pointerEvents: vpsFinishDocument ? "all" : "none"}}>
              <div className="request_content-buttons">
                <button onClick={() => {
                  dispatch(handleType(vpsActionTypes.CLEAR_VPS_BILLING, null))
                  setExpiredDateVps('')
                  setModalExpired(false)
                }}
                >
                  <span>{t("back")}</span>
                </button>
                <select name="S@loxiddin" id="S@loxiddin" className='sign'></select>
                <button
                  disabled={!modalExpired || loader}
                  id={'eri_sign'}
                  onClick={getSignature}
                  style={{opacity: loader ? 0.5 : 1}}
                >
                  <span>{t("signing")}</span>
                  <ThirdStepIcon color="white"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && <SignatureModal close={closeErrModal} text={err_msg} />}
    </>
  );
};

export default MuiTable;
