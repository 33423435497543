import moment from "moment";
import React, {useState, useEffect, useRef} from "react";
import SecondStepIcon from "../../images/2step";
import LegalyIcon from "../../images/legalyIcon";
import RequestIcon from "../../images/requestIcon";
import ShowReqIcon from "../../images/showReqIcon";
import {iContractDetail, iUserDetail} from "../../store/models";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import ThirdStepIcon from "../../images/3step";
import {
  rejectContract
} from "../../store/actions/contractActions";
import {useNavigate} from "react-router-dom";
import OneAuth from "../oneauth/OneAuth";
import RefreshIcon from "../../images/refreshIcon";
import {HooksCommission} from "../eImzoConfig";
import SignatureModal from "../Modal/SignatureModal";

type RowProps = {
  label: string;
  value: string | number | undefined;
  span?: string | undefined;
};

const RowBlock: React.FC<RowProps> = ({label, value, span}) => {
  return (
    <div className="showRequest_row-right-block-body-table-row">
      <div className="showRequest_row-right-block-body-table-row-item">
        {label}
      </div>
      <div className="showRequest_row-right-block-body-table-row-item">
        <span>{span}</span>
        {value}
      </div>
    </div>
  );
};

type Props = {
  detail: iContractDetail | null;
  userDetail: iUserDetail | null;
};

const ShowRequestVPS: React.FC<Props> = ({detail, userDetail}) => {
  const {AppLoad, sign, error, err_msg, setError, setErrMsg} = HooksCommission()
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [comment, setReject] = useState<string>("");
  const {access} = useAppSelector((state) => state.auth);
  const {contractDetail} = useAppSelector((state) => state.contracts);
  const contract = useAppSelector((state) => state.contracts.contractDetail?.contract)
  const [isActive, setIsActive] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false)

  const handleChange = () => {
    setIsActive(!isActive);
  };

  const handleDateFormat = (date: string | undefined) => {
    return moment(date).format("DD.MM.YYYY");
  };

  useEffect(() => {
    if (contract?.contract_number.toLowerCase().startsWith("c")) {
      AppLoad()
    }
  }, [contract?.contract_number]);

  useEffect(() => {
    AppLoad()
  }, []);

  const getSignature = () => {
    setLoader(true)
    sign(contract?.base64file, 'vps', contract?.id)
  };

  const closeErrModal = () => {
    setError(false)
    setErrMsg("")
    setLoader(false)
  }

  const {language} = useAppSelector((state) => state.language);

  const [openContractInfo, setOpenContractInfo] = useState<boolean>(false);
  const [openUserInfo, setOpenUserInfo] = useState<boolean>(false);
  const [openProcess, setOpenProcess] = useState<boolean>(false);
  const [openYurInfo, setOpenYurInfo] = useState<boolean>(false);

  const handleRuGrammatic = (day: number) => {
    if (day === 1) return "рабочий день";
    if (day === 2) return "рабочие дни";
    if (day > 2) return "рабочих дней";
  };

  const reducedObject = detail?.configurations?.reduce((accumulator: any, item: any) => {
    Object.entries(item).forEach(([key, value]) => {
      if (typeof value === 'number') {
        accumulator[key] = (accumulator[key] || 0) + value;
      }
    });
    return accumulator;
  }, {});

  return (
    <div className="showRequest">
      <div className="showRequest_titleBlock">
        <div className="showRequest_titleBlock-date">
          {handleDateFormat(detail?.contract.contract_date)}
        </div>
        <div className="showRequest_titleBlock-title">
          {t("acceptedForConsideration")}
        </div>
      </div>
      <div className="showRequest_row">
        <div className="showRequest_row-left">
          <div className="showRequest_row-left-item">
            <div className="showRequest_row-left-item-line"/>
            <div
              className="showRequest_row-left-item-circle"
              onClick={() => setOpenContractInfo(!openContractInfo)}
            >
              <RequestIcon/>
            </div>
            <div
              className="showRequest_row-left-item-line"
              style={{height: openContractInfo && detail?.contract?.contract_number.toLowerCase().startsWith('c') ? 780 : 25}}
            />
            <div
              className="showRequest_row-left-item-circle"
              onClick={() => setOpenUserInfo(!openUserInfo)}
            >
              <SecondStepIcon color="white" width={32} height={32}/>
            </div>
            <div
              className="showRequest_row-left-item-line"
              style={{height: openUserInfo ? 825 : 25}}
            />
            {userDetail?.u_type === "Yuridik" && (
              <>
                <div
                  className="showRequest_row-left-item-circle"
                  onClick={() => setOpenYurInfo(!openYurInfo)}
                >
                  <LegalyIcon/>
                </div>
                <div
                  className="showRequest_row-left-item-line"
                  style={{height: openYurInfo ? 720 : 25}}
                />
              </>
            )}
            <div
              className="showRequest_row-left-item-circle"
              onClick={() => setOpenProcess(!openProcess)}
            >
              <ShowReqIcon/>
            </div>
          </div>
        </div>
        <div className="showRequest_row-right">
          <div
            className="showRequest_row-right-block"
            style={{height: openContractInfo && detail?.contract?.contract_number.toLowerCase().startsWith('c') ? 800 : 48}}
          >
            <div
              className="showRequest_row-right-block-head"
              onClick={() => setOpenContractInfo(!openContractInfo)}
            >
              <span>{t("application")}</span>
            </div>
            {openContractInfo && (
              <>
                <div className="showRequest_row-right-block-body">
                  {/* <span>Umumiy ma'lumotlar</span> */}
                  <div className="showRequest_row-right-block-body-table">
                    <RowBlock
                      label={t("contractNumber")}
                      value={detail?.contract.contract_number}
                    />
                    <RowBlock
                      label={t("contractDate")}
                      value={handleDateFormat(detail?.contract.contract_date)}
                    />
                    <RowBlock
                      label={t("contractStatus")}
                      value={detail?.contract.contract_status.name || detail?.contract.contract_status}
                    />
                    {detail?.contract?.contract_number.toLowerCase().startsWith('c') && (
                      <>
                        <RowBlock
                          label={'CPU'}
                          value={reducedObject?.cpu}
                        />
                        <RowBlock
                          label={'RAM'}
                          value={reducedObject?.ram}
                        />
                        <RowBlock
                          label={t('internet')}
                          value={reducedObject?.internet}
                        />
                        <RowBlock
                          label={t('tasix')}
                          value={reducedObject?.tasix}
                        />
                        <RowBlock
                          label={t('storage_disk')}
                          value={reducedObject?.system_storage_disk}
                        />
                        <RowBlock
                          label={'IMUT'}
                          value={reducedObject?.imut ? reducedObject?.imut : '-'}
                        />
                        <RowBlock
                          label={t('storage_type')}
                          value={detail?.configurations.map((item: any) => (
                            <div key={item.id}>{item.storage_type}</div>
                          ))}
                        />
                      </>
                    )}
                    <RowBlock
                      label={t("termOfContract")}
                      value={
                        detail?.contract.expiration_date
                          ? detail?.contract.expiration_date
                          : `31.12.${moment(
                            detail?.contract.contract_date
                          ).year()}`
                      }
                    />
                    <RowBlock
                      label={t("paymentAmount")}
                      value={`${detail?.contract.contract_cash} ${t("uzs")}`}
                    />
                    <RowBlock
                      label={t("payed")}
                      value={`${detail?.contract.payed_cash} ${t("uzs")}`}
                    />
                    <RowBlock
                      label={t("indebtedness")}
                      value={`${detail?.contract.arrearage} ${t("uzs")}`}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="showRequest_row-right-block"
            style={{height: openUserInfo ? 845 : 48}}
          >
            <div
              className="showRequest_row-right-block-head"
              onClick={() => setOpenUserInfo(!openUserInfo)}
            >
              <span>{t("userInfo")}</span>
            </div>
            {openUserInfo && (
              <>
                <div className="showRequest_row-right-block-body">
                  <span>{t("totalInfo")}</span>
                  <div className="showRequest_row-right-block-body-table">
                    <RowBlock
                      label={t("nsm")}
                      value={detail?.client.full_name}
                    />
                    <RowBlock
                      label={t("birthday")}
                      value={detail?.client.birth_date}
                    />
                    <RowBlock label={t("stir")} value={detail?.client.tin}/>
                    <RowBlock
                      label={t("phoneNumber")}
                      value={detail?.client.mob_phone_no}
                    />
                    <RowBlock label={t("mail")} value={detail?.client.email}/>
                  </div>
                </div>
                <div className="showRequest_row-right-block-body">
                  <span>{t("pasportInfo")}</span>
                  <div className="showRequest_row-right-block-body-table">
                    <RowBlock label={t("state")} value={detail?.client.natn}/>
                    <RowBlock
                      label={t("birthdayPalace")}
                      value={detail?.client.birth_place}
                    />
                    <RowBlock
                      label={t("pasportNumber")}
                      value={detail?.client.pport_no}
                    />
                    <RowBlock
                      label={t("pasportPin")}
                      value={detail?.client.pin}
                    />
                    <RowBlock
                      label={t("liveAddress")}
                      value={detail?.client.per_adr}
                    />
                    <RowBlock
                      label={t("phoneNumber")}
                      value={detail?.client.mob_phone_no}
                    />
                    <RowBlock label={t("mail")} value={detail?.client.email}/>
                  </div>
                </div>
              </>
            )}
          </div>
          {userDetail?.u_type === "Yuridik" && (
            <div
              className="showRequest_row-right-block"
              style={{height: openYurInfo ? 740 : 48}}
            >
              <div
                className="showRequest_row-right-block-head"
                onClick={() => setOpenYurInfo(!openYurInfo)}
              >
                <span>{t("yurInfo")}</span>
              </div>
              {openYurInfo && (
                <>
                  <div className="showRequest_row-right-block-body">
                    <span>{t("totalInfo")}</span>
                    <div className="showRequest_row-right-block-body-table">
                      <RowBlock
                        label={t("orgName")}
                        value={detail?.client.name}
                      />
                      <RowBlock
                        label={t("yurAddr")}
                        value={detail?.client.per_adr}
                      />
                      <RowBlock label={t("stir")} value={detail?.client.tin}/>
                      <RowBlock
                        label={t("phoneNumber")}
                        value={detail?.client.mob_phone_no}
                      />
                      <RowBlock
                        label={t("mail")}
                        value={detail?.client.email}
                      />
                      <RowBlock
                        label={t("mfo")}
                        value={detail?.client.bank_mfo?.mfo}
                      />
                      <RowBlock
                        label={t("bank")}
                        value={detail?.client.bank_mfo?.bank_name}
                      />
                      <RowBlock
                        label={`${t("xxtut")} (OKONX)`}
                        value={detail?.client.xxtut}
                      />
                      <RowBlock label={`${t("ktut")} (OKPO)`} value={detail?.client.ktut}/>
                      <RowBlock label={`${t("ifut")} (OKED)`} value={detail?.client.oked}/>
                      <RowBlock
                        label={t("checkingAccount")}
                        value={detail?.client.paymentAccount}
                      />
                      <RowBlock
                        label={t("directorFullname")}
                        value={`${detail?.client.director_lastname} ${detail?.client.director_firstname} ${detail?.client.director_middlename}`}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="showRequest_row-right-block">
            <div
              className="showRequest_row-right-block-head"
              onClick={() => setOpenProcess(!openProcess)}
            >
              <span>{t("reviewProcess")}</span>
            </div>
            {openProcess && (
              <>
                {detail?.participants.map((el) => (
                  <div className="showRequest_row-right-block-body" key={el.id}>
                    <div className="showRequest_row-right-block-body-table">
                      <RowBlock
                        label={el.role}
                        value={el.agreement_status}
                      />
                      <RowBlock
                        label={t("comment")}
                        value={
                          el.expert_summary.comment
                            ? el.expert_summary.comment
                            : "-"
                        }
                      />
                      <RowBlock
                        label={t("lifeTime")}
                        value={`1 ${
                          language === "uz" ? "ish kuni" : handleRuGrammatic(1)
                        }`}
                        span={`${handleDateFormat(
                          detail.contract.contract_date
                        )} - ${moment(detail.contract.contract_date)
                          .add(1, "d")
                          .format("DD.MM.YYYY")}`}
                      />
                      <RowBlock
                        label={`${t("concluded")}: ${
                          el.userdata.userdata.role.name
                        }`}
                        value={el.userdata.full_name}
                      />
                      <RowBlock
                        label={t("phoneNumber")}
                        value={el.userdata.mob_phone_no}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={
          contract?.contract_number.toLowerCase().startsWith("c") && detail?.contract?.is_confirmed_contract === 2
            ? "d-flex flex-column justify-content-end mt-4"
            : "d-none"
        }
      >
        <div className="request_content-buttons">
          <button className="reject" style={{marginLeft: 20, marginRight: 20, lineHeight: '14px', fontSize: '14px'}}
                  onClick={handleChange}>
            Bekor qilish
          </button>
          <select name="S@loxiddin" id="S@loxiddin" className='sign'></select>
          <button
            disabled={loader}
            style={{
              opacity: loader ? .5 : 1,
              pointerEvents: loader ? 'none' : 'all',
            }}
            id={'eri_sign'}
            onClick={getSignature}
          >
            <span>{t("signing")}</span>
            <ThirdStepIcon color="white"/>
          </button>
        </div>

        <div className={`align-items-start mt-5 ${isActive ? "d-flex" : "d-none"}`}>
          <textarea
            value={comment}
            onChange={e => setReject(e.target.value)}
            className="p-3 m-1 rounded w-75"
            placeholder="Izoh qoldiring!!! Nima uchun shartnomani bekor qilmoqchisiz?"
            id="comment"
          />
          <button
            className="confirm_btn"
            name="reject"
            disabled={comment.length <= 6 ? true : false}
            onClick={() => {
              dispatch(rejectContract(access, comment, contractDetail?.contract?.id === undefined ? 1 : contractDetail?.contract?.id, 'vps'))
              navigate(`/my-request`)
            }}
          >
            Tasdiqlayman
          </button>
        </div>
      </div>
      <div className="request_content-buttons">
        <div>
          <div className="request_content-buttons">
            <button onClick={() => {
              navigate('/my-request')
            }}>
              <span>{t("back")}</span>
            </button>
          </div>
        </div>
      </div>
      {error && <SignatureModal close={closeErrModal} text={err_msg}/>}
    </div>
  );
};

export default ShowRequestVPS;