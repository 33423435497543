import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PageNotFound = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  
  return (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{textAlign: 'center'}}>
        <h1>OOPs!</h1>
        <p>Page not found</p>
        <p style={{cursor: 'pointer'}} onClick={() => navigate('/')}>{t('back_to_home')}</p>
      </div>
    </div>
  );
};

export default PageNotFound;