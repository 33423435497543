import React from "react";

type Props = {
  bgColor: string;
  iconColor: string;
};

const FavoriteIcon: React.FC<Props> = ({ bgColor, iconColor }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_218)">
        <rect x="4" width="47" height="47" rx="10" fill={bgColor} />
        <rect
          x="4.5"
          y="0.5"
          width="46"
          height="46"
          rx="9.5"
          stroke="url(#paint0_linear_1_218)"
        />
      </g>
      <g clipPath="url(#clip0_1_218)">
        <path
          d="M33.0929 11.875H34.6042C35.8054 11.875 36.9421 12.353 37.7817 13.2055C38.6213 14.045 39.0992 15.1946 39.1121 16.3958V30.6042C39.1121 31.8054 38.6342 32.955 37.7946 33.7945C36.955 34.647 35.8054 35.125 34.6042 35.125H20.3958C19.1946 35.125 18.045 34.6341 17.2054 33.7945C16.3658 32.942 15.8879 31.8054 15.875 30.6042V16.3958C15.875 15.1946 16.3658 14.058 17.2054 13.2055C18.0579 12.3659 19.1946 11.8879 20.3958 11.875H33.08H33.0929Z"
          stroke={iconColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M31.1039 25.0242L28.5852 22.7379C28.2881 22.4796 27.9135 22.3375 27.5131 22.3375C27.1127 22.3375 26.7381 22.4796 26.4539 22.7379L23.9352 25.0242C22.8373 26.0317 21.9331 25.6183 21.9331 24.1329V11.9009H33.1319V24.1329C33.1319 25.6313 32.2793 26.0317 31.1298 25.0242H31.1039Z"
          stroke={iconColor}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_218"
          x="0"
          y="0"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_218"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_218"
          x1="4"
          y1="0"
          x2="51"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={iconColor} />
          <stop offset="0.447916" stopColor={iconColor} stopOpacity="0" />
          <stop offset="1" stopColor={iconColor} />
        </linearGradient>
        <clipPath id="clip0_1_218">
          <rect
            width="31"
            height="31"
            fill={iconColor}
            transform="translate(12 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FavoriteIcon;
