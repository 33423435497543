import { ReducerActions } from "../utilsTypes";

const initialState = {
  language: localStorage.getItem('language') ? localStorage.getItem('language') : 'uz',
};

export const languageReducer = (
  state = initialState,
  action: ReducerActions
) => {
  const { type, payload } = action;
  switch (type) {
    case "SWITCH_LANGUAGE":
      return { ...state, language: payload };
    default:
      return state;
  }
};
