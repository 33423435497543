export const localization = {
  uz: {
    translation: {
      news: 'Bildirishnomalar',
      myVm: 'Virtual mashinalar',
      back: "Orqaga",
      allServices: "Barcha xizmatlar",
      departmentName: "Bo'lim nomi",
      usersOfTheService: "Xizmatdan foydalanuvchilar",
      termOfService: "Xizmat ko'rsatish muddati",
      needDocuments: "Kerakli hujjatlar",
      useOfTheService: "Xizmatdan foydalanish",
      connection: "Bog'lanish",
      contactInformation: "Bog'lanish ma'lumotlari",
      specialist: "Mutaxassis",
      departmentHead: "Bo'lim boshlig'i",
      nsm: "F.I.Sh.",
      birthday: "Tug'ilgan sanasi",
      stir: "STIR",
      position: "Lavozimi",
      mail: "Pochta",
      address: "Manzil",
      phoneNumber: "Telefon raqami",
      close: "Yopish",
      step: "bosqich",
      publicOffer: "Ommaviy oferta",
      gotAcquaintedWithRules: "Qoidalar bilan tanishib chiqdim",
      cancel: "Bekor qilish",
      next: "Keyingi",
      save: "Saqlash",
      contractInfo: "Shartnoma ma'lumotlari",
      props: "Rekvizitlar",
      login: "Login",
      name: "Ismi",
      surname: "Familiyasi",
      midname: "Otasining ismi",
      pasportNumber: "Pasport raqami",
      pasportPin: "JSHSHIR",
      selectServiceType: "Shartnoma obyekti",
      select: "Tanlang",
      internetConnectMethod: "Internetga ulanish manbayi",
      enterCount: "Shartnoma obyekti soni",
      countErrorMessage: "Bitta RACK ga 42 ta dan ortiq UNIT qo'shish mumkin emas!",
      enterOdfCount: "Internetga ulanish manbayi soni",
      deviceType: "Qurilma turi",
      deviceCount: "Qurilma soni",
      unitCount: "UNIT soni",
      onlyOneDevice: "(1 dona qurilma uchun)",
      electorKw: "Elektr iste'moli (1 dona qurilma uchun, W)",
      manage: "Boshqarish",
      contractIncomplete: "Shartnoma to'liq to'ldirilmagan",
      totalPrice: "Jami to'lov miqdori (so'm)",
      unitPrice: "To'lov miqdori(so'm)",
      totalUnitCount: "Jami UNIT soni",
      contractSigning: "Shartnoma imzolash",
      signing: "Imzolash",
      waitsigning: "Imzolanmoqda...",
      successfully: "Muvafaqiyatli qabul qilindi",
      monitorTheStatus:
        "Holatini real vaqt rejimida shaxsiy kabinet orqali kuzatib borishingiz mumkin",
      personalRoom: "shaxsiy kabinet",
      acceptedForConsideration: "Ariza ko'rib chiqish uchun qabul qilindi",
      application: "Ariza",
      contractNumber: "Shartnoma raqami",
      contractDate: "Shartnoma tuzilgan sana",
      contractStatus: "Shartnoma holati",
      termOfContract: "Shartnoma amal qilish muddati",
      paymentAmount: "To'lov miqdori",
      payed: "To'landi",
      indebtedness: "Qarzdorlik",
      uzs: "so'm",
      userInfo: "Foydalanuvchi ma'lumotlari",
      totalInfo: "Umumiy ma'lumotlar",
      pasportInfo: "Pasport ma'lumotlari",
      state: "Fuqaroligi",
      birthdayPalace: "Tug'ilgan joyi",
      liveAddress: "Yashash manzili",
      reviewProcess: "Ko'rib chiqish jarayoni",
      comment: "Izox",
      lifeTime: "Muddat",
      concluded: "Xulosa berdi",
      myContracts: "Shartnomalarim",
      savedServices: "Saqlangan xizmatlar",
      myInfo: "Ma'lumotlarim",
      logout: "Chiqish",
      serviceName: "Xizmat nomi",
      loginToSystem: "Tizimga kirish",
      inDetail: "Batafsil",
      loginError: "Xizmatdan foydalanish uchun tizimga kirishingiz lozim",
      orgName: "Tashkilot nomi",
      yurAddr: "Yuridik manzil",
      mfo: "MFO",
      xxtut: "XXTUT",
      ktut: "KTUT",
      ifut: "IFUT",
      checkingAccount: "Hisob raqami",
      bank: "Bank filiali",
      directorName: "Rahbarning ismi",
      directorSurname: "Rahbarning familiyasi",
      directorMidname: "Rahbarning otasini ismi",
      send: "Yuborish",
      directorFullname: "Rahbarning F.I.SH.",
      yurInfo: "Yuridik shaxs ma'lumotlari",
      engeener: "Yetakchi muhandis",
      faces: "shaxslar",
      internet: "Internet",
      tasix: "TASIX",
      storage_disk: "Xotira",
      storage_type: "Xotira turi",
      vm_status_0: "Birinchi to'lov kutilmoqda",
      vm_status_1: 'Aktiv',
      vm_status_2: "Qarzdorlik uchun o'chirilgan",
      vm_status_3: "Servis vaqtinchalik to'xtatilgan",
      vm_status_4: "Resurs o'chirilgan",
      invoice: "Invoys",
      address_footer: "TOSHKENT SH, QORASUV-3, MINGBULOQ, 38-UY",
      work_day: "DUSHANBA, JUMA, 9:00-18:00",
      workday: "Ish kuni",
      pochta: "Pochta indeksi",
      relax: "Dam olish kunlari:",
      relax_day: "Shanba, Yakshanba",
      links: "Xavolalar",
      clients: "Mijozlar bilan ishlash bo'limi:",
      data: "Data markaz",
      copyright: "Barcha huquqlar himoyalangan",
      history: "Tarix",
      contact: "Bog'lanish",
      call: "Biz bilan bo'glaning",
      about: "Biz haqimizda",
      lang: 'Til',
      lang_billing: 'Avtomatik yuboriladigan xabarnomalar tilini sozlash',
      email_billing: 'Avtomatik yuboriladigan xabarnomalar mail manzilini sozlash',
      service: 'Servis',
      service_support: "Texnik qo'llab-quvvatlash",
      call_center: "Call Markaz",
      back_to_home: "Bosh sahifaga qaytish",
      advantages: "Afzalliklar",
      experience: "Yillik tajriba",
      professional_team: "Professional jamoa",
      improvement: "Doimiy rivojlanish va takomillashtirish",
      individual_approach: "Har bir mijozga induvidual yondashuv",
      range_of_services: "Ko'rsatiladigan xizmatlarning doirasi kengligi",
      technological_infrastructure: "Yuqori texnologik infratuzilma",
      level_of_service: "Yuqori darajadagi servis",
      application_info: "Xizmatdan foydalanish uchun ariza qoldirish",
      organization_name: "F.I.SH./Tashkilot nomi",
      phone: "Telefon",
      email: "Email",
      text: "Matn",
      file_attachment: "Fayl biriktirish",
      upload: "Yuklash",
      download_file: "Faylni yuklab olish",
      see: "Ko'rish",
      service_info: "Xizmat haqida ma'lumot",
      sample_contract: "Shartnoma va xat namunasi",
      application_sample: "Ariza namunasi",
      video_guide: "Video qo'llanma",
      edit: "Taxrirlash",
      contract_info: "Shartnoma ma'lumotlari",
      no_info_vm: "Virtual mashinalar mavjud emas!",
      vat_number: "QQS ro'yxat raqami",
      add: "Qo'shish",
      total: "Jami",
      cant_choose_same_err_msg: "1ta \"Data Markaz\" dan bir xil RACK yoki UNIT tanlay olmaysiz!",
      select_payment_method: "To'lov turini tanlang",
      e_xat_employers: "“E-XAT” kalitidan foydalanuvchi xodimlar sonini kiriting",
      from_budget_funds: "Byudjet mablag’lari hisobidan (30/70)",
      from_own_funds: "O’z mablag’lari hisobidan (100%)",
      configuration: "Konfiguratsiya",
      cloud_servers: "Bulutli serverlar",
      month: "oy",
      enter_name: "Nomini kiriting",
      operating_system: "Operatsion tizim",
      operating_system_version: "Operatsion tizim versiyasi",
      data_store: "Ma'lumotlarni saqlash",
      info_unicon: "\"UNICON.UZ - Fan-texnika va marketing tadqiqotlari markazi\" Mas’uliyati cheklangan jamiyatining mijozlarga xizmatlar ko'rsatishga doir jarayonlarni raqamlashtirishga mo'ljallangan avtomatlashtirilgan axborot tizimi",
      agreement: "Shaxsiy ma'lumotlarimni uzatilishiga va tizimdan foydalanish shartlariga roziman.",
      access_through_system: "tizimi orqali kirish",
      fiz_type: "Fizik",
      yur_type: "Yuridik",
      fiz_and_yur_type: "Fizik va yuridik",
      service_video: "Xizmat haqida video",
      share: "Ulashish",
      video_instruction_list: "Xizmatdan foydalanish video qo'llanmalari",
      feedback: "Qayta aloqa",
      feedback_req: "Qayta aloqa uchun ariza qoldirish"
    },
  },
  ru: {
    translation: {
      back_to_home: "Вернуться на главную страницу",
      call_center: "Колл-центр",
      service_support: 'Техническая поддержка',
      service: 'Cервис',
      lang_billing: 'Настройка языка автоматически отправляемых уведомлений',
      email_billing: 'Настройка адреса автоматически отправляемых уведомлений',
      about: "О нас",
      lang: 'Язык',
      call: "Связаться с нами",
      address_footer: "Ташкент г. Карасув-3, Мингбулак, дом-38",
      work_day: "Понедельник, Пятница, 9:00-18:00",
      workday: "Рабочий день",
      pochta: "Почта индекс",
      relax: "Выходной дни:",
      relax_day: "Суббота, Воскресенье",
      links: "Сслыки",
      clients: "Отдел обслуживания клиентов",
      data: "Дата центр",
      copyright: "Все права защищены",
      history: "История",
      contact: "Контакты",
      invoice: "Инвойс",
      news: 'Уведомления',
      vm_status_0: "Ожидание первой оплаты",
      vm_status_1: 'Активен',
      vm_status_2: "Отключён за долги",
      vm_status_3: "Сервис приостановлен",
      vm_status_4: "Ресурс удален",
      myVm: 'Виртуальные машины',
      storage_type: "Тип хранения",
      internet: "Интернет",
      tasix: "ТАСИХ",
      storage_disk: "Память",
      back: "Назад",
      allServices: "Все услуги",
      departmentName: "Название отдела",
      usersOfTheService: "Кто может пользоваться этой услугой",
      termOfService: "Сроки оказания услуги",
      needDocuments: "Необходимые документы",
      useOfTheService: "Получить услугу",
      connection: "Связаться",
      contactInformation: "Информация для соединения",
      specialist: "Специалист",
      departmentHead: "Начальник отдела",
      nsm: "Ф.И.О.",
      birthday: "Дата рождения",
      stir: "ИНН",
      position: "Должность",
      mail: "Эл.почта",
      address: "Адрес",
      phoneNumber: "Номер телефона",
      close: "Закрыть",
      step: "этап",
      publicOffer: "Публичная оферта",
      gotAcquaintedWithRules: "Ознакомлен(а) с правилами публичной оферты",
      cancel: "Отменить",
      next: "Следующий",
      save: "Сохранить",
      contractInfo: "Информации контракта",
      props: "Реквизиты",
      login: "Логин",
      name: "Имя",
      surname: "Фамилия",
      midname: "Отчесто",
      pasportNumber: "Серия и номер паспорта",
      pasportPin: "ПИНФЛ",
      selectServiceType: "Объект контракта",
      select: "Выберите",
      internetConnectMethod: "Источник соединения с интернетом",
      enterCount: "Количество объектов контракта",
      countErrorMessage: "Нельзя добавить более 42 UNITов в одну СТОЙКУ!",
      enterOdfCount: "Количество источников для соединения с интернетом",
      deviceType: "Тип устройства",
      deviceCount: "Количество устройства",
      unitCount: "Количество UNITов",
      onlyOneDevice: "(для 1 устройства)",
      electorKw: "Расход электроэнергии (для 1 устройства, W)",
      manage: "Управление",
      contractIncomplete: "Контракт полностью не заполнен",
      totalPrice: "Общая сумма платежа (сум)",
      unitPrice: "Сумма платежа(сум)",
      totalUnitCount: "Общее количество UNITов",
      contractSigning: "Подписать контракт",
      signing: "Подписать",
      waitsigning: "подписывается...",
      successfully: "Успешно был принят",
      monitorTheStatus:
        "Вы можете следить за статусом в режиме реального времени через",
      personalRoom: "личный кабинет",
      acceptedForConsideration: "Заявка принята к рассмотрению",
      application: "Заявка",
      contractNumber: "Номер договора",
      contractDate: "Дата заключения договора",
      contractStatus: "Статус договора",
      termOfContract: "Срок договора",
      paymentAmount: "Сумма договора",
      payed: "Оплачено",
      indebtedness: "Задолженность",
      uzs: "сум",
      userInfo: "Информация о пользователе",
      totalInfo: "Общие сведения",
      pasportInfo: "Паспортные данные",
      state: "Гражданство",
      birthdayPalace: "Место рождения",
      liveAddress: "Адрес проживания",
      reviewProcess: "Обзор процесса",
      comment: "Комментарий",
      lifeTime: "Срок",
      concluded: "Дал заключение",
      myContracts: "Мои договора",
      savedServices: "Избранное",
      myInfo: "Мои реквизиты",
      logout: "Выйти",
      serviceName: "Название услуги",
      loginToSystem: "Войти в систему",
      inDetail: "Подробнее",
      loginError: "Вы должны войти в систему, чтобы использовать сервис",
      orgName: "Название организации",
      yurAddr: "Юридический адрес",
      mfo: "МФО",
      xxtut: "ОКОНХ",
      ktut: "ОКПО",
      ifut: "ОКЭД",
      checkingAccount: "Расчётный счёт",
      bank: "Филиал банка",
      directorName: "Имя директора",
      directorSurname: "Фамилия директора",
      directorMidname: "Отчество директора",
      send: "Отправить",
      directorFullname: "Ф.И.О. директора",
      yurInfo: "Информация о юридическом лице",
      engeener: "Ведущий инжинер",
      faces: "лица",
      advantages: "Преимущества",
      experience: "Лет опыта",
      professional_team: "Профессиональная команда",
      improvement: "Постоянное развитие и улучшение",
      individual_approach: "Индивидуальный подход к каждому клиенту",
      range_of_services: "Широкий спектр предоставляемых услуг",
      technological_infrastructure: "Высокотехнологичная инфраструктура",
      level_of_service: "Высокий уровень обслуживания",
      application_info: "Подать заявку для получения услуги",
      organization_name: "Ф.И.О./Название организации",
      phone: "Телефон",
      email: "Электронная почта",
      text: "Комментарий",
      file_attachment: "Прикрепить файл",
      upload: "Загрузить",
      see: "Видеть",
      service_info: "Информация об услуге",
      sample_contract: "Образец договора и письма",
      application_sample: "Образец заявления",
      video_guide: "Видео инструкция",
      edit: "Редактировать",
      contract_info: "Информация о контракте",
      no_info_vm: "Нет информации о виртуальных машинах!",
      vat_number: "Регистрационный номер НДС",
      add: "Добавить",
      total: "Общий",
      cant_choose_same_err_msg: "Вы не можете выбрать одну и ту же RACK или UNIT из 1 «ЦОД»!",
      select_payment_method: "Выберите способ оплаты",
      e_xat_employers: "Введите количество сотрудников с помощью клавиши «E-XAT».",
      from_budget_funds: "За счет бюджетных средств (30/70)",
      from_own_funds: "За счет собственных средств (100%)",
      configuration: "Конфигурация",
      cloud_servers: "Облачные серверы",
      month: "месяц",
      enter_name: "Введите имя",
      operating_system: "Операционная система",
      operating_system_version: "Версия операционной системы",
      data_store: "Хранилище данных",
      info_unicon: "Автоматизированная информационная система Общества с ограниченной ответственностью «UNICON.UZ - Центр научных, технических и маркетинговых исследований», предназначенная для цифровизации процессов обслуживания клиентов",
      agreement: "Я согласен на передачу своих персональных данных и условия использования системы.",
      access_through_system: "Войти через систему",
      fiz_type: "Физические",
      yur_type: "Юридические",
      fiz_and_yur_type: "Физические и юридические",
      service_video: "Видеоролик об услуге",
      download_file: "Скачать файл",
      share: "Поделиться",
      video_instruction_list: "Видео инструкции для получения услуги",
      feedback: "Обратная связь",
      feedback_req: "Оставить заявку на обратную связь"
    },
  },
  en: {
    translation: {
      news: 'Notifications',
      myVm: 'Virtual machines',
      back: "Back",
      allServices: "All services",
      departmentName: "Department name",
      usersOfTheService: "Users of the service",
      termOfService: "Terms of service provision",
      needDocuments: "Required documents",
      useOfTheService: "Use service",
      connection: "Contact",
      contactInformation: "Contact information",
      specialist: "Specialist",
      departmentHead: "Department head",
      nsm: "S.N.M.",
      birthday: "Date of birth",
      stir: "STIR",
      position: "Position",
      mail: "Mail",
      address: "Address",
      phoneNumber: "Phone number",
      close: "Close",
      step: "stage",
      publicOffer: "Public offer",
      gotAcquaintedWithRules: "I got acquainted with the rules",
      cancel: "Cancel",
      next: "Next",
      save: "Save",
      contractInfo: "Contract information",
      props: "Props",
      login: "Login",
      name: "Name",
      surname: "Surname",
      midname: "Father's name",
      pasportNumber: "Passport number",
      pasportPin: "JSHSHIR",
      selectServiceType: "The object of the contract",
      select: "Choose",
      internetConnectMethod: "Internet connection resource",
      enterCount: "Contract object number",
      countErrorMessage: "Cannot add more than 42 UNITS to one RACK!",
      enterOdfCount: "Internet connection resource number",
      deviceType: "Device type",
      deviceCount: "Device number",
      unitCount: "The number of UNITS",
      onlyOneDevice: "(for 1 device)",
      electorKw: "Power consumption (for 1 device, W)",
      manage: "Manage",
      contractIncomplete: "The contract is incomplete",
      totalPrice: "Total payment amount (sum)",
      unitPrice: "Amount of payment (sum)",
      totalUnitCount: "Total number of UNITS",
      contractSigning: "Signing the contract",
      signing: "Signing",
      waitsigning: "Signing...",
      successfully: "Successfully accepted",
      monitorTheStatus:
        "You can monitor the status in real time through your personal account",
      personalRoom: "private office",
      acceptedForConsideration: "The application has been accepted for consideration",
      application: "Application",
      contractNumber: "Contract number",
      contractDate: "Contract date",
      contractStatus: "Contract status",
      termOfContract: "Term of contract",
      paymentAmount: "Payment amount",
      payed: "Paid",
      indebtedness: "Indebtedness",
      uzs: "sum",
      userInfo: "User Information",
      totalInfo: "General information",
      pasportInfo: "Passport information",
      state: "Citizenship",
      birthdayPalace: "place of birth",
      liveAddress: "Residential address",
      reviewProcess: "Review process",
      comment: "Explanation",
      lifeTime: "Term",
      concluded: "Concluded",
      myContracts: "My contracts",
      savedServices: "Favourites",
      myInfo: "My banking details",
      logout: "Logout",
      serviceName: "Service name",
      loginToSystem: "Login",
      inDetail: "More",
      loginError: "You must log in to use the service",
      orgName: "Name of the organization",
      yurAddr: "Legal address",
      mfo: "MFO",
      xxtut: "XXTUT",
      ktut: "KTUT",
      ifut: "IFUT",
      checkingAccount: "Account number",
      bank: "Bank branch",
      directorName: "Director name",
      directorSurname: "Director surname",
      directorMidname: "Director middle name",
      send: "Send",
      directorFullname: "Leader's S.N.M.",
      yurInfo: "Legal entity information",
      engeener: "Lead engineer",
      faces: "persons",
      internet: "Internet",
      tasix: "TASIX",
      storage_disk: "Memory",
      storage_type: "Memory type",
      vm_status_0: "The first payment is pending",
      vm_status_1: 'Active',
      vm_status_2: "Disabled for debt",
      vm_status_3: "The service is temporarily suspended",
      vm_status_4: "The resource is disabled",
      invoice: "Invoice",
      address_footer: "TOSHKENT city, QORASUV-3, MINGBULOQ, 38-home",
      work_day: "MONDAY, FRIDAY, 9:00-18:00",
      workday: "Work day",
      pochta: "Zip code",
      relax: "Days off:",
      relax_day: "Saturday, Sunday",
      links: "References",
      clients: "Customer service department:",
      data: "Data center",
      copyright: "all rights reserved",
      history: "History",
      contact: "Contact us",
      call: "Contact us",
      about: "About us",
      lang: 'Language',
      lang_billing: 'Setting the language of automatic notifications',
      email_billing: 'Configuring the email address for automatic notifications',
      service: 'Service',
      service_support: "Technical support",
      call_center: "Call Center",
      back_to_home: "Return to home page",
      advantages: "Advantages",
      experience: "Years of experience",
      professional_team: "Professional team",
      improvement: "Continuous development and improvement",
      individual_approach: "Individual approach to each client",
      range_of_services: "Wide range of services provided",
      technological_infrastructure: "High technological infrastructure",
      level_of_service: "High level of service",
      application_info: "Submit an application to receive the service",
      organization_name: "S.N.M./Name of organization",
      phone: "Phone",
      email: "Email",
      text: "Text",
      file_attachment: "Attach file",
      upload: "Upload",
      see: "See",
      service_info: "Information about the service",
      sample_contract: "Sample contract and letter",
      application_sample: "Application sample",
      video_guide: "Video instruction",
      edit: "Edit",
      contract_info: "Contract information",
      no_info_vm: "There is no information about virtual machines!",
      vat_number: "VAT registration number",
      add: "Add",
      total: "Total",
      cant_choose_same_err_msg: "You cannot select the same RACK or UNIT from 1 “Data Center”!",
      select_payment_method: "Select a payment method",
      e_xat_employers: "Enter the number of employees using the \"E-XAT\" key.",
      from_budget_funds: "At the expense of budget funds (30/70)",
      from_own_funds: "From own funds (100%)",
      configuration: "Configuration",
      cloud_servers: "Cloud servers",
      month: "month",
      enter_name: "Enter the name",
      operating_system: "Operating system",
      operating_system_version: "Operating system version",
      data_store: "Data store",
      info_unicon: "Automated information system of \"UNICON.UZ - Center for Scientific, Technical and Marketing Research\" Limited Liability Company, designed for digitalization of customer service processes",
      agreement: "I agree to the transfer of my personal data and the terms of use of the system.",
      access_through_system: "access through the system",
      fiz_type: "Natural",
      yur_type: "Legal",
      fiz_and_yur_type: "Natural and legal",
      service_video: "Video about the service",
      download_file: "Download file",
      share: "Share",
      video_instruction_list: "Video instructions for receiving the service",
      feedback: "Feedback",
      feedback_req: "Leave a request for feedback"
    },
  },
};
