import React from "react";

type Props = {
  bgColor: string;
  iconColor: string;
};

const MyRequestIcon: React.FC<Props> = ({ bgColor, iconColor }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_200)">
        <rect x="4" width="47" height="47" rx="10" fill={bgColor} />
        <rect
          x="4.5"
          y="0.5"
          width="46"
          height="46"
          rx="9.5"
          stroke="url(#paint0_linear_1_200)"
        />
      </g>
      <path
        d="M20.6666 15.8333H34V17.1666H20.6666V15.8333ZM20.6666 22.4999H27.405V23.8333H20.6666V22.4999ZM33.4816 19.1666H20.6666V20.4999H32.1491L33.4816 19.1666ZM24.6608 33.2266C24.8849 33.4836 25.1694 33.6809 25.4887 33.8008C25.808 33.9206 26.152 33.9593 26.49 33.9133H32.9925C33.1472 33.9133 33.2955 33.8518 33.4049 33.7424C33.5143 33.633 33.5758 33.4846 33.5758 33.3299C33.5758 33.1752 33.5143 33.0268 33.4049 32.9174C33.2955 32.808 33.1472 32.7466 32.9925 32.7466H26.49C25.7308 32.7333 25.8658 32.0741 25.9725 31.8699C26.1115 31.6303 26.2199 31.3741 26.295 31.1074C26.3448 30.8928 26.3213 30.6677 26.2283 30.4681C26.1352 30.2684 25.978 30.1056 25.7816 30.0058C25.4662 29.8636 25.1128 29.8288 24.7757 29.9066C24.4386 29.9844 24.1362 30.1706 23.915 30.4366C23.6283 30.7324 23.23 31.1849 22.8483 31.6249C23.0841 30.7008 23.39 29.5149 23.69 28.3583C23.7759 28.0947 23.7588 27.8084 23.6421 27.5569C23.5253 27.3055 23.3176 27.1076 23.0608 27.0033C22.7764 26.9057 22.4655 26.9196 22.191 27.0423C21.9165 27.1649 21.6987 27.3871 21.5816 27.6641C21.2216 28.3566 18.3716 33.1324 18.3433 33.1808C18.304 33.2465 18.2781 33.3194 18.2669 33.3952C18.2558 33.471 18.2597 33.5482 18.2784 33.6225C18.2972 33.6968 18.3303 33.7667 18.3761 33.8281C18.4218 33.8896 18.4792 33.9415 18.545 33.9808C18.6778 34.0601 18.8367 34.0834 18.9867 34.0456C19.1368 34.0078 19.2656 33.9119 19.345 33.7791C19.4516 33.5999 21.7266 29.7891 22.4441 28.5166C21.9041 30.5999 21.3091 32.9183 21.2741 33.1266C21.2347 33.2798 21.2466 33.4416 21.3078 33.5874C21.369 33.7332 21.4763 33.855 21.6133 33.9341C21.7658 34.0097 21.9392 34.0325 22.1061 33.9987C22.273 33.9649 22.4239 33.8765 22.535 33.7474C22.64 33.6524 22.8366 33.4266 23.3325 32.8508C23.7944 32.3074 24.2676 31.7737 24.7516 31.2499C24.9333 31.0583 25.0433 31.1433 24.9366 31.3316C24.728 31.5953 24.5927 31.9094 24.5444 32.2421C24.4961 32.5749 24.5365 32.9145 24.6616 33.2266H24.6608Z"
        fill={iconColor}
      />
      <path
        d="M37.3332 27.0792V35.6667H17.3332V12.3334H37.3332V16.4942L38.4415 15.3859C38.6121 15.2176 38.7992 15.0669 38.9998 14.9359V11.5001C38.9998 11.2791 38.912 11.0671 38.7558 10.9108C38.5995 10.7545 38.3875 10.6667 38.1665 10.6667H16.4998C16.2788 10.6667 16.0669 10.7545 15.9106 10.9108C15.7543 11.0671 15.6665 11.2791 15.6665 11.5001V36.5001C15.6665 36.7211 15.7543 36.9331 15.9106 37.0893C16.0669 37.2456 16.2788 37.3334 16.4998 37.3334H38.1665C38.3875 37.3334 38.5995 37.2456 38.7558 37.0893C38.912 36.9331 38.9998 36.7211 38.9998 36.5001V26.8559C38.4887 27.1268 37.8976 27.206 37.3332 27.0792V27.0792Z"
        fill={iconColor}
      />
      <path
        d="M42.4401 18.8841L42.0043 18.4483C42.227 18.154 42.3398 17.7909 42.3231 17.4222C42.3063 17.0534 42.1611 16.7021 41.9126 16.4291C41.5901 16.1448 41.1682 15.9995 40.739 16.0248C40.3098 16.0501 39.9079 16.2439 39.621 16.5641L29.1835 27L28.486 29.6183C28.4749 29.6526 28.4736 29.6892 28.4821 29.7242C28.4906 29.7592 28.5086 29.7911 28.5341 29.8165C28.5597 29.8418 28.5917 29.8596 28.6268 29.8679C28.6618 29.8762 28.6984 29.8746 28.7326 29.8633L31.3418 29.1575L41.0851 19.4141L41.4968 19.8266C41.5255 19.8554 41.5484 19.8895 41.5639 19.927C41.5795 19.9645 41.5875 20.0048 41.5875 20.0454C41.5875 20.086 41.5795 20.1263 41.5639 20.1638C41.5484 20.2013 41.5255 20.2354 41.4968 20.2641L37.3993 24.3616C37.2743 24.4867 37.2041 24.6564 37.2042 24.8332C37.2043 25.01 37.2746 25.1796 37.3997 25.3046C37.5248 25.4295 37.6944 25.4997 37.8713 25.4996C38.0481 25.4996 38.2176 25.4292 38.3426 25.3041L42.4401 21.2066C42.7481 20.8985 42.9211 20.4807 42.9211 20.045C42.9211 19.6093 42.7481 19.1915 42.4401 18.8833V18.8841Z"
        fill={iconColor}
      />
      <defs>
        <filter
          id="filter0_d_1_200"
          x="0"
          y="0"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_200"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_200"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_200"
          x1="4"
          y1="0"
          x2="51"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={bgColor} />
          <stop offset="0.447916" stopColor={bgColor} stopOpacity="0" />
          <stop offset="1" stopColor={bgColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MyRequestIcon;
