import * as changeTypes from '../actionTypes/changeTypes'
import {handleType} from "./handleType";

export const changeAction = ({name, value}: any) => {
    return (dispatch: any) => {
      dispatch(handleType(changeTypes.TYPING, {value, name}))
    }
}

export const clearChange = () => {
  return (dispatch: any) => {
    dispatch(handleType(changeTypes.INITIAL_STATE, null))
  }
}