export const CONTRACTS_LOADING = "CONTRACTS_LOADING";
export const CONTRACTS_ERROR = "CONTRACTS_ERROR";
export const GET_SERVICES = "GET_SERVICES";
export const GET_GROUP_SERVICES = "GET_GROUP_SERVICES";
export const GET_SERVICE_DETAIL = "GET_SERVICE_DETAIL";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_OFFER_DETAIL = "GET_OFFER_DETAIL";
export const GET_SAVED_SERVICES = "GET_SAVED_SERVICES";
export const GET_TARIFS_ELEMENTS_DEVICES = "GET_TARIFS_ELEMENTS_DEVICES";
export const CREATE_SAVED_SERVICE = "CREATE_SAVED_SERVICE";
export const CREATE_CONTRACT = "CREATE_CONTRACT";
export const CREATE_SAVED_CONTRACT = "CREATE_SAVED_CONTRACT";
export const SAVE_CONTRACT = "SAVE_CONTRACT";
export const SAVE_PKCS = "SAVE_PKCS";
export const CLEAR_SAVED_CONTRACTS = "CLEAR_SAVED_CONTRACTS";
export const GET_USER_CONTRACTS = "GET_USER_CONTRACTS";
export const GET_CONTRACT_DETAIL = "GET_CONTRACT_DETAIL";
export const SEND_TARIF_DEVICES = "SEND_TARIF_DEVICES";
export const CLEAN_USER_DETAILS = "CLEAN_USER_DETAILS";
export const GET_CONNECT_METHODS = "GET_CONNECT_METHODS";
export const GET_GROUP_ADMIN = "GET_GROUP_ADMIN";
export const GET_GROUP_PINNED_USER = "GET_GROUP_PINNED_USER";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const SAVE_DEVICES = "SAVE_DEVICES";
export const CLEAR_DEVICES = "CLEAR_DEVICES";
export const CONTRACTS_NUM = "CONTRACTS_NUM";
export const CONTRACTS_REJECT_START = 'CONTRACTS_REJECT_START'
export const CONTRACTS_REJECT_SUCCESS = 'CONTRACTS_REJECT_SUCCESS'
export const CONTRACTS_REJECT_FAILED = 'CONTRACTS_REJECT_FAILED'
export const GET_DATA_CENTER_LIST_START = 'GET_DATA_CENTER_LIST_START'
export const GET_DATA_CENTER_LIST_SUCCESS = 'GET_DATA_CENTER_LIST_SUCCESS'
export const GET_DATA_CENTER_LIST_ERROR = 'GET_DATA_CENTER_LIST_ERROR'
export const POST_CALCULATE_COLOCATION_START = 'POST_CALCULATE_COLOCATION_START'
export const POST_CALCULATE_COLOCATION_SUCCESS = 'POST_CALCULATE_COLOCATION_SUCCESS'
export const POST_CALCULATE_COLOCATION_ERROR = 'POST_CALCULATE_COLOCATION_ERROR'
export const GET_SERVICE_DETAIL_LIST = 'GET_SERVICE_DETAIL_LIST'
export const CONTRACTS_LIST_ERROR = 'CONTRACTS_LIST_ERROR'
export const GET_SERVICE_MARKETING_VIDEO = 'GET_SERVICE_MARKETING_VIDEO'
export const GET_SERVICE_MARKETING_VIDEO_ERROR = 'GET_SERVICE_MARKETING_VIDEO_ERROR'
export const GET_SERVICE_TUTORIAL_VIDEO = 'GET_SERVICE_TUTORIAL_VIDEO'
export const GET_SERVICE_TUTORIAL_VIDEO_ERROR = 'GET_SERVICE_TUTORIAL_VIDEO_ERROR'
export const GET_SERVICE_REKLAMA = 'GET_SERVICE_REKLAMA'
export const GET_SERVICE_REKLAMA_ERROR = 'GET_SERVICE_REKLAMA_ERROR'
