import React from "react";

const NotificationIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 48 48" fill="#0e0e4b" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.62 28.34L39.75 27.64C39.5161 27.4528 39.3273 27.2154 39.1975 26.9453C39.0677 26.6753 39.0002 26.3796 39 26.08V18C38.9988 14.5277 37.793 11.1633 35.5883 8.48074C33.3835 5.79819 30.3164 3.96364 26.91 3.29C26.7439 2.6458 26.3684 2.07508 25.8425 1.6676C25.3167 1.26012 24.6703 1.03899 24.005 1.03899C23.3397 1.03899 22.6933 1.26012 22.1675 1.6676C21.6416 2.07508 21.2661 2.6458 21.1 3.29C17.6918 3.96159 14.6223 5.79527 12.4156 8.47802C10.2088 11.1608 9.00162 14.5263 9 18V26.08C8.99981 26.3796 8.93232 26.6753 8.80251 26.9453C8.67269 27.2154 8.48387 27.4528 8.25 27.64L7.38 28.34C6.32928 29.18 5.48023 30.2449 4.8953 31.4563C4.31037 32.6677 4.00443 33.9948 4 35.34V37C4 38.0609 4.42143 39.0783 5.17157 39.8284C5.92172 40.5786 6.93913 41 8 41H16.26C16.7108 42.7076 17.714 44.2181 19.1132 45.2958C20.5124 46.3736 22.2289 46.958 23.995 46.958C25.7611 46.958 27.4776 46.3736 28.8768 45.2958C30.276 44.2181 31.2792 42.7076 31.73 41H40C41.0609 41 42.0783 40.5786 42.8284 39.8284C43.5786 39.0783 44 38.0609 44 37V35.36C43.9986 34.0114 43.6941 32.6804 43.109 31.4653C42.524 30.2502 41.6733 29.1821 40.62 28.34ZM24 43C23.3019 42.9958 22.6171 42.809 22.0137 42.4581C21.4102 42.1072 20.909 41.6046 20.56 41H27.45C27.1001 41.606 26.5974 42.1096 25.992 42.4606C25.3866 42.8115 24.6998 42.9975 24 43ZM40 37H8V35.36C8.00107 34.6106 8.17058 33.871 8.49599 33.196C8.8214 32.5209 9.2944 31.9276 9.88 31.46L10.75 30.76C11.4516 30.1983 12.0181 29.4861 12.4075 28.676C12.797 27.866 12.9994 26.9788 13 26.08V18C13 15.0826 14.1589 12.2847 16.2218 10.2218C18.2847 8.15893 21.0826 7 24 7C26.9174 7 29.7153 8.15893 31.7782 10.2218C33.8411 12.2847 35 15.0826 35 18V26.08C34.9991 26.9805 35.2008 27.8696 35.5903 28.6815C35.9798 29.4934 36.5471 30.2072 37.25 30.77L38.12 31.47C38.7043 31.9366 39.1765 32.5282 39.5019 33.2015C39.8272 33.8747 39.9974 34.6123 40 35.36V37Z"
        fill="#0e0e4b"/>
    </svg>
  )
}

export default NotificationIcon