import React, {useEffect, useRef, useState} from "react";
import DropIcon from "../../images/dropIcon";
import {UserDetailHandler} from "../../hooks/handlers";
import DeleteIcon from "../../images/deleteIcon";
import Arrow2 from "../../images/arrow2";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Server} from "../../store/models";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getBank} from "../../store/actions/accountActions";
import {
  clearVpsCalculation, createVpsContract, getOperationSystems, getOperationSystemsDetail, getVpsTariffs,
  postVpsBilling, saveVpsContractData
} from "../../store/actions/vpsActions";
import Steps from "../../components/Steps/Steps";
import {useLocation} from 'react-router-dom';
import ReverseIcon from "../../images/ReverseIcon";
import {axios2} from "../../axios";
import {HooksCommission} from "../../components/eImzoConfig";
import {refreshUserDetail} from "../../store/actions/contractActions";

const VpsContractInfo: React.FC = () => {
  const {AppLoad, id} = HooksCommission()
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {access} = useAppSelector((state) => state.auth);
  const {userDetail} = useAppSelector((state) => state.user);
  const {
    operationSystems,
    operationSystemsDetail,
    vpsTariffs,
    vpsBilling,
  } = useAppSelector(state => state.vps)

  const service_id = localStorage.getItem('service_id')
  const error_code = localStorage.getItem('error_code')

  const [height, setHeight] = useState<number>(0)

  const [openRek, setOpenRek] = useState<boolean>(true);
  const [openContract, setOpenContract] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userDetail?.mob_phone_no
  );
  const [address, setAddress] = useState<string | undefined>(
    userDetail?.per_adr
  );
  const [stir, setStir] = useState<string | undefined>(userDetail?.tin)
  const [vatNumber, setVatNumber] = useState<string | undefined>(userDetail?.vatNumber)
  const [email, setEmail] = useState<string | undefined>(userDetail?.email)
  const [mfo, setMfo] = useState<string | undefined>(userDetail?.bank_mfo?.mfo);
  const [xxtut, setXxtut] = useState<string | undefined>(userDetail?.xxtut);
  const [ktut, setKtut] = useState<string | undefined>(userDetail?.ktut);
  const [ifut, setIfut] = useState<string | undefined>(userDetail?.oked);
  const [checkingAccount, setCheckingAccount] = useState<string | undefined>(
    userDetail?.paymentAccount
  );
  const [bank, setBank] = useState<string | undefined>("");
  const [directorName, setDirectorName] = useState<string | undefined>(
    userDetail?.director_firstname
  );
  const [directorSurname, setDirectorSurname] = useState<string | undefined>(
    userDetail?.director_lastname
  );
  const [directorMidname, setDirectorMidname] = useState<string | undefined>(
    userDetail?.director_middlename
  );

  const valid = (e: any, setState: any) => {
    const re = /^[a-zA-Z' ]*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState(e.target.value);
    }
  };

  const sendMfo = () => {
    dispatch(getBank(access, mfo))
  };

  const handleValidateYurUser = () => {
    if (!phoneNumber?.length) return true;
    if (!mfo?.length) return true;
    if (!ifut?.length) return true;
    if (!checkingAccount?.length) return true;
    if (!bank?.length) return true;
    if (!directorName?.length) return true;
    if (!directorSurname?.length) return true;
    if (!directorMidname?.length) return true;
    if (!email?.length) return true;
  };
  const handleValidateFizUser = () => {
    if (!phoneNumber?.length) return true;
    if (!address?.length) return true;
    if (!email?.length) return true;
  };

  const [server, setServer] = useState<Server[]>([
    {
      tariff: null,
      cpu: null,
      ram: null,
      system_storage: null,
      system_storage_disk: null,
      internet: null,
      is_imut: false,
      billing_status: 4,
      tasix: null,
      imut: null,
      count_vm: 1,
      image_id: null,
      os_type_id: '',
      account_id: null,
      data_disks: [],
      vm_systems: [{
        ipv_address: true,
        vm_name: '',
        account_id: null,
        vm_id: null,
        id: null,
      }],
    }
  ])

  const serverStateJSON = localStorage.getItem('server')
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getVpsTariffs(access))
    dispatch(getOperationSystems(access))
    if (mfo) {
      dispatch(getBank(access, mfo)).then((res: any) => {
        setBank(res.bank_name)
      })
    }
    heightCalculation()
  }, [access, dispatch])

  useEffect(() => {
    AppLoad()
    validationImzo()
  }, [])

  useEffect(() => {
    if (!handleSecondValidateForCalculation()) return postBilling(server)
  }, [server]);

  useEffect(() => {
    if (serverStateJSON) {
      const storedServerState = JSON.parse(serverStateJSON ? serverStateJSON : '[]');
      setServer(storedServerState);
    }
  }, [serverStateJSON]);

  const validationImzo = () => {
    const pin = id?.find((item, index) => {
      // @ts-ignore
      return item?.PINFL === userDetail?.pin
    })
    // @ts-ignore
    const byId = document.getElementById(`itm-${pin?.serialNumber}`)
    byId?.removeAttribute('disabled')
    byId?.setAttribute('selected', 'true')
    return byId
  }

  validationImzo()

  const handleServerAdd = () => {
    const abc: Server[] = [...server, {
      tariff: null,
      cpu: null,
      ram: null,
      system_storage: null,
      system_storage_disk: null,
      internet: null,
      is_imut: false,
      tasix: null,
      imut: null,
      count_vm: 1,
      image_id: null,
      account_id: null,
      billing_status: 4,
      os_type_id: '',
      data_disks: [],
      vm_systems: [{
        ipv_address: false,
        vm_name: '',
        account_id: null,
        vm_id: null,
        id: null,
      }]
    }]
    setServer(abc)
  }

  const addSystem = (i: number) => {
    const updatedServerState = [...server]
    updatedServerState[i].vm_systems.push({
      ipv_address: false,
      vm_name: '',
      account_id: null,
      vm_id: null,
      id: null,
    })
    updatedServerState[i].count_vm = updatedServerState[i].vm_systems.length
    updatedServerState[i].billing_status = 4
    setServer(updatedServerState)
  }

  const timeoutId = useRef<any>()
  const postBilling = (value: Server[]) => {
    const filteredServers = value.filter((serverItem) => {
      return serverItem.billing_status !== 3 || (
        Array.isArray(serverItem.data_disks) && serverItem.data_disks.some((disk) => disk.status !== 3)
      );
    });
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      dispatch(postVpsBilling(access, {vms: filteredServers}, handleSecondValidateForCalculation()))
    }, 500)
  }

  const handleServerDelete = (i: number) => {
    const value = [...server]
    if (value[i].account_id === null) {
      value.splice(i, 1)
    } else {
      value[i].billing_status = 3
    }
    postBilling(value)
    setServer(value)
  }

  const serverDelete = (i: number) => {
    const value = [...server]
    value[i].vm_systems.splice(value[i].vm_systems.length - 1, 1)
    value[i].count_vm = value[i].vm_systems.length
    value[i].billing_status = 4
    setServer(value)
    postBilling(value)
  }

  const changeServer = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, i: number) => {
    const {name, value} = e.target;
    const check = e.target as HTMLInputElement

    if (name === 'is_imut') {
      const updatedServer = [...server];
      updatedServer[i] = {
        ...updatedServer[i],
        [name]: !updatedServer[i].is_imut,
      };
      if (!check.checked) {
        updatedServer[i].is_imut = false
        updatedServer[i].imut = null
      }
      updatedServer[i].billing_status = 4
      setServer(updatedServer);
      postBilling(updatedServer)
    } else {
      if (name === 'tasix' || name === 'internet') {
        const updatedServer = [...server];
        updatedServer[i] = {
          ...updatedServer[i],
          [name]: Number(value)
        };
      }
      if (
        name === 'cpu' ||
        name === 'ram' ||
        name === 'system_storage_disk' ||
        name === 'tasix' ||
        name === 'imutInput' ||
        name === 'internet' ||
        name === 'tariff' ||
        name === 'imut'
      ) {
        // Validate input as an integer number
        if (/^-?\d*$/.test(value)) {
          const updatedServer = [...server];
          updatedServer[i] = {
            ...updatedServer[i],
            [name]: Number(value)
          };
          setServer(updatedServer);
          postBilling(updatedServer)
          updatedServer[i].billing_status = 4
          if (Number(updatedServer[i].tariff) > 0) {
            const tariffId: any = vpsTariffs.find((item) => (
              Number(item.id) === Number(updatedServer[i].tariff)
            ))
            updatedServer[i].billing_status = 4
            updatedServer[i].cpu = tariffId?.vps_device?.cpu
            updatedServer[i].ram = tariffId?.vps_device?.ram
            if (tariffId?.vps_device?.data_disks === undefined) {
              updatedServer[i].data_disks = []
            } else {
              updatedServer[i].data_disks = tariffId?.vps_device?.data_disks
            }
            updatedServer[i].internet = tariffId?.vps_device?.internet
            updatedServer[i].tasix = tariffId?.vps_device?.tasix
            updatedServer[i].system_storage = tariffId?.vps_device?.system_storage
            updatedServer[i].system_storage_disk = tariffId?.vps_device?.system_storage_disk
            updatedServer[i].imut = tariffId?.vps_device?.imut
            updatedServer[i].vm_systems?.some((s) => s.ipv_address = tariffId?.vps_device?.ipv_address)
            updatedServer[i].vm_systems?.some((s) => s.account_id = tariffId?.vps_device?.account_id)
            updatedServer[i].vm_systems?.some((s) => s.vm_id = tariffId?.vps_device?.vm_id)
            postBilling(updatedServer)
          } else {
            updatedServer[i].tariff = null
            updatedServer[i].billing_status = 4
            dispatch(clearVpsCalculation())
            postBilling(updatedServer)
          }
        }
      } else {
        if (name === 'storage') {
          const updatedServer = [...server]
          updatedServer[i].billing_status = 4
          updatedServer[i] = {
            ...updatedServer[i]
          }
          setServer(updatedServer)
          postBilling(updatedServer)
        }
        if (name === 'os_type') {
          const updatedServer = [...server];
          // updatedServer[i] = {
          //   ...updatedServer[i],
          //   // [name]: value
          // };
          updatedServer[i].billing_status = 4
          updatedServer[i].os_type_id = value
          dispatch(getOperationSystemsDetail(access, value))
          setServer(updatedServer);
          postBilling(updatedServer)
        } else {
          const updatedServer = [...server];
          updatedServer[i] = {
            ...updatedServer[i],
            [name]: value
          };
          updatedServer[i].billing_status = 4
          setServer(updatedServer);
          postBilling(updatedServer)
        }
      }
    }
  }

  const changeServerNum = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, i: number, dataIndex: number) => {
    const check = e.target as HTMLInputElement
    const updatedServer: Server[] = [...server];
    if (e.target.name === 'ipv_address') {
      if (check.checked === false) {
        updatedServer[dataIndex].internet = null
        updatedServer[dataIndex].tasix = null
      }
      updatedServer[dataIndex].vm_systems[i].ipv_address = !updatedServer[dataIndex].vm_systems[i].ipv_address
      updatedServer[dataIndex].billing_status = 4;
    } else if (e.target.name === 'vm_name') {
      if (e.target.value === '' || /^[A-Za-z0-9]+$/.test(e.target.value)) {
        updatedServer[dataIndex].vm_systems[i].vm_name = e.target.value;
        updatedServer[dataIndex].billing_status = 4;
      }
    }
    setServer(updatedServer);
    postBilling(updatedServer)
  };

  const changeDisks = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, i: number, dataIndex: number) => {
    const updatedServer: Server[] = [...server]
    if (e.target.name === 'storage') {
      updatedServer[i].data_disks[dataIndex].storage = e.target.value
      updatedServer[i].billing_status = 4;
    } else {
      updatedServer[i].data_disks[dataIndex].storage_disk = Number(e.target.value)
      updatedServer[i].data_disks[dataIndex].status = 4
      updatedServer[i].billing_status = 4;
    }
    setServer(updatedServer)
    postBilling(updatedServer)
  }

  const addDisks = (i: number) => {
    const updatedServerState: Server[] = [...server]
    updatedServerState[i].data_disks.push({
      storage: '',
      storage_disk: null,
      id: null,
      vm_id: null,
      name: null,
      status: 1
    })
    updatedServerState[i].billing_status = 4
    setServer(updatedServerState)
  }

  const disksDelete = (i: number, diskIndex: number) => {
    const value = [...server]
    if (
      JSON.parse(error_code ? error_code : '').error_code === 2 ||
      value[i].data_disks[diskIndex].vm_id === null
    ) {
      value[i].data_disks.splice(diskIndex, 1)
      value[i].billing_status = 4
    } else {
      value[i].data_disks[diskIndex].status = 3
      value[i].billing_status = 4
    }
    setServer(value)
    localStorage.setItem('server', JSON.stringify(value))
    postBilling(value)
  }
  
  const reducedDisks = vpsBilling?.configurations_prices?.reduce((accumulator: any, item: any) => {
    Object.entries(item).forEach(([key, value]) => {
      if (typeof value === 'string' && key === 'additional_disks') {
        // Extract numeric values from the string and sum them up
        const numericValues: any = value.match(/\d+/g) || [];
        const sum = numericValues.reduce((total: any, num: any) => total + parseInt(num, 10), 0);
        accumulator[key] = (accumulator[key] || 0) + sum;
      } else if (typeof value === 'object' && value !== null) {
        // @ts-ignore
        accumulator[key] = (accumulator[key] || 0) + (value.hdd_size || 0)
        // @ts-ignore
        accumulator[key] = (accumulator[key] || 0) + (value.ssd_size || 0)
      }
    });
    return accumulator;
  }, {});
  
  const handleSecondValidate = () => {
    const uniqueVmNamesPerServer: any = new Set();
    for (const currentServer of server) {
      const uniqueVmNames: any = new Set();
      if (
        currentServer.billing_status !== 3 &&
        (!currentServer?.cpu ||
          !currentServer?.ram ||
          (currentServer?.is_imut && !currentServer?.imut) ||
          !currentServer?.system_storage ||
          !currentServer?.system_storage_disk ||
          currentServer?.vm_systems?.some((s) => !s.vm_name) ||
          currentServer?.vm_systems?.some((value) => value.ipv_address && (!currentServer?.internet || !currentServer?.tasix)) ||
          (currentServer?.data_disks?.length !== 0 && currentServer?.data_disks?.some((s) => !s.storage || !s.storage_disk)) ||
          currentServer?.data_disks?.some((s) => Number(s.storage_disk) > 4096) ||
          !currentServer?.image_id ||
          currentServer?.system_storage_disk > 4096 ||
          currentServer?.vm_systems?.some((s) => {
            const vmName = s.vm_name.trim().toLowerCase();
            if (uniqueVmNames.has(vmName) || uniqueVmNamesPerServer.has(vmName)) {
              return true;
            }
            uniqueVmNames.add(vmName);
            uniqueVmNamesPerServer.add(...uniqueVmNames)
            return false;
          }) ||
          !vpsBilling?.success ||
          Number(currentServer?.cpu) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_cpu) ||
          Number(currentServer?.ram) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_ram) ||
          Number(currentServer?.system_storage_disk) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_disk))
      ) {
        return true;
      }

    }

    return false;
  }
  
  const validation = () => {
    return ((handleSecondValidate() || handleValidateYurUser()) && (handleSecondValidate() || handleValidateFizUser()));
  }

  const handleSecondValidateForCalculation = () => {
    const uniqueVmNamesPerServer: any = new Set();
    for (const currentServer of server) {
      const uniqueVmNames: any = new Set();
      if (
        currentServer.billing_status !== 3 &&
        (!currentServer?.cpu ||
          !currentServer?.ram ||
          (currentServer?.is_imut && !currentServer?.imut) ||
          !currentServer?.system_storage ||
          !currentServer?.system_storage_disk ||
          currentServer?.vm_systems?.some((s) => !s.vm_name) ||
          currentServer?.vm_systems?.some((value) => value.ipv_address && (!currentServer?.internet || !currentServer?.tasix)) ||
          (currentServer?.data_disks?.length !== 0 && currentServer?.data_disks.some((s) => !s.storage || !s.storage_disk)) ||
          // ((currentServer?.internet || currentServer?.tasix) && currentServer?.vm_systems.some((value) => !value.ipv_address)) ||
          !currentServer?.image_id ||
          currentServer?.vm_systems?.some((s) => {
            const vmName = s.vm_name.trim().toLowerCase(); // Trim and convert to lowercase
            if (uniqueVmNames.has(vmName) || uniqueVmNamesPerServer.has(vmName)) {
              return true; // Duplicate vm_name found within this server or across servers
            }
            uniqueVmNames.add(vmName);
            uniqueVmNamesPerServer.add(...uniqueVmNames)
            return false;
          }) ||
          // vpsBilling.length === 0 ||
          Number(currentServer?.cpu) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_cpu) ||
          Number(currentServer?.ram) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_ram) ||
          Number(currentServer?.system_storage_disk) < Number(operationSystemsDetail?.find(os => os.image_id === currentServer.image_id)?.min_disk))
      ) {
        return true;
      }

    }

    return false;
  }

  const handleValidateStorages = () => {
    for (const currentServer of server) {
      if (
        currentServer?.data_disks.some((s) => s.storage && (!s.storage || !s.storage_disk)) ||
        currentServer?.data_disks.some((s) => s.storage && (!s.storage || !s.storage_disk))
      ) {
        return true;
      }
    }

    return false;
  }

  const date = new Date()
  const formattedDateTime = date.toISOString()

  const dataFiz: any = {
    service: JSON.parse(service_id ? service_id : ''),
    save: 0,
    contract_date: formattedDateTime,
    configuration: server,
    is_back_office: false,
  }

  const dataYur: any = {
    service: JSON.parse(service_id ? service_id : ''),
    save: 0,
    contract_date: formattedDateTime,
    configuration: server,
    is_back_office: false,
  }

  const createContract = () => {
    dispatch(createVpsContract(access, userDetail?.u_type === 'Fizik' ? dataFiz : dataYur)).then(() => {
      localStorage.setItem('server', JSON.stringify(server))
      dispatch(saveVpsContractData(userDetail?.u_type === 'Fizik' ? dataFiz : dataYur))
      navigate('/vpsAgreement')
    })
  }

  const heightCalculation = () => {
    window.addEventListener('scroll', () => {
      setHeight(window.scrollY)
    })
  }

  const recoveryConfig = (i: number) => {
    const updatedServer = [...server]
    updatedServer[i].billing_status = 2
    setServer(updatedServer)
    postBilling(updatedServer)
  }

  const sendStir = () => {
    dispatch(refreshUserDetail(access)).then(() => window.location.reload())
  }
  const sendVatNumber = () => {
    dispatch(refreshUserDetail(access)).then(() => window.location.reload())
  }

  const reducedObject = vpsBilling?.configurations_prices?.reduce((accumulator: any, item: any) => {
    Object.entries(item).forEach(([key, value]) => {
      if (typeof value === 'number') {
        accumulator[key] = (accumulator[key] || 0) + value;
      } else if (typeof value === 'string' && key === 'additional_disks') {
        // Extract numeric values from the string and sum them up
        const numericValues: any = value.match(/\d+/g) || [];
        const sum = numericValues.reduce((total: any, num: any) => total + parseInt(num, 10), 0);
        accumulator[key] = (accumulator[key] || 0) + sum;
      } else if (typeof value === 'object' && value !== null) {
        // @ts-ignore
        accumulator[key] = (accumulator[key] || 0) + (value.hdd_price || 0)
        // @ts-ignore
        accumulator[key] = (accumulator[key] || 0) + (value.ssd_price || 0)
      }
    });
    return accumulator;
  }, {});

  return (
    <div className='container'>
      <div className={'request'}>
        <Steps activeStep={2}/>
        <div className={'request_content'}>
          <div>
            <h2 className="contractInfo_title">{t("props")}</h2>
            <div className="contractInfo_drop">
              <div
                className="contractInfo_drop-head"
                onClick={() => setOpenRek(!openRek)}
              >
                <div className="contractInfo_drop-head-title">{t("props")}</div>
                <div
                  className="contractInfo_drop-head-icon"
                  style={{transform: openRek ? "rotate(-180deg)" : ""}}
                >
                  <DropIcon/>
                </div>
              </div>
              {openRek && (
                <>
                  <UserDetailHandler
                    setStir={(e: any) => setStir(e.target.value)}
                    sendStir={sendStir}
                    vatNumber={vatNumber}
                    setVatNumber={(e: any) => setVatNumber(e.target.value)}
                    sendVatNumber={sendVatNumber}
                    email={email}
                    setEmail={(e: any) => setEmail(e.target.value)}
                    sendMfo={sendMfo}
                    detail={userDetail}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={(e: any) => setPhoneNumber(e.target.value)}
                    address={address}
                    setAddress={(e: any) => setAddress(e.target.value)}
                    mfo={mfo}
                    setMfo={(e: any) => setMfo(e.target.value)}
                    xxtut={xxtut}
                    setXxtut={(e: any) => setXxtut(e.target.value)}
                    ktut={ktut}
                    setKtut={(e: any) => setKtut(e.target.value)}
                    ifut={ifut}
                    setIfut={(e: any) => setIfut(e.target.value)}
                    checkingAccount={checkingAccount}
                    setCheckingAccount={(e: any) => setCheckingAccount(e.target.value)}
                    bank={bank}
                    setBank={(e: any) => valid(e, setBank)}
                    directorName={directorName}
                    setDirectorName={(e: any) => valid(e, setDirectorName)}
                    directorSurname={directorSurname}
                    setDirectorSurname={(e: any) => valid(e, setDirectorSurname)}
                    directorMidname={directorMidname}
                    setDirectorMidname={(e: any) => valid(e, setDirectorMidname)}
                  />
                  <div className="profile_wrap-form-block-btn">
                    <button
                      className={'px-3'}
                      onClick={() => navigate('/profile')}
                      disabled={false}
                      style={{opacity: handleValidateYurUser() === undefined ? 1 : 1, whiteSpace: 'nowrap'}}
                    >
                      {t("edit")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="single-summary__cards-wrap"
               style={{height: window.scrollY > 200 ? '90%' : '50%', top: window.scrollY > 200 ? '5%' : '40%'}}>
            <div className={"single-summary__cards"}>
              <h3 className="single-summary__title">{t("cloud_servers")}</h3>
              {(window.scrollY > 200 ? server.length > 2 : server.length >= 2) && (
                <div className='chevron'>
                  <i>&#65086;</i>
                </div>
              )}

              <div
                style={{marginBottom: 10, color: 'red'}}
              >
                {vpsBilling?.success === false && vpsBilling?.err_msg}
              </div>

              <div
                style={{marginBottom: 10}}
              >
                {vpsBilling?.device_price_type === 'Standard' &&
                  <h4
                    style={{color: 'red'}}
                    className={'server_conf__title'}>
                    Jami 10dan ko'p yadro uchun, 15% chegirma bilan:
                  </h4>
                }
                {vpsBilling?.device_price_type === 'Pro' &&
                  <h4
                    style={{color: 'red'}}
                    className={'server_conf__title'}>
                    Jami 100dan ko'p yadro uchun, 25% chegirma bilan:
                  </h4>
                }
              </div>

              {
                server.map((data, index) => (
                  data.billing_status !== 3 && (
                    <div className="server_conf" key={index}>

                      {Number(data?.cpu) !== 1 && Number(data?.cpu) % 2 !== 0 &&
                        <h4
                          style={{color: 'red', marginBottom: 20}}
                          className={'server_conf__title'}>
                          Xar bir virtual serverda yadrolar soni juft bo‘lishi lozim
                        </h4>
                      }
                    </div>
                  )
                ))
              }
              <>
                <div className="conf_title_wrap">
                  <h4 className="server_conf__title">{t("configuration")}</h4>
                </div>
                <div className="conf_info_wrap">
                  <p>CPU</p>
                  <p>{vpsBilling?.success && vpsBilling?.configurations_prices?.length !== 0 && reducedObject?.cpu} {t("uzs")}</p>
                </div>
                <div className="conf_info_wrap">
                  <p>RAM</p>
                  <p>{vpsBilling?.success && vpsBilling?.configurations_prices?.length !== 0 && reducedObject?.ram} {t("uzs")}</p>
                </div>

                <div className="conf_info_wrap">
                  <p>{'DISK'}</p>
                  <p>{vpsBilling?.success && vpsBilling?.configurations_prices?.length !== 0 && reducedObject?.additional_disks} {t("uzs")}</p>
                </div>
                <div className="conf_info_wrap">
                  <p>IP</p>
                  <p>{vpsBilling?.success && vpsBilling?.configurations_prices?.length !== 0 && reducedObject?.ipv_address_price} {t("uzs")}</p>
                </div>
                <div className="conf_info_wrap">
                  <p>INTERNET</p>
                  <p>{vpsBilling?.success && vpsBilling?.configurations_prices?.length !== 0 && reducedObject?.internet} {t("uzs")}</p>
                </div>
                <div className="conf_info_wrap">
                  <p>TASIX</p>
                  <p>{vpsBilling?.success && vpsBilling?.configurations_prices?.length !== 0 && reducedObject?.tasix} {t("uzs")}</p>
                </div>
              </>
              <div className="conf_info_wrap">
                <p>{t("total")}:</p>
                <p>{vpsBilling?.success && vpsBilling?.configurations_total_price} {t("uzs")}/{t("month")}</p>
              </div>
            </div>
          </div>

          <div style={{marginTop: 20}}>
            <h2 className='contractInfo_title'>{t("contract_info")}</h2>
            <div className='contractInfo_drop'>
              <div
                className="contractInfo_drop-head"
                onClick={() => setOpenContract(!openContract)}
              >
                <div className="contractInfo_drop-head-title">{t("contract_info")}</div>
                <div
                  className="contractInfo_drop-head-icon"
                  style={{transform: openContract ? "rotate(-180deg)" : ""}}
                >
                  <DropIcon/>
                </div>
              </div>
              {openContract && (
                <>
                  {server.map((data, index) => (
                    <div key={index}>
                      <div className='head'>
                        <h3>{t("configuration")} {index + 1}</h3>
                        <div className='head_counter'>
                          {data.billing_status === 3 && (
                            <button
                              className='delete_btn'
                              onClick={() => recoveryConfig(index)}
                            >
                              <ReverseIcon/>
                            </button>
                          )}
                          {data.billing_status !== 3 && (
                            <button
                              className='delete_btn'
                              onClick={() => handleServerDelete(index)}
                              disabled={server.length === 1}
                            >
                              <DeleteIcon/>
                            </button>
                          )}
                        </div>
                      </div>
                      {data.billing_status !== 3 && (
                        <div className="body">
                          {data.account_id === null && (
                            <select
                              className='select'
                              name='tariff' value={data.tariff || ''}
                              onChange={(e) => changeServer(e, index)}
                            >
                              <option value={''}>{t("select")}</option>
                              {vpsTariffs && vpsTariffs.map((item) => (
                                <option key={item.id} value={item.id}>{item.tariff_name}</option>
                              ))
                              }
                            </select>
                          )}
                          <div className='inputs'>
                            {data && server[index].vm_systems.map((element, i) => (
                              <div key={i} className='serverNum'>
                                <div style={{display: 'flex', flexDirection: 'column', width: "45%"}}>
                                  <label htmlFor="vm_name">VM: {t("enter_name")} {i + 1}</label>
                                  <input
                                    maxLength={10}
                                    type="text"
                                    id={'vm_name'}
                                    name={'vm_name'}
                                    value={element.vm_name}
                                    onChange={(e) => changeServerNum(e, i, index)}
                                    disabled={element?.id !== null}
                                  />
                                  <div>
                                    <input
                                      style={{width: '5%', marginRight: '10px'}}
                                      type="checkbox"
                                      onChange={(e) => changeServerNum(e, i, index)}
                                      checked={element.ipv_address}
                                      // disabled={(data?.tariff !== null || data?.tariff === '' || server.length === 1)}
                                      disabled={index === 0 || (data?.tariff !== null || data?.tariff === '' || server.length === 1)}
                                      name="ipv_address"
                                    />
                                    <label style={{width: '50%'}}>Static ip address</label>
                                  </div>
                                </div>
                                <div className='parent'>
                                  <div style={{
                                    display: 'flex',
                                    width: "100%",
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                    <div style={{
                                      display: i > 0 ? 'none' : 'flex',
                                      flexDirection: 'column',
                                      width: "48%"
                                    }}
                                    >
                                      <label htmlFor="version">VM: {t("operating_system")}</label>
                                      <select
                                        id="version"
                                        name='os_type'
                                        disabled={data?.account_id ? true : false}
                                        value={data?.os_type_id}
                                        onChange={(e) => changeServer(e, index)}
                                      >
                                        <option value="" disabled={data?.os_type_id !== ''}>{t("select")}...</option>
                                        {operationSystems && operationSystems.map((el, idx) => (
                                          <option value={el.os_type_id} key={idx}>{el.name}</option>
                                        ))}
                                      </select>
                                    </div>
                                    <div style={{
                                      display: i > 0 ? 'none' : 'flex',
                                      flexDirection: 'column',
                                      width: "48%"
                                    }}
                                    >
                                      <label htmlFor="version">{t("operating_system_version")}</label>
                                      <select
                                        id="version"
                                        name='image_id'
                                        disabled={data?.os_type_id === '' || operationSystemsDetail?.length === 0 || data?.account_id ? true : false}
                                        value={data?.image_id || ''}
                                        onChange={(e) => changeServer(e, index)}
                                      >
                                        <option value="" disabled={data?.image_id !== ''}>{t("select")}...</option>
                                        {operationSystemsDetail && operationSystemsDetail.map((el, idx) => (
                                          el.os_type_id === data?.os_type_id &&
                                          <option value={el.image_id} key={idx}>{el.name}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div style={{display: 'flex', flexDirection: 'column', width: '45%', marginTop: 10}}>
                              <label htmlFor="cpu">CPU (CORE)</label>
                              <input
                                disabled={data.tariff !== null}
                                name='cpu' id='cpu' type={"text"} placeholder={'CPU'} value={data.cpu || ""}
                                onChange={(e) => changeServer(e, index)}
                              />
                              {operationSystemsDetail && Number(data.cpu) <= Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_cpu) && Number(data.cpu) < Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_cpu) && (
                                <label style={{color: 'red'}} htmlFor="cpu">
                                  *
                                  Minimum {Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_cpu)} (CORE)
                                </label>
                              )}
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '45%', marginTop: 10}}>
                              <label htmlFor="ram">RAM (GB)</label>
                              <input
                                id='ram' name='ram' type={"text"} placeholder={'RAM'} value={data.ram || ""}
                                onChange={(e) => changeServer(e, index)}
                                disabled={data.tariff !== null}
                              />
                              {operationSystemsDetail && Number(data.ram) <= Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_ram) && Number(data.ram) < (Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_ram)) && (
                                <label style={{color: 'red'}} htmlFor="ram">
                                  *
                                  Minimum {(Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_ram))} (GB)
                                </label>
                              )}
                            </div>
                            <div className='dataSaved' id={'dataSaved'}>
                              <label htmlFor="dataSaved">{t("operating_system")} (GB)</label>
                              <div>
                                <select
                                  disabled={data.tariff !== null}
                                  name='system_storage'
                                  value={data.system_storage || ""}
                                  onChange={(e) => changeServer(e, index)}
                                >
                                  <option value="">{t("select")}...</option>
                                  <option value="hdd">HDD SAS</option>
                                  <option value="ssd">SSD</option>
                                  {/*<option value="sat">HDD SATA</option>*/}
                                  {/*<option value="nvm">NVME</option>*/}
                                  {/*<option value="iom">IOM</option>*/}
                                </select>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 10,
                                    width: '100%'
                                  }}
                                >
                                  {data.system_storage && (
                                    <input
                                      disabled={data.tariff !== null}
                                      placeholder={data.system_storage.toUpperCase()}
                                      name='system_storage_disk'
                                      value={data.system_storage_disk || ""}
                                      onChange={(e) => changeServer(e, index)}
                                      style={{
                                        width: '100%',
                                        marginTop: 5
                                      }}
                                      id='type'
                                      type={"text"}
                                    />
                                  )}
                                </div>
                              </div>
                              {operationSystemsDetail && Number(data.system_storage_disk) <= Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_disk) && (Number(data.system_storage_disk)) < (Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_disk)) && (
                                <label style={{color: 'red'}} htmlFor="cpu">
                                  *
                                  Minimum {(Number(operationSystemsDetail.find(os => os.image_id === data.image_id)?.min_disk))} (GB)
                                </label>
                              )}
                              {Number(data?.system_storage_disk) > 4096 && (
                                <label style={{color: 'red'}} htmlFor="cpu">
                                  * Maksimal qiymat 4096 GB. Ko'proq qo'shish uchun yangi disk yarating
                                </label>
                              )}
                            </div>
                            {data?.vm_systems[0]?.ipv_address && (
                              <>
                                <div style={{display: 'flex', flexDirection: 'column', width: '45%', marginTop: 10}}>
                                  <label htmlFor="internet">Internet(МИР) (mbit/s)</label>
                                  <input
                                    disabled={data.tariff !== null}
                                    name='internet' value={data.internet || ""}
                                    onChange={(e) => changeServer(e, index)} type={"text"} id='internet'
                                  />
                                  {Number(data.internet) > 10 &&
                                    <label htmlFor="tasIx" style={{color: 'green'}}>*10 (mbit/s) gacha bepul</label>}

                                  <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: 10}}>
                                    <label htmlFor="tasIx">TAS-IX/UZ-IX (mbit/s)</label>
                                    <input
                                      disabled={data.tariff !== null}
                                      name='tasix' value={data.tasix || ""} onChange={(e) => changeServer(e, index)}
                                      style={{width: '100%', marginTop: 5}} id='tasIx' type={"text"}
                                    />
                                    {Number(data.tasix) > 100 &&
                                      <label htmlFor="tasIx" style={{color: 'green'}}>*100 (mbit/s) gacha bepul</label>}
                                  </div>

                                  {/*<div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: 10}}>*/}
                                  {/*  <div>*/}
                                  {/*    <input*/}
                                  {/*      disabled={data.tariff !== null}*/}
                                  {/*      style={{width: '3%', marginRight: '10px'}}*/}
                                  {/*      type="checkbox"*/}
                                  {/*      onChange={(e) => changeServer(e, index)}*/}
                                  {/*      checked={data.is_imut}*/}
                                  {/*      name="is_imut"*/}
                                  {/*      id="imut"*/}
                                  {/*    />*/}
                                  {/*    <label style={{width: '50%'}} htmlFor="imut">IMUT (Mbit/s)</label>*/}
                                  {/*  </div>*/}
                                  {/*  {data.is_imut && (*/}
                                  {/*    <div>*/}
                                  {/*      <input*/}
                                  {/*        disabled={data.tariff !== null}*/}
                                  {/*        style={{width: '100%', marginRight: '10px'}}*/}
                                  {/*        onChange={(e) => changeServer(e, index)} value={data.imut || ''} type="text"*/}
                                  {/*        name="imut" id="imutInput"*/}
                                  {/*      />*/}
                                  {/*    </div>*/}
                                  {/*  )}*/}
                                  {/*</div>*/}
                                </div>
                              </>
                            )}

                            <div className='dataSaved' id={'dataSaved'}>
                            <span>
                              <label htmlFor="dataSaved">{t("data_store")} (GB)</label>
                                <button
                                  style={{
                                    padding: '5px 10px',
                                    border: '1px solid black',
                                    borderRadius: '50%',
                                    background: 'transparent',
                                    marginLeft: 5,
                                  }}
                                  disabled={data.tariff !== null ? true : handleSecondValidateForCalculation()}
                                  onClick={() => addDisks(index)}
                                >
                                  +
                                </button>
                            </span>
                              {server[index]?.data_disks?.map((disk, diskIndex: number) => (
                                disk.status !== 3 && (
                                  <>
                                    <div style={{display: 'flex', alignItems: 'center'}} key={diskIndex}>
                                      <select
                                        disabled={data.tariff !== null}
                                        style={{width: '85%'}} name="storage" value={disk.storage || ''}
                                        onChange={(e) => changeDisks(e, index, diskIndex)}
                                      >
                                        <option value=""
                                                disabled={JSON.parse(error_code ? error_code : '').error_code === 1}>Tanlang...
                                        </option>
                                        <option value="ssd">SSD</option>
                                        <option value="hdd">HDD</option>
                                        {/*<option value="sat">HDD SATA</option>*/}
                                        {/*<option value="nvm">NVME</option>*/}
                                        {/*<option value="iom">IOM</option>*/}
                                      </select>
                                      <div>
                                        <button
                                          style={{
                                            padding: '5px 12px',
                                            border: '1px solid black',
                                            borderRadius: '50%'
                                          }}
                                          onClick={() => disksDelete(index, diskIndex)}
                                          disabled={data.tariff !== null ? true : handleValidateStorages()}
                                        >
                                          -
                                        </button>
                                        {/*<button*/}
                                        {/*  disabled={data.tariff !== null ? true : handleValidateStorages()}*/}
                                        {/*  style={{*/}
                                        {/*    padding: '5px 10px',*/}
                                        {/*    border: '1px solid black',*/}
                                        {/*    borderRadius: '50%',*/}
                                        {/*    marginLeft: 5,*/}
                                        {/*    // opacity: diskIndex > 0 ? 0 : 1,*/}
                                        {/*    // pointerEvents: diskIndex > 0 ? "none" : "all"*/}
                                        {/*  }}*/}
                                        {/*  onClick={() => addDisks(index)}*/}
                                        {/*>*/}
                                        {/*  +*/}
                                        {/*</button>*/}
                                      </div>
                                    </div>
                                    {disk.storage && (
                                      <input
                                        disabled={data.tariff !== null}
                                        placeholder={disk.storage.toUpperCase() || ''}
                                        name='storage_disk'
                                        value={disk.storage_disk || ""}
                                        onChange={(e) => changeDisks(e, index, diskIndex)}
                                        style={{
                                          width: '85%',
                                          marginTop: 5,
                                        }}
                                        id='type'
                                        type={"text"}
                                      />
                                    )}
                                    {Number(disk?.storage_disk) > 4096 && (
                                      <label style={{color: 'red'}} htmlFor="cpu">
                                        * Maksimal qiymat 4096 GB. Ko'proq qo'shish uchun yangi disk yarating
                                      </label>
                                    )}
                                  </>
                                )
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className='request_content-buttons' style={{justifyContent: 'center'}}>
                    <button
                      disabled={handleSecondValidateForCalculation()}
                      style={{
                        opacity: handleSecondValidateForCalculation() ? 0.5 : 1
                      }}
                      onClick={() => handleServerAdd()}
                    >
                      {t("add")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="request_content-buttons">
            <button onClick={() => {
              if (JSON.parse(error_code ? error_code : '')?.error_code === 1) {
                navigate('/')
                localStorage.removeItem('server')
                dispatch(clearVpsCalculation())
              } else {
                navigate('/vps')
                localStorage.setItem('server', JSON.stringify(server))
              }
            }}
            >
              <span>{JSON.parse(error_code ? error_code : '[]')?.error_code === 1 ? t("cancel") : t("back")}</span>
            </button>
            <button
              disabled={validation()}
              style={{
                opacity: validation() ? 0.5 : 1
              }}
              onClick={createContract}
            >
              <span>{t("next")}</span>
              <Arrow2 color="white"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VpsContractInfo