import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import CallIcon from "../../images/callIcon";
import JobIcon from "../../images/jobIcon";
import MailIcon from "../../images/mail";
import Marker from "../../images/marker";
import NameIcon from "../../images/nameIcon";
import { useTranslation } from "react-i18next";
import { iGroupAdmin } from "../../store/models";

type Props = {
  close: () => void;
  admin: iGroupAdmin | null;
  pinnedUser: iGroupAdmin | null;
};

type InfoProps = {
  label: string;
  info: string | undefined | null;
  icon: any;
};

const InfoItem = ({ label, info, icon }: InfoProps) => {
  return (
    <div className="modalWrap_block-info_column_item">
      <div className="modalWrap_block-info_column_item-label">
        {icon}
        <span>{label}</span>
      </div>
      <div className="modalWrap_block-info_column_item-title">{info}</div>
    </div>
  );
};

const CallModal: React.FC<Props> = ({ close, admin, pinnedUser }) => {
  const { t } = useTranslation();
  const [type, setType] = useState<"admin" | "pinned-user">("pinned-user");

  return (
    <div className="modalWrap">
      <OutsideClickHandler onOutsideClick={close}>
        <div className="modalWrap_block">
          <div className="modalWrap_block-title">{t("contactInformation")}</div>
          <div className="modalWrap_block-types">
            <button
              className={type === "pinned-user" ? "active" : "inactive"}
              onClick={() => setType("pinned-user")}
            >
              {t("specialist")}
            </button>
            {/* <button
              className={type === "admin" ? "active" : "inactive"}
              onClick={() => setType("admin")}
            >
              {t("departmentHead")}
            </button> */}
          </div>
          {type === "pinned-user" && (
            <div className="modalWrap_block-info">
              <div className="modalWrap_block-info_column">
                <InfoItem
                  label={t("nsm")}
                  info={pinnedUser?.full_name}
                  icon={<NameIcon />}
                />
                <InfoItem
                  label={t("position")}
                  info={pinnedUser?.email == "rozaguli@exat.uz" ? t("specialist") : t("departmentHead")}
                  icon={<JobIcon />}
                />
                <InfoItem
                  label={t("mail")}
                  info={pinnedUser?.email}
                  icon={<MailIcon />}
                />
                {/* <InfoItem
                  label={t("address")}
                  info={pinnedUser?.per_adr}
                  icon={<Marker />}
                /> */}
                <InfoItem
                  label={t("phoneNumber")}
                  info={pinnedUser?.mob_phone_no}
                  icon={<CallIcon color="#9A9A9A" />}
                />
              </div>
              {/* <div className="modalWrap_block-info_column">
                <InfoItem
                  label={t("phoneNumber")}
                  info={pinnedUser?.mob_phone_no}
                  icon={<CallIcon color="#9A9A9A" />}
                />
              </div> */}
            </div>
          )}
          {type === "admin" && (
            <div className="modalWrap_block-info">
              <div className="modalWrap_block-info_column">
                <InfoItem
                  label={t("nsm")}
                  info={admin?.full_name}
                  icon={<NameIcon />}
                />
                <InfoItem
                  label={t("position")}
                  info={t("departmentHead")}
                  icon={<JobIcon />}
                />
                <InfoItem
                  label={t("mail")}
                  info={admin?.email}
                  icon={<MailIcon />}
                />
                {/* <InfoItem
                  label={t("address")}
                  info={admin?.per_adr}
                  icon={<Marker />}
                /> */}
                <InfoItem
                  label={t("phoneNumber")}
                  info={admin?.mob_phone_no}
                  icon={<CallIcon color="#9A9A9A" />}
                />
              </div>
              {/* <div className="modalWrap_block-info_column">
                <InfoItem
                  label={t("phoneNumber")}
                  info={admin?.mob_phone_no}
                  icon={<CallIcon color="#9A9A9A" />}
                />
              </div> */}
            </div>
          )}
          <div className="modalWrap_block-btnBlock">
            <button onClick={close}>{t("close")}</button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default CallModal;
