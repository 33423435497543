import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
  cleanUserDetails,
  createSavedService,
  deleteSavedService, getFizUserDetail,
  getServices,
} from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import ServiceBlock from "../../components/ServiceBlock/ServiceBlock";
import SavedModal from "../../components/Modal/SavedModal";
import {clearAuthData, getMessage, logoutHandler} from "../../store/actions/authActions";
import RekModal from "../../components/Modal/RekModal";
import {ISigner} from "../../components/iSigner";
import {parseKeyList} from "../../components/iSigner/isigner";

const MainPage: React.FC = () => {
  const iSigner = ISigner.load();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {access, response, token} = useAppSelector((state) => state.auth);
  const {services, loading} = useAppSelector(
    (state) => state.contracts
  );

  const {userDetail} = useAppSelector(state => state.user)

  console.log(userDetail === undefined)
  
  const [modal, setModal] = useState<boolean>(false)


  // const [keys, setKeys] = useState<any>([]);
  // const [key, setKey] = useState<any>(null);
  //
  // useEffect(() => {
  //   iSigner.keyList((response: any) => {
  //     setKeys(parseKeyList(response));
  //   });
  // }, [iSigner]);

  const handleSaveService = (id: number) => {
    dispatch(createSavedService({service_id: id}, access));
    setModal(true)
  };
  const handleDeleteSaveService = (id: number) => {
    dispatch(deleteSavedService(id, access));
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(getServices(access));
    if (access) {
      dispatch(getFizUserDetail(access));
      dispatch(getMessage(access, false))
    }
  }, [access, dispatch]);

  useEffect(() => {
    if (userDetail === undefined) {
      const refresh: any = localStorage.getItem('refresh_token')
      const body = {
        response,
        token,
        access,
      };
      navigate("/");
      dispatch(logoutHandler(body, access, JSON.parse(refresh ? refresh : 'null')));
      dispatch(clearAuthData(body));
      dispatch(cleanUserDetails(userDetail));
      localStorage.clear()
    }
  }, [userDetail]);

  if (loading) return <LoadingPage/>;

  return (
    <>
      <RekModal/>
      <div className="main">
        {window.innerWidth > 768 && <Sidebar active={location.pathname} type="main"/>}
        {/*<div className="border"></div>*/}
        <div className="main_wrap">
          <div className="main_wrap-services">
            {/*<select*/}
            {/*  name="key"*/}
            {/*  id="key"*/}
            {/*  value={key || ''}*/}
            {/*  onChange={(e) => setKey(e.target.value)}*/}
            {/*>*/}
            {/*  <option value="">Tanlang</option>*/}
            {/*  {keys && keys?.map((item: any,  index: number) => (*/}
            {/*    <option key={index} value={item?.serial}>{item?.organization === '' ? item?.commonName : item?.organization}</option>*/}
            {/*  ))}*/}
            {/*</select>*/}
            {services && services.map((el, index: number) => (
              <ServiceBlock
                key={Math.floor(index)}
                name={el.name}
                descr={el.description}
                icon={el.image}
                favorite={el.is_saved}
                token={access}
                visible={el.group.is_visible_in_front}
                setSaved={
                  el.is_saved
                    ? () => handleDeleteSaveService(el.id)
                    : () => handleSaveService(el.id)
                }
                navigate={() => navigate(`/service/${el.id}`, {
                  state: {
                    slug: el
                  }
                })}
              />
            ))}
            {modal && <SavedModal close={() => setModal(false)}/>}
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default MainPage;
