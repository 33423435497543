export const ISignerMsgTypeCodes = {
    Request: 0,
    Response: 1,
    Notify: 2,
};

export const ISignerFuncCodes = {
    METHODS_LIST: 0,
    VERSION: 1,
    KEYLIST: 2,
    REMOVABLELIST: 3,
    HASH: 4,
    SIGN: 5,
    KEYPAIRGEN: 6,
    SAVEKEY: 7,
    CHANGEPIN: 8,
    REENCRYPT: 9,
    QR: 10,
    TOKEN_AUTH: 11,
    RESET_PIN: 12,
};

export const ISignerErrorCodes = {
    UNKNOWN: 0,
    SUCCESS: 1,
    UNKNOWN_TYPE: 2,
    UNKNOWN_FUNC: 3,
    INVALID_REQUEST: 4,
    ARGS_REQIURED: 5,
    ARGS_COUNT_MISMATCH: 6,
    ARG_REQIURED: 7,
    ARG_COULD_NOT_BE_EMPTY: 8,
    INVALID_PATH: 9,
    KEYS_ARE_NOT_PAIR: 10,
    INVALID_SUBJECT_DN: 11,
    INVALID_SERVER_TOKEN: 12,
    KEY_NOT_FOUND: 13,
    KEY_PASSWORD_INCORRECT: 14,
    UNKNOWN_KEY_FORMAT: 15,
    COULD_NOT_GENERATE_SIGN: 16,
    INVALID_CERTIFICATE: 17,
    ACTION_CANCELED: 18,
    INVALID_PRIVATE_KEY: 19,
    COULD_NOT_WRITE: 20,
    USB_TOKEN_NOT_INSTALLED: 21,
    USB_TOKEN_NOT_CONNECTED: 22,
    USB_TOKEN_ERROR: 23,
    USB_TOKEN_PASSWORD_INCORRECT: 24,
    IKEY_PASSWORD_INCORRECT: 25,
};
