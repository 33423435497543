import * as contractTypes from "../actionTypes/contractsActionTypes";
import {
  iContractDetail,
  iContrctSavedResponse,
  iUserDetail,
  iOfferDetail,
  iServiceList,
  iTarifsElementsDevices,
  iUserContracts,
  iConnectMethods,
  iGroupAdmin,
  iListDataCenter,
  iServiceDetailList,
  iServiceDetailVideoList
} from "../models";
import {ReducerActions} from "../utilsTypes";
import app from "../../App";

interface StateTypes {
  loading: boolean;
  error: any;
  services: iServiceList[];
  groupServices: iServiceList[];
  fizUserDetail: iUserDetail | null;
  savedServices: iServiceList[];
  serviceDetail: iServiceList | null;
  serviceDetailList: iServiceDetailList[] | any;
  serviceDetailVideoMarketingList: iServiceDetailVideoList[] | any;
  serviceDetailVideoTutorialList: iServiceDetailVideoList[] | any;
  serviceReklamalList: iServiceDetailVideoList[] | any;
  offerDetail: iOfferDetail | null;
  tarifsElementsDevices: iTarifsElementsDevices | null;
  contractDocument: any | null;
  contractSavedResponse: iContrctSavedResponse | null;
  pkcsResponse: { message: string } | null;
  userContracts: iUserContracts[];
  contractDetail: iContractDetail | null;
  connectMethods: iConnectMethods[];
  groupAdmin: iGroupAdmin | null;
  pinnedUser: iGroupAdmin | null;
  num: string;
  listDataCenter: iListDataCenter[] | null;
  calculateDataCenter: any
}

const initState: StateTypes = {
  loading: false,
  error: null,
  services: [],
  groupServices: [],
  fizUserDetail: null,
  savedServices: [],
  serviceDetail: null,
  serviceDetailList: null,
  serviceDetailVideoMarketingList: null,
  serviceDetailVideoTutorialList: null,
  serviceReklamalList: null,
  offerDetail: null,
  tarifsElementsDevices: null,
  contractDocument: null,
  contractSavedResponse: null,
  pkcsResponse: null,
  userContracts: [],
  contractDetail: null,
  connectMethods: [],
  groupAdmin: null,
  pinnedUser: null,
  num: '',
  listDataCenter: null,
  calculateDataCenter: null
};

export const contractsReducer = (
  state = initState,
  action: ReducerActions
): StateTypes => {
  const {type, payload} = action;
  switch (type) {
    case contractTypes.CONTRACTS_LOADING:
      return {...state, loading: true};
    case contractTypes.GET_SERVICES:
      return {...state, loading: false, services: payload};
    case contractTypes.GET_GROUP_SERVICES:
      return {...state, loading: false, groupServices: payload};
    case contractTypes.GET_SERVICE_DETAIL:
      return {...state, loading: false, serviceDetail: payload};
    case contractTypes.GET_SERVICE_DETAIL_LIST:
      return {...state, loading: false, serviceDetailList: payload};
    case contractTypes.GET_SERVICE_MARKETING_VIDEO:
      return {...state, loading: false, serviceDetailVideoMarketingList: payload};
    case contractTypes.GET_SERVICE_MARKETING_VIDEO_ERROR:
      return {...state, loading: false, error: payload};
    case contractTypes.GET_SERVICE_TUTORIAL_VIDEO:
      return {...state, loading: false, serviceDetailVideoTutorialList: payload};
    case contractTypes.GET_SERVICE_REKLAMA:
      return {...state, loading: false, serviceReklamalList: payload};
    case contractTypes.GET_SERVICE_TUTORIAL_VIDEO_ERROR:
      return {...state, loading: false, error: payload};
    case contractTypes.GET_SAVED_SERVICES:
      return {...state, loading: false, savedServices: payload};
    case contractTypes.GET_OFFER_DETAIL:
      return {...state, loading: false, offerDetail: payload};
    case contractTypes.GET_TARIFS_ELEMENTS_DEVICES:
      return {...state, loading: false, tarifsElementsDevices: payload};
    case contractTypes.CREATE_CONTRACT:
      return {...state, loading: false, contractDocument: payload};
    case contractTypes.CREATE_SAVED_CONTRACT:
      return {...state, loading: false, contractSavedResponse: payload};
    case contractTypes.SAVE_PKCS:
      return {...state, loading: false, pkcsResponse: payload};
    case contractTypes.GET_USER_CONTRACTS:
      return {...state, loading: false, userContracts: payload};
    case contractTypes.GET_CONTRACT_DETAIL:
      return {...state, loading: false, contractDetail: payload};
    case contractTypes.GET_CONNECT_METHODS:
      return {...state, loading: false, connectMethods: payload};
    case contractTypes.GET_GROUP_ADMIN:
      return {...state, loading: false, groupAdmin: payload};
    case contractTypes.GET_GROUP_PINNED_USER:
      return {...state, loading: false, pinnedUser: payload};
    case contractTypes.CONTRACTS_ERROR:
      return {...state, loading: false, error: payload};
    case contractTypes.CONTRACTS_LIST_ERROR:
      return {...state, loading: false, error: payload};
    case contractTypes.CONTRACTS_NUM:
      return {...state, loading: false, num: payload};
    case contractTypes.CONTRACTS_REJECT_START:
      return {...state, loading: true}
    case contractTypes.CONTRACTS_REJECT_SUCCESS:
      return {...state, loading: false}
    case contractTypes.CONTRACTS_REJECT_FAILED:
      return { ...state, loading: false, error: payload }
    case contractTypes.GET_DATA_CENTER_LIST_START:
      return {...state, loading: true}
    case contractTypes.GET_DATA_CENTER_LIST_SUCCESS:
      return {...state, loading: false, listDataCenter: payload}
    case contractTypes.GET_DATA_CENTER_LIST_ERROR:
      return {...state, loading: false, error: payload}
    case contractTypes.POST_CALCULATE_COLOCATION_START:
      return {...state, loading: true}
    case contractTypes.POST_CALCULATE_COLOCATION_SUCCESS:
      return {...state, loading: false, calculateDataCenter: payload}
    case contractTypes.POST_CALCULATE_COLOCATION_ERROR:
      return {...state, loading: false, error: payload}
    default:
      return state;
  }
};

interface UserDetailTypes {
  userDetail: iUserDetail | null;
  loading: boolean
}

const userDetailState: UserDetailTypes = {
  userDetail: null,
  loading: false
};

export const userDetailReducer = (
  state = userDetailState,
  action: ReducerActions
): UserDetailTypes => {
  const {type, payload} = action;
  switch (type) {
    case contractTypes.CONTRACTS_LOADING:
      return {...state, loading: true}
    case contractTypes.GET_USER_DETAIL:
      return {...state, userDetail: payload, loading: false};
    case contractTypes.CLEAN_USER_DETAILS:
      return {userDetail: null, loading: false};
    default:
      return state;
  }
};

interface savedContractDetailTypes {
  // savedContractDetails: iContractData | iContractDataYur | null;
  savedContractDetails: any;
  savedDevices: any;
}

const initialState: savedContractDetailTypes = {
  savedContractDetails: null,
  savedDevices: null,
};

export const contractDetailReducer = (
  state = initialState,
  action: ReducerActions
): savedContractDetailTypes => {
  const {type, payload} = action;
  switch (type) {
    case contractTypes.SAVE_CONTRACT:
      return {...state, savedContractDetails: payload};
    case contractTypes.SAVE_DEVICES:
      return {...state, savedDevices: payload};
    case contractTypes.CLEAR_SAVED_CONTRACTS:
      return {...state, savedContractDetails: null};
    case contractTypes.CLEAR_DEVICES:
      return {...state, savedDevices: null};
    default:
      return state;
  }
};
