import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58317 5.75008H13.4165C13.4165 5.24175 13.2146 4.75424 12.8551 4.39479C12.4957 4.03535 12.0082 3.83341 11.4998 3.83341C10.9915 3.83341 10.504 4.03535 10.1445 4.39479C9.7851 4.75424 9.58317 5.24175 9.58317 5.75008ZM7.6665 5.75008C7.6665 4.73342 8.07037 3.75839 8.78926 3.03951C9.50815 2.32062 10.4832 1.91675 11.4998 1.91675C12.5165 1.91675 13.4915 2.32062 14.2104 3.03951C14.9293 3.75839 15.3332 4.73342 15.3332 5.75008H20.1248C20.379 5.75008 20.6228 5.85105 20.8025 6.03077C20.9822 6.21049 21.0832 6.45425 21.0832 6.70841C21.0832 6.96258 20.9822 7.20634 20.8025 7.38606C20.6228 7.56578 20.379 7.66675 20.1248 7.66675H19.2796L18.4305 17.5759C18.3489 18.5328 17.9111 19.4241 17.2037 20.0737C16.4963 20.7232 15.5709 21.0835 14.6106 21.0834H8.38909C7.42875 21.0835 6.50335 20.7232 5.79598 20.0737C5.08861 19.4241 4.6508 18.5328 4.56917 17.5759L3.72009 7.66675H2.87484C2.62067 7.66675 2.37692 7.56578 2.19719 7.38606C2.01747 7.20634 1.9165 6.96258 1.9165 6.70841C1.9165 6.45425 2.01747 6.21049 2.19719 6.03077C2.37692 5.85105 2.62067 5.75008 2.87484 5.75008H7.6665ZM14.3748 11.5001C14.3748 11.2459 14.2739 11.0022 14.0941 10.8224C13.9144 10.6427 13.6707 10.5417 13.4165 10.5417C13.1623 10.5417 12.9186 10.6427 12.7389 10.8224C12.5591 11.0022 12.4582 11.2459 12.4582 11.5001V15.3334C12.4582 15.5876 12.5591 15.8313 12.7389 16.0111C12.9186 16.1908 13.1623 16.2917 13.4165 16.2917C13.6707 16.2917 13.9144 16.1908 14.0941 16.0111C14.2739 15.8313 14.3748 15.5876 14.3748 15.3334V11.5001ZM9.58317 10.5417C9.329 10.5417 9.08525 10.6427 8.90553 10.8224C8.7258 11.0022 8.62484 11.2459 8.62484 11.5001V15.3334C8.62484 15.5876 8.7258 15.8313 8.90553 16.0111C9.08525 16.1908 9.329 16.2917 9.58317 16.2917C9.83734 16.2917 10.0811 16.1908 10.2608 16.0111C10.4405 15.8313 10.5415 15.5876 10.5415 15.3334V11.5001C10.5415 11.2459 10.4405 11.0022 10.2608 10.8224C10.0811 10.6427 9.83734 10.5417 9.58317 10.5417Z"
        fill="#0E0E4B"
      />
    </svg>
  );
};

export default DeleteIcon;
