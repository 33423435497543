import React from "react";
import FirststepIcon from "../../images/1step";
import SecondStepIcon from "../../images/2step";
import ThirdStepIcon from "../../images/3step";
import FourthStepIcon from "../../images/4step";
import DoneIcon from "../../images/doneIcon";
import { useTranslation } from "react-i18next";

type Props = {
  activeStep: number;
};

const Steps: React.FC<Props> = ({ activeStep }) => {
  const { t } = useTranslation();

  const handleActiveStep = (step: number) => {
    if (step === activeStep) return "steps-active";
    if (activeStep > step) return "steps-done";
    if (activeStep < step) return "steps_item";
  };

  const handleDoneIconColor = (step: number) => {
    if (step === activeStep) return "#00D26D";
    if (activeStep > step) return "#00D26D";
    if (activeStep < step) return "#E1E1E1";
  };

  const handleStepIconColor = (step: number) => {
    if (step === activeStep) return "#0E0E4B";
    if (activeStep > step) return "#0E0E4B";
    if (activeStep < step) return "#E1E1E1";
  };

  const steps = [
    {
      id: 1,
      icon: <FirststepIcon />,
    },
    {
      id: 2,
      icon: <SecondStepIcon color={handleStepIconColor(2)} />,
    },
    {
      id: 3,
      icon: <ThirdStepIcon color={handleStepIconColor(3)} />,
    },
    {
      id: 4,
      icon: <FourthStepIcon color={handleStepIconColor(4)} />,
    },
  ];

  return (
    <div className="steps">
      {steps.map((el) => (
        <div className={handleActiveStep(el.id)} key={el.id}>
          {activeStep === el.id && (
            <div className="steps-active_line" />
          )}
          <div className={`${handleActiveStep(el.id)}_title`}>
            {el.icon}
            <span>{el.id}-{t("step")}</span>
          </div>
          <DoneIcon color={handleDoneIconColor(el.id)} />
        </div>
      ))}
    </div>
  );
};

export default Steps;
