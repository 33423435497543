import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CallModal from "../../components/Modal/CallModal";
import SoonModal from "../../components/Modal/SoonModal";
import YurModal from "../../components/Modal/YurModal";
import Header from "../../components/Header/Header";
import Arrow2 from "../../images/arrow2";
import CallIcon from "../../images/callIcon";
// import SendIcon from "../../images/sendIcon";
import ClockIcon from "../../images/clockIcon";
import FileInfo from "../../images/file-info";
import FileDownload from "../../images/file-download";
import DocumentIcon from "../../images/documentIcon";
import ObjIcon from "../../images/objIcon";
import UsersIcon from "../../images/usersIcon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  getGroupAdmin,
  getPinnedUser,
  getServiceDetail,
  getServiceDetailList,
  getServiceReklama,
  getServiceVideoMarketingList,
  getServiceVideoTutorialList,
} from "../../store/actions/contractActions";
import StatusModal from "../../components/Modal/StatusModal";
import { useTranslation } from "react-i18next";
import SimpleModal from "../../components/Modal/SimpleModal";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  iServiceDetailList,
  iServiceDetailVideoList,
} from "../../store/models";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import VanillaTilt from "vanilla-tilt";
var PlayIcon = require("../../images/playbtn.png");

const Tilt = (props: any) => {
  const { options, ...rest } = props;
  const tilt = useRef<any>(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
};

type InfoProps = {
  label: string;
  info: string | undefined | any;
  icon: any;
};

type ModalTypes = "call" | "auth" | "soon" | "yur" | "simple";

const InfoItem = ({ label, info, icon }: InfoProps) => {
  return (
    <div className="serviceWrap_info-block_item">
      <div className="serviceWrap_info-block_item_label">
        {icon}
        <span>{label}</span>
      </div>
      <div className="serviceWrap_info-block_item_info">{info}</div>
    </div>
  );
};

const ExtraItemAppDoc = ({ label, info, icon }: InfoProps) => {
  const { t } = useTranslation();
  return (
    <div className="serviceWrap_info-block_item">
      <div className="serviceWrap_info-block_item_label">
        {icon}
        <span>{label}</span>
      </div>
      <div className="serviceWrap_info-block_item_info">
        <a
          className="serviceWrap_info-block_item_info__link"
          href={info}
          target="_blank"
          rel="noreferrer"
        >
          {t("upload")}
        </a>
      </div>
    </div>
  );
};
const ExtraItemInfoDoc = ({ label, info, icon }: InfoProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className="serviceWrap_info-block_item">
      <div className="serviceWrap_info-block_item_label">
        {icon}
        <span>{label}</span>
      </div>
      <div className="serviceWrap_info-block_item_info">
        <button
          className="serviceWrap_info-block_item_info__link"
          onClick={() => setOpen(true)}
        >
          {t("see")}
        </button>
        {open && (
          <div className="modalWrap" onClick={() => setOpen(false)}>
            <div
              style={{ position: "relative", borderRadius: "4px" }}
              className="publicOffer_content agreement serviceWrap_info-block_item_info__modal"
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                }}
              >
                <h3
                  style={{
                    backgroundColor: "rgb(14 14 75)",
                    color: "#fff",
                    padding: "16px",
                    paddingLeft: "50px",
                  }}
                >
                  {label}
                </h3>
              </div>
              <div
                style={{ paddingTop: "56px" }}
                dangerouslySetInnerHTML={{
                  __html: info,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ServicePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { state } = location;

  const { userDetail } = useAppSelector((state) => state.user);
  const { access } = useAppSelector((state) => state.auth);
  const {
    serviceDetail,
    groupAdmin,
    pinnedUser,
    serviceDetailList,
    serviceDetailVideoMarketingList,
    serviceDetailVideoTutorialList,
    serviceReklamalList,
  } = useAppSelector((state) => state.contracts);

  const video_instructions = serviceDetailVideoTutorialList;

  const [modal, setModal] = useState<ModalTypes | null>(null);

  const getAuth = () => {
    window.location.replace(
      `https://api2.unicon.uz/api/oauth/oneid-login?path=${window.location.origin}`
    );
  };

  useEffect(() => {
    dispatch(getServiceDetail(params.id));
    dispatch(getServiceDetailList(params.id));
    dispatch(getServiceVideoMarketingList(params.id));
    dispatch(getServiceVideoTutorialList(params.id));
    dispatch(getServiceReklama(params.id));
  }, []);

  useEffect(() => {
    // dispatch(getGroupAdmin(params.id));
    dispatch(getPinnedUser(params.id));
  }, []);

  const handleNavigate = () => {
    if (
      serviceDetail?.slug === "colocation" ||
      state?.slug?.slug === "colocation"
    ) {
      return "/request";
    } else if (serviceDetail?.slug === "vps" || state?.slug?.slug === "vps") {
      return "/vps";
    } else if (
      serviceDetail?.slug === "e-xat" ||
      state?.slug?.slug === "e-xat"
    ) {
      return "/e-xat";
    } else {
      return "/ariza";
    }

    // if (params.id == '4' || params.id == '5' || params.id == '6' || params.id == '7' || params.id == '8' || params.id == '19') {
    //   return '/ariza'
    // } else if (params.id == '18') {
    //   return '/vps'
    // } else {
    //   return '/request'
    // }
  };
  console.log(serviceReklamalList, "serviceReklamaList");

  return (
    <div className="serviceWrap">
      <Header />
      <div className="serviceWrap_img">
        <div className="container">
          <div className="serviceWrap_img-content">
            <div>
              <div className="serviceWrap_img-content-title">
                {serviceDetail?.name}
              </div>
              <div className="serviceWrap_img-content-descr">
                {serviceDetail?.description}
              </div>
            </div>
            <div className="serviceWrap_img-content-btnWrap">
              {params.id !== "4" && params.id == "3" ? (
                <button onClick={() => setModal("soon")}>
                  <span>{t("useOfTheService")}</span>
                  <Arrow2 />
                </button>
              ) : (params.id == "5" || params.id == "23") &&
                userDetail?.u_type === "Fizik" ? (
                <button onClick={() => setModal("yur")}>
                  <span>{t("useOfTheService")}</span>
                  <Arrow2 />
                </button>
              ) : serviceDetail?.slug === "colocation" ||
                serviceDetail?.slug === "e-xat" ||
                serviceDetail?.slug === "vps" ? (
                <>
                  <button
                    onClick={() => {
                      access
                        ? navigate(handleNavigate(), {
                            state: {
                              service_id: params?.id,
                              group_id: serviceDetail?.group.id,
                              name: serviceDetail?.name,
                            },
                          })
                        : setModal("auth");
                      localStorage.setItem(
                        "service_id",
                        JSON.stringify(params?.id)
                      );
                    }}
                  >
                    <span>{t("useOfTheService")}</span>
                    {/* <Arrow2 /> */}
                  </button>
                </>
              ) : access &&
                serviceDetail?.slug !== "colocation" &&
                serviceDetail?.slug !== "e-xat" &&
                serviceDetail?.slug !== "vps" ? (
                <>
                  <button
                    onClick={() => {
                      access
                        ? navigate(handleNavigate(), {
                            state: {
                              service_id: params?.id,
                              group_id: serviceDetail?.group.id,
                              name: serviceDetail?.name,
                            },
                          })
                        : setModal("auth");
                      localStorage.setItem(
                        "service_id",
                        JSON.stringify(params?.id)
                      );
                    }}
                  >
                    <span>{t("feedback")}</span>
                    {/* <Arrow2 /> */}
                  </button>
                </>
              ) : (
                ""
              )}
              {!access ? (
                <button
                  onClick={() => {
                    navigate("/qayta-aloqa");
                    localStorage.setItem(
                      "service_id",
                      JSON.stringify(params?.id)
                    );
                  }}
                >
                  <span>{t("feedback")}</span>
                  {/* <Arrow2 /> */}
                </button>
              ) : (
                ""
              )}
              <a
                className="share-btn"
                href={`https://telegram.me/share/url?url=https://xizmatlar.unicon.uz${location.pathname}`}
                target="_blank"
              >
                {/* <SendIcon width="32px" /> */}
                <span
                  style={{
                    display: "inline-block",
                    fontSize: "1.2rem",
                    fontWeight: "bolder",
                    marginLeft: "30px",
                  }}
                >
                  {t("share")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          paddingTop: "40px",
          paddingBottom: "40px",
          gap: "20px",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {serviceDetailList?.map((el: iServiceDetailList) => (
            <div className="mb-3">
              <Accordion key={el.id} className="accardion">
                <AccordionSummary
                  expandIcon={""}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {el.icon !== null ? (
                    <img
                      style={{ marginRight: "16px" }}
                      width={28}
                      src={el?.icon}
                      alt="rasm"
                    />
                  ) : (
                    ""
                  )}{" "}
                  <span style={{ fontWeight: "bolder" }}>{el.name}</span>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#fff" }}>
                  <div dangerouslySetInnerHTML={{ __html: el?.description }} />
                  {el?.file && (
                    <a href={el.file} target="_blank">
                      {t("download_file")}
                    </a>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>

        {/* <div style={{ width: "100%" }} className="serviceWrap_info">
          
          <div className="serviceWrap_info-block">
            <InfoItem
              icon={<ObjIcon />}
              label={t("departmentName")}
              info={serviceDetail?.group.comment}
            />
            <InfoItem
              icon={<UsersIcon />}
              label={t("usersOfTheService")}
              info={`${
                serviceDetail?.user_type_id === 1
                  ? t("fiz_type")
                  : serviceDetail?.user_type_id == 2
                  ? t("yur_type")
                  : t("fiz_and_yur_type")
              } ${t("faces")}`}
            />
            {serviceDetail?.information !== "" && (
              <ExtraItemInfoDoc
                icon={<FileInfo />}
                label={t("service_info")}
                info={serviceDetail?.information}
              />
            )}
          </div>
          <div className="serviceWrap_info-block">
            <InfoItem
              icon={<ClockIcon />}
              label={t("termOfService")}
              info={`${serviceDetail?.period} ${t("workday")}`}
            />
            <InfoItem
              icon={<DocumentIcon />}
              label={t("needDocuments")}
              info={serviceDetail?.need_documents
                .map((el) => el.name)
                .join(", ")}
            />
            {serviceDetail?.download_file !== null && (
              <ExtraItemAppDoc
                icon={<FileDownload />}
                label={
                  serviceDetail?.slug === "e-xat"
                    ? t("sample_contract")
                    : t("application_sample")
                }
                info={serviceDetail?.download_file}
              />
            )}
          </div>
        </div> */}
        <div
          style={{
            width: "100%",
            maxWidth: "300px",
          }}
        >
          {serviceDetailVideoMarketingList?.length != 0 && (
            <div className="mb-3">
              {/* <Accordion className="accardion">
                <AccordionSummary
                  expandIcon={""}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <span style={{ fontWeight: "bolder" }}>
                    {t("service_video")}
                  </span>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#fff" }}>
                  {serviceDetailVideoMarketingList?.map(
                    (ell: iServiceDetailVideoList) => (
                      <div key={ell.id}>
                        <a
                          href={ell.video_file || ell.video_doc || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {ell.name}
                        </a>
                      </div>
                    )
                  )}
                </AccordionDetails>
              </Accordion> */}
              <span>{t("service_video")}:</span>
              <a
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
                href={
                  serviceDetailVideoMarketingList
                    ? serviceDetailVideoMarketingList[0]?.video_file ||
                      serviceDetailVideoMarketingList[0]?.video_doc
                    : "#"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <video
                  autoPlay
                  loop={true}
                  muted
                  width={300}
                  src={
                    serviceDetailVideoMarketingList
                      ? serviceDetailVideoMarketingList[0]?.video_file ||
                        serviceDetailVideoMarketingList[0]?.video_doc
                      : "#"
                  }
                />
              </a>
            </div>
          )}
          {serviceDetailVideoTutorialList?.length != 0 && (
            <div
              onClick={() => setModal("simple")}
              className="video-instuctor"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                border: "1px solid rgb(171, 184, 195)",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "300px",
                marginBottom: "20px",
                cursor: "pointer",
              }}
            >
              <img className="m-2" src={PlayIcon} alt="video" />
              <p
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  color: "#0E0E4B",
                }}
              >
                {t("video_guide")}
              </p>
            </div>
          )}

          {serviceReklamalList && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  border: "1px solid rgb(171, 184, 195)",
                  borderRadius: "8px",
                  width: "100%",
                  maxWidth: "300px",
                  height: "230px",
                  cursor: "pointer",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={serviceReklamalList[0]?.video_file} alt="sale" />
                {/* <Swiper
                  direction={"horizontal"}
                  slidesPerView={"auto"}
                  spaceBetween={30}
                  modules={[Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper w-full"
                  style={{ width: "100%", height: "100%" }}
                >
                  {serviceReklamalList?.map((e: any, idx: number) => {
                    <Tilt key={e.id}>
                      <SwiperSlide key={idx + 1}>
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                        <a
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                          }}
                          target={"_blank"}
                          rel="noreferrer"
                          href={"https://dc.unicon.uz/"}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={
                              "http://dcid.unicon.uz/media/servicevideotutorial/it-park.jpg"
                            }
                            alt="sale"
                          />
                        </a>
                      </SwiperSlide>
                    </Tilt>;
                  })}
                </Swiper> */}
              </div>
            </>
          )}
        </div>
      </div>
      {modal === "call" && (
        <CallModal
          close={() => setModal(null)}
          admin={groupAdmin}
          pinnedUser={pinnedUser}
        />
      )}
      {modal === "auth" && (
        <StatusModal
          type="auth"
          close={() => setModal(null)}
          navigation={getAuth}
        />
      )}
      {modal === "soon" && <SoonModal close={() => setModal(null)} />}
      {modal === "yur" && <YurModal close={() => setModal(null)} />}
      {modal === "simple" && (
        <SimpleModal
          close={() => setModal(null)}
          video_instructions={video_instructions}
        />
      )}
    </div>
  );
};

export default ServicePage;
