import React from "react";

const Checked = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.45455L4.84122 9.01898C5.24156 9.52123 6.00482 9.52123 6.40516 9.01898L12 2"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Checked;
