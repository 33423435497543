import * as emailActionTypes from '../actionTypes/EMailActionTypes'
import {axios2} from "../../axios";
import {handleType} from "./handleType";
import {Dispatch} from "redux";
import {RefreshState} from "./authActions";

export const createEMailContract = (token: RefreshState | null, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(emailActionTypes.POST_EMAIL_START, null))
    try {
      const res = await axios2.post('e-xat/contract-create', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      dispatch(handleType(emailActionTypes.POST_EMAIL_SUCCESS, res.data))
      return res.data
    } catch (e: any) {
      dispatch(handleType(emailActionTypes.POST_EMAIL_ERROR, e.message))
      return e
    }
  }
}