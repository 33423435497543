import React, { useEffect, useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import VanillaTilt from "vanilla-tilt";
let Sale15 = require("../../images/chegirma15.jpg");
let Sale25 = require("../../images/chegirma25.jpg");


const Tilt = (props: any) => {
  const { options, ...rest } = props;
  const tilt = useRef<any>(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

export default function RekModal() {
  const [open, setOpen] = useState<boolean>(false);
  
  const renderTime = ({ remainingTime }: any) => {
    if (remainingTime === 0) {
      return <div className="timer">X</div>;
    }
    
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };
  
  useEffect(() => {
    const isVisible = sessionStorage.getItem('modalVisible')

    if (isVisible == null) {
      const clearId = setTimeout(() => {
        setOpen(true)
        sessionStorage.setItem('modalVisible', JSON.stringify(true))
      }, 100)
      return () => clearTimeout(clearId)
    }

  }, [])


  let last = 0;
  setInterval(tick, 1000);
  function tick() {
    last++;
  }


  const handleClick = () => {
    if (last >= 0) {
      setOpen(false);
    }
  };

  return (
    <div
      className={"rek-modal"}
      onClick={handleClick}
      style={open ? { position: "fixed", top: "0", width: "100%", backgroundColor: "#0005", height: "100%", zIndex: "900", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" } : { display: "none" }}
    >
      <div className="rek-modal__wrapper absolute w-[50%] md:w-[80%] z-[99]" style={{ position: "absolute", width: "50%", zIndex: "99", left: "25%" }} >
        <div style={{ marginBottom: "4px", display: "flex", justifyContent: "flex-end", alignItems: "center", transform: "translateX(30px)" }} className="timer-wrapper mb-2">

          <CountdownCircleTimer
            strokeWidth={4}
            size={40}
            isPlaying
            duration={0}
            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000", 0.33]]}
            onComplete={() => [false, 1000]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
        <Swiper
          direction={"horizontal"}
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="mySwiper w-full"
          style={{ width: "100%" }}
        >
          <Tilt>
            <SwiperSlide>
              <a target={"_blank"} rel="noreferrer" href={"https://dc.unicon.uz/"}>
                <img style={{ width: "100%" }} src={Sale15} alt="sale" />
              </a>
            </SwiperSlide>
          </Tilt>
          <Tilt>
            <SwiperSlide>
              <a target={"_blank"} rel="noreferrer" href={"https://dc.unicon.uz/"}>
                <img style={{ width: "100%" }} src={Sale25} alt="sale" />
              </a>
            </SwiperSlide>
          </Tilt>
        </Swiper>
      </div>
    </div>
  );
}
