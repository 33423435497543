import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
// import {images} from "../../images/images";
import {switchLanguage} from "../../store/actions/languageAction";
import {useTranslation} from "react-i18next";
import i18n from "../../resources/i18n";
import UzbIcon from "../../images/uzbIcon";
import RusIcon from "../../images/rusIcon";
import NotificationIcon from "../../images/NotificationIcon";
import {getMessage} from "../../store/actions/authActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import VanillaTilt from "vanilla-tilt";
var Rek15 = require("../../images/rek15.png");
var Rek25 = require("../../images/rek25.png");
var UsaIcon = require("../../images/usa-flag.png");

interface TiltProps {
  // options: any;
}

const Tilt: React.FC<TiltProps> = (props: any) => {
  const { options, ...rest } = props;
  const tilt = useRef<any>(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {t} = useTranslation();

  const [notificationModal, setNotificationModal] = useState<boolean>(false)

  const {userDetail} = useAppSelector((state) => state.user);
  const {access, message} = useAppSelector((state) => state.auth);
  const {language} = useAppSelector((state) => state.language);
  const {vpsBalance} = useAppSelector((state) => state.vps)

  // const getAuth = () => {
  //   window.location.replace(
  //     `https://api2.unicon.uz/api/oauth/oneid-login?path=${window.location.origin}`
  //   );
  // };

  useEffect(() => {
    if (access && location.pathname !== '/news') {
      dispatch(getMessage(access, false))
    }
  }, [access, dispatch, location.pathname]);

  const changeLng = (lng: any) => {
    if (lng !== language) {
      dispatch(switchLanguage(lng));
      i18n.changeLanguage(lng);
      localStorage.setItem('language', lng)
      window.location.reload()
    }
  };

  const navigation = (id: string | null, type: number) => {
    navigate(`/news`, {state: {type, id}})
    setNotificationModal(!notificationModal)
  }

  return (
    <div>
      {/* <div className="test absolute top-0 overflow-x-hidden">
        <p className="animation">Tizim test rejimida ishlamoqda!!!</p>
      </div> */}

      <Swiper
          direction={"horizontal"}
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper w-full"
        >
          <Tilt>
            <SwiperSlide key={1}>
              <a
                className={
                  location.pathname !== "/"
                    ? "hidden"
                    : "swiper--link"
                }
                target={"_blank"}
                rel="noreferrer"
                href={"https://dc.unicon.uz/"}
              >
                <img
                  className={"mx-auto"}
                  width={1200}
                  src={Rek25}
                  alt={"chegirma 25%"}
                />
              </a>
            </SwiperSlide>
          </Tilt>
          <Tilt>
            <SwiperSlide key={2}>
              <a
                className={
                  location.pathname !== "/"
                    ? "hidden"
                    : "swiper--link"
                }
                target={"_blank"}
                rel="noreferrer"
                href={"https://dc.unicon.uz/"}
              >
                <img
                  className={"mx-auto"}
                  width={1200}
                  src={Rek15}
                  alt={"chegirma 15%"}
                />
              </a>
            </SwiperSlide>
          </Tilt>
        </Swiper>

      <div className="header">
        <div className="header_wrap">
          <button onClick={() => navigate("/")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="36.2"
                 viewBox="0 0 293.5 36.2">
              <g id="Layer_2">
                <g id="Layer_1-2">
                  <g id="Layer_2-2">
                    <g>
                      <path className="cls-1"
                            d="M69.9,1.1V23.2a13,13,0,0,1-26,0V1.1h5.8V23.2a7.1,7.1,0,0,0,14.2,0h0V1.1Z"/>
                      <path className="cls-1" d="M102.7,1.1V36.2L80.8,14.8V36.1H75V.9L96.9,22.3V1.1Z"/>
                      <path className="cls-1" d="M107.8,36.1V1.1h5.8v35Z"/>
                      <path className="cls-1"
                            d="M148.3,31.2a17.5,17.5,0,1,1-.1-25.4l-4,4.2a11.62,11.62,0,0,0-16.3.3A11.67,11.67,0,0,0,144.2,27Z"/>
                      <path className="cls-1"
                            d="M185.9,18.6a17.46,17.46,0,1,1,0-.08Zm-5.8,0a11.7,11.7,0,1,0-11.7,11.7h0A11.85,11.85,0,0,0,180.1,18.6Z"/>
                      <path className="cls-1" d="M218.7,1.1V36.2L196.8,14.8V36.1H191V.9l21.9,21.4V1.1Z"/>
                      <path className="cls-1" d="M223.8,32.6a3.4,3.4,0,1,1,3.4,3.4h0a3.37,3.37,0,0,1-3.4-3.34Z"/>
                      <path className="cls-2"
                            d="M261.6,1.1V23.2a13,13,0,0,1-26,0V1.1h5.8V23.2a7.1,7.1,0,0,0,14.2,0V1.1Z"/>
                      <path className="cls-2" d="M293.5,30.2V36H266.6L283.1,6.8H267.2V1h25.9L276.6,30.2Z"/>
                      <polygon className="cls-2" points="42.1 36.1 34.7 36.1 17.2 6.3 20.9 0 42.1 36.1"/>
                      <polygon className="cls-1" points="33.4 36.1 0 36.1 16.6 7.4 33.4 36.1"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <div className="header_wrap-right">
            <div className="header_wrap-right_lngBtns">
              <button
                onClick={() => changeLng("uz")}
                style={{opacity: language === "uz" ? 0.5 : 1}}
              >
                <UzbIcon/>
              </button>
              <button
                onClick={() => changeLng("ru")}
                style={{opacity: language === "ru" ? 0.5 : 1}}
              >
                <RusIcon/>
              </button>
              <button
                onClick={() => changeLng("en")}
                style={{opacity: language === "en" ? 0.5 : 1}}
              >
                <img
                  className={"mx-auto"}
                  width={32}
                  src={UsaIcon}
                  alt={"chegirma 25%"}
                />
              </button>
              {access &&
                <button
                  onClick={() => setNotificationModal(!notificationModal)}
                  className={'notificationBtn'}
                >
                  <span>{message?.count}</span>
                  <NotificationIcon/>
                </button>
              }
            </div>
            {access ? (
              <div
                className="header_wrap-right_user"
                onClick={() => navigate("/profile")}
              >
                <div className="header_wrap-right_user-cap">
                  <span>{userDetail?.first_name?.charAt(0)}.</span>
                  <span>{userDetail?.sur_name?.charAt(0)}.</span>
                </div>
                <div className="header_wrap-right_user-name">
                  <span>{userDetail?.first_name}</span>
                  <span>{vpsBalance?.err_id === 0 ? Math.floor(vpsBalance?.responce?.balance) : 0} uzs</span>
                </div>
              </div>
            ) : (
              <button className={'login'} style={{color: '#000'}} onClick={() => navigate('/auth')}>
                {t("loginToSystem")}
              </button>
            )}
          </div>
        </div>
        <div
          style={{
            opacity: notificationModal ? 1 : 0,
            pointerEvents: notificationModal ? 'all' : 'none',
            display: message?.count !== 0 ? 'block' : 'none'
          }}
          className='notificationModal'
        >
          <ul>
            {message?.datas?.map((item, index) => (
              <div
                onClick={() => navigation(item.id, item.type)}
                key={Math.floor(index)}
              >
                {item.msg?.slice(0, 50)}...
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
