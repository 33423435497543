import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import Logo from "../../images/logo";
import Checked from "../../images/checked";
import OneId from "../../images/oneId";
import AuthLogo from "../../images/authLogo";
import OneAuth from "../../components/oneauth/OneAuth";
import {axios2} from "../../axios";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

const AuthPage: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const [active, setActive] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [type, setType] = useState<number>(1)
  
  useEffect(() => {
    AppLoad()
  }, []);
  
  const login = async () => {
    await axios2.get('api/oauth/login/eri').then(({data}) => {
      localStorage.setItem('challenge', data?.challenge)
      // @ts-ignore
      signIn(navigate)
    })
  }
  
  const getAuth = () => {
    window.location.replace(
      `https://api2.unicon.uz/api/oauth/oneid-login?path=${window.location.origin}`
    );
  };
  
  return (
    <div className="auth_wrap">
      <div className="auth_wrap-left">
        <Logo/>
        <div className="auth_wrap-left_text">
          <p>
            {t("info_unicon")}
          </p>
        </div>
        <span>
          © Copyright 2022.{" "}
          <a href="https://unicon.uz/" target="_blank">
            UNICON.UZ
          </a>
        </span>
        <div className="auth_wrap-left_absolute">
          <AuthLogo/>
        </div>
      </div>
      <div className="auth_wrap-right">
        <div>
          <h3>{t("loginToSystem")}</h3>
          <div className="auth_wrap-right_approve">
            <div className="auth_wrap-right_approve_checkBlock">
              <div
                className={
                  active
                    ? "auth_wrap-right_approve_checkBlock-active"
                    : "auth_wrap-right_approve_checkBlock-check"
                }
                onClick={() => setActive(!active)}
              >
                {active && <Checked/>}
              </div>
              <div className="auth_wrap-right_approve_checkBlock-text">
                
                <span onClick={() => setModal(true)}>
                  {t("agreement")}
                </span>{" "}
               
              </div>
            </div>
          </div>
          <div className={'tabs'}>
            {/*<div*/}
            {/*  className={'tab'}*/}
            {/*  onClick={() => {*/}
            {/*    setType(1)*/}
            {/*    setActive(false)*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    background: type === 1 ? '#3C4B64' : 'transparent',*/}
            {/*    color: type === 1 ? '#fff' : 'black',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  OneId*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className={'tab'}*/}
            {/*  onClick={() => {*/}
            {/*    setType(2)*/}
            {/*    setActive(false)*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    background: type === 2 ? 'crimson' : 'transparent',*/}
            {/*    color: type === 2 ? '#fff' : 'crimson',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  ERI*/}
            {/*</div>*/}
          </div>
          <button
            className={
              active ? "auth_wrap-right_active" : "auth_wrap-right_button"
            }
            disabled={!active}
            style={{
              display: type === 1 ? 'block' : 'none'
            }}
            onClick={getAuth}
          >
            <OneId/> <span>{t("access_through_system")}</span>
          </button>
          <form
            id={'eri_form'}
            name={'eri_form'}
            onSubmit={(e) => e.preventDefault()}
            style={{
              marginTop: 10,
              opacity: type === 2 ? 1 : 0,
              pointerEvents: type === 2 ? "all" : "none",
            }}
          >
            <OneAuth/>
            <select name="key" onChange={cbChanged} id="key" className='form-select'></select>
            <button
              id={'eri_signIn'}
              onClick={login}
              disabled={!active}
              style={{
                opacity: !active ? 0.5 : 1
              }}
            >
              <span>{t("loginToSystem")}</span>
            </button>
          </form>
        </div>
      </div>
      {modal && <Modal close={() => setModal(false)}/>}
    </div>
  );
};

export default AuthPage;
