import React, {useEffect} from "react";
import {logoutHandler, clearAuthData} from "../store/actions/authActions";
import {cleanUserDetails} from "../store/actions/contractActions";
import {axios2} from "../axios/index";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../hooks/redux";

const InterCeptor: React.FC = () => {

  const dispatch = useAppDispatch()

  const {userDetail} = useAppSelector((state) => state.user)
  const {token, access, response} = useAppSelector((state) => state.auth)
  const auth = useAppSelector(state => state.auth)
  
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      axios2.interceptors.response.use((response: any) => {
        return response
      }, (error: any) => {
        if (error.response.status === 401) {
          // const refresh: any = localStorage.getItem('res')
          // const body = {
          //   response,
          //   token,
          //   access,
          // };
          navigate('/')
          // dispatch(logoutHandler(body, access, JSON.parse(refresh ? refresh?.refresh : null)));
          dispatch(clearAuthData(auth))
          dispatch(cleanUserDetails(userDetail))
          localStorage.removeItem('res')
          localStorage.clear()
          window.location.reload()
        }
        return error
      })
    }
  }, [access])

  return (
    <></>
  )
}

export default InterCeptor