import React from "react";

const OneAuth: React.FC = () => {
  return (
    <>
      <div id="keyId" style={{display: 'none'}}></div>
      
      <input type="hidden" name="eri_fullname" id="eri_fullname"/>
      <input type="hidden" name="eri_inn" id="eri_inn"/>
      <input type="hidden" name="eri_pinfl" id="eri_pinfl"/>
      <input type="hidden" name="eri_sn" id="eri_sn"/>
      <textarea style={{display: 'none'}} name="eri_data" id="eri_data"></textarea>
      <textarea style={{display: 'none'}} name="eri_hash" id="eri_hash"></textarea>
    </>
  )
}

export default OneAuth