import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import StatusModal from "../../components/Modal/StatusModal";
import ShowRequest from "../../components/ShowRequest/ShowRequest";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getContractDetail} from "../../store/actions/contractActions";

const ShowRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [modal, setModal] = useState<boolean>(true);

  const {access} = useAppSelector((state) => state.auth);
  const {contractDetail} = useAppSelector((state) => state.contracts);
  const {userDetail} = useAppSelector((state) => state.user);
  const {change} = useAppSelector((state) => state.userService)

  const {state} = location
  
  useEffect(() => {
    if (state?.name === 'vps') {
      dispatch(getContractDetail(access, 'vps', params.id));
    } else if (state?.name === 'e-xat') {
      dispatch(getContractDetail(access, 'e-xat', params.id));
    } else if(state?.name === 'colocation') {
      dispatch(getContractDetail(access, 'colocation', params.id));
    } else {
      dispatch(getContractDetail(access, change.userSelectService === undefined ? 'e-xat' : change.userSelectService, params.id));
    }
  }, [access, change, dispatch, params.id, state?.name]);
  
  return (
    <>
      <Header/>
      <div className="container">
        <div className="request">
          <div className="request_content">
            <ShowRequest detail={contractDetail} userDetail={userDetail}/>
          </div>
        </div>
      </div>
      {modal && (
        <StatusModal
          type="success"
          close={() => setModal(false)}
          navigation={() => navigate("/my-request")}
        />
      )}
    </>
  );
};

export default ShowRequestPage;
