import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import SuccessIcon from "../../images/successIcon";

type Props = {
  close: () => void;
}

const SavedModal: React.FC<Props> = ({close}) => {
  return (
    <>
      <div className="modalWrap">
        <OutsideClickHandler onOutsideClick={close}>
          <div className="modalWrap_block" style={{textAlign: 'center'}}>
            <SuccessIcon/>
            <p style={{color: "#fff", fontSize: '18px'}}>Muvoffaqiyatli saqlandi</p>
            <div className="modalWrap_block-btnBlock" style={{justifyContent: 'center'}}>
              <button onClick={close}>OK</button>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  )
}

export default SavedModal