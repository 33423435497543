import React from "react";
import ErrorIcon from "../../images/errorIcon";

type Props = {
  close: () => void;
  text: any
};

const SignatureModal: React.FC<Props> = ({close, text}) => {
  return (
    <div className="modalWrap">
      <div className="modalWrap_block">
        <div className="modalWrap_block-status">
          <ErrorIcon/>
          <div className="modalWrap_block-status-title">
            {text}
          </div>
          <button onClick={close}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;
