import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getToken, postAuth, refreshToken } from "../store/actions/authActions";
import LoadingPage from "./LoadingPage/LoadingPage";

const CodePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { response, token } = useAppSelector((state) => state.auth);
  const [codeParams, setCodeParams] = useSearchParams();
  
  const code = codeParams.get("code");

  useEffect(() => {
    dispatch(postAuth(code, window.location.origin));
  }, []);

  useEffect(() => {
    if (response) {
      dispatch(getToken(response?.access_token));
    }
  }, [response]);

  useEffect(() => {
    if (token) {
      dispatch(refreshToken({refresh: token?.refresh}, navigate));
    }
  }, [token])

  return <LoadingPage />;
};

export default CodePage;
