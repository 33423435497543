import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.9994 117.333C71.0045 117.342 77.9424 115.967 84.4143 113.286C90.8862 110.605 96.7645 106.672 101.711 101.712C106.671 96.765 110.605 90.8867 113.286 84.4148C115.966 77.9429 117.342 71.005 117.333 63.9999C117.342 56.9947 115.966 50.0569 113.285 43.585C110.605 37.1131 106.671 31.2348 101.711 26.2879C96.7645 21.328 90.8862 17.3945 84.4143 14.7137C77.9424 12.0329 71.0045 10.6575 63.9994 10.6665C56.9943 10.6576 50.0564 12.0331 43.5845 14.7139C37.1127 17.3947 31.2343 21.3281 26.2874 26.2879C21.3276 31.2348 17.3943 37.1131 14.7134 43.585C12.0326 50.0569 10.6571 56.9947 10.6661 63.9999C10.657 71.005 12.0324 77.9429 14.7132 84.4148C17.3941 90.8867 21.3275 96.765 26.2874 101.712C31.2343 106.672 37.1127 110.605 43.5845 113.286C50.0564 115.967 56.9943 117.342 63.9994 117.333V117.333Z"
        stroke="#DBFF00"
        strokeWidth="10.6667"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9997 98.6663C65.7678 98.6663 67.4635 97.964 68.7137 96.7137C69.964 95.4635 70.6663 93.7678 70.6663 91.9997C70.6663 90.2316 69.964 88.5359 68.7137 87.2856C67.4635 86.0354 65.7678 85.333 63.9997 85.333C62.2316 85.333 60.5359 86.0354 59.2856 87.2856C58.0354 88.5359 57.333 90.2316 57.333 91.9997C57.333 93.7678 58.0354 95.4635 59.2856 96.7137C60.5359 97.964 62.2316 98.6663 63.9997 98.6663Z"
        fill="#DBFF00"
      />
      <path
        d="M63.999 32V74.6667"
        stroke="#DBFF00"
        strokeWidth="10.6667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
