import React from "react";

const FirststepIcon = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67076 1.59164C8.08326 1.96247 8.11659 2.59164 7.74576 3.00414L4.74576 6.33747C4.56243 6.54164 4.30409 6.66247 4.02909 6.66664C3.75409 6.6708 3.49159 6.56664 3.29576 6.37497L1.62493 4.7083C1.23326 4.31664 1.23326 3.6833 1.62493 3.2958C2.01659 2.9083 2.64993 2.90414 3.03743 3.2958L3.95826 4.21664L6.25409 1.66664C6.62493 1.25414 7.25409 1.2208 7.66659 1.59164H7.67076ZM7.67076 8.2583C8.08326 8.62914 8.11659 9.2583 7.74576 9.6708L4.74576 13.0041C4.56243 13.2083 4.30409 13.3291 4.02909 13.3333C3.75409 13.3375 3.49159 13.2333 3.29576 13.0416L1.62493 11.375C1.23326 10.9833 1.23326 10.35 1.62493 9.96247C2.01659 9.57497 2.64993 9.5708 3.03743 9.96247L3.95826 10.8833L6.25409 8.3333C6.62493 7.9208 7.25409 7.88747 7.66659 8.2583H7.67076ZM10.6666 3.99997C10.6666 3.26247 11.2624 2.66664 11.9999 2.66664H21.3333C22.0708 2.66664 22.6666 3.26247 22.6666 3.99997C22.6666 4.73747 22.0708 5.3333 21.3333 5.3333H11.9999C11.2624 5.3333 10.6666 4.73747 10.6666 3.99997ZM10.6666 10.6666C10.6666 9.92914 11.2624 9.3333 11.9999 9.3333H21.3333C22.0708 9.3333 22.6666 9.92914 22.6666 10.6666C22.6666 11.4041 22.0708 12 21.3333 12H11.9999C11.2624 12 10.6666 11.4041 10.6666 10.6666ZM7.99993 17.3333C7.99993 16.5958 8.59576 16 9.33326 16H21.3333C22.0708 16 22.6666 16.5958 22.6666 17.3333C22.6666 18.0708 22.0708 18.6666 21.3333 18.6666H9.33326C8.59576 18.6666 7.99993 18.0708 7.99993 17.3333ZM3.33326 19.3333C2.22909 19.3333 1.33326 18.4375 1.33326 17.3333C1.33326 16.2291 2.22909 15.3333 3.33326 15.3333C4.43743 15.3333 5.33326 16.2291 5.33326 17.3333C5.33326 18.4375 4.43743 19.3333 3.33326 19.3333Z"
        fill="#0E0E4B"
      />
    </svg>
  );
};

export default FirststepIcon;
