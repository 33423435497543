import * as vpsActionTypes from '../actionTypes/vpsActionTypes'
import {axios2} from "../../axios";
import {handleType} from "./handleType";
import {Dispatch} from "redux";
import {RefreshState} from "./authActions";

interface DataFinishVps {
  expiration_date: string;
  save: number;
  is_back_office: boolean;
}

export const getOperationSystems = (token: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_START, null))
    try {
      const response = await axios2.get('billing/vps-billing/cloud-images', {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_SUCCESS, response.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_ERROR, e))
    }
  }
}

export const getOperationSystemsDetail = (token: any, id: string | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_DETAIL_START, null))
    try {
      const response = await axios2.get(`billing/vps-billing/cloud-images/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_DETAIL_SUCCESS, response.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.GET_VPS_OPERATION_SYSTEMS_DETAIL_ERROR, e))
    }
  }
}

export const getVpsTariffs = (token: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_VPS_TARIFFS_START, null))
    try {
      const response = await axios2.get('vps/tariff-list', {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.GET_VPS_TARIFFS_SUCCESS, response.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.GET_VPS_TARIFFS_ERROR, e))
    }
  }
}

export const postVpsBilling = (token: any, data: any, check: boolean | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.POST_VPS_BILLING_START, null))
    if (!check) {
      try {
        const response: any = await axios2.post('billing/calculate-vps', data, {
          headers: {Authorization: `Bearer ${token}`}
        })
        if (response?.data?.success) {
          dispatch(handleType(vpsActionTypes.POST_VPS_BILLING_SUCCESS, response.data))
        } else {
          dispatch(handleType(vpsActionTypes.POST_VPS_BILLING_SUCCESS, response?.response?.data))
        }
      } catch (e) {
        console.log(e)
        dispatch(handleType(vpsActionTypes.POST_VPS_BILLING_ERROR, e))
      }
    }
  }
}

export const createVpsContract = (token: any, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.POST_VPS_CONTRACT_START, null))
    try {
      const response = await axios2.post('vps/contract-create', data, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.POST_VPS_CONTRACT_SUCCESS, response.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.POST_VPS_CONTRACT_ERROR, e))
    }
  }
}

export const createVpsContractAdditionalAgreement = (token: any, pin_or_tin: string | undefined, navigate: any, slug: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.CREATE_CONTRACT_ADDITIONAL_AGREEMENT_START, null))
    try {
      const res: any = await axios2.get(`${slug}/contract-create?pin_or_tin=${pin_or_tin}`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.CREATE_CONTRACT_ADDITIONAL_AGREEMENT_SUCCESS, res.data))
      if (res?.response?.status === 400 || res?.response?.status === 500) {
        alert(res.response.data.message)
        navigate('/')
      } else {
        return res.data
      }
    } catch (e: any) {
      console.log(e)
      dispatch(handleType(vpsActionTypes.CREATE_CONTRACT_ADDITIONAL_AGREEMENT_ERROR, e.message))
    }
  }
}

export const getVpsBalance = (token: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_VPS_BALANCE_START, null))
    try {
      const res = await axios2.get(`billing/vps-billing/get-balance?scoped=${true}`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.GET_VPS_BALANCE_SUCCESS, res.data))
      return res.data
    } catch (e: any) {
      dispatch(handleType(vpsActionTypes.GET_VPS_BALANCE_ERROR, e.message))
    }
  }
}

export const getMyVps = (token: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_MY_VPS_START, null))
    try {
      const res = await axios2.get(`billing/vps-billing/get-balance`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.GET_MY_VPS_SUCCESS, res.data.responce.vm_list.vms))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.GET_MY_VPS_ERROR, e))
    }
  }
}
export const getMyVpsDetail = (token: RefreshState | null, id: string | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_MY_VPS_DETAIL_START, null))
    try {
      const res = await axios2.get(`billing/vps-billing/get/vm/info/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.GET_MY_VPS_DETAIL_SUCCESS, res.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.GET_MY_VPS_DETAIL_ERROR, e))
    }
  }
}

export const postFinishVps = (token: RefreshState | null, data: DataFinishVps, service: string | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.POST_VPS_FINISH_START, null))
    try {
      const res = await axios2.post(`${service}/contract/finish`, data, {
        headers: {Authorization: `Bearer ${token}`}
      })
      dispatch(handleType(vpsActionTypes.POST_VPS_FINISH_SUCCESS, res.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.POST_VPS_FINISH_ERROR, e))
    }
  }
}

export const getVmMsg = (token: RefreshState | null, id: string | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.GET_MESSAGE_VM_START, null))
    try {
      const res = await axios2.get(`main/notify/get/vm/msg/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      dispatch(handleType(vpsActionTypes.GET_MESSAGE_VM_FINISH, res.data))
    } catch (e) {
      dispatch(handleType(vpsActionTypes.GET_MESSAGE_VM_ERROR, e))
    }
  }
}

export const saveVpsContractData = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.SAVE_VPS_CONTRACT_DATA, data))
  }
}

export const clearVpsContractData = () => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.CLEAR_VPS_CONTRACT_DATA, null))
  }
}

export const clearVpsCalculation = () => {
  return (dispatch: Dispatch) => {
    dispatch(handleType(vpsActionTypes.CLEAR_VPS_CALCULATION, null))
  }
}