import React, {useState} from "react";
import Checked from "../../images/checked";
import {useTranslation} from "react-i18next";
import Steps from "../../components/Steps/Steps";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Arrow2 from "../../images/arrow2";
import {useNavigate} from "react-router-dom";
// import {
//   clearVpsCalculation,
// } from "../../store/actions/vpsActions";
import EMailOffer from "./EMailOffer";

const EMailPage: React.FC = () => {
  const [agree, setAgree] = useState<boolean>(false);
  // const dispatch = useAppDispatch();
  const {t} = useTranslation();
  
  const {offerDetail} = useAppSelector((state) => state.contracts);
  
  const service_id = localStorage.getItem('service_id')
  
  const navigate = useNavigate()
  
  return (
    <div className='container'>
      <div className='request request_vps'>
        <Steps activeStep={1}/>
        <div className='request_content'>
          <>
            <EMailOffer title={t("publicOffer")} pdfFile={offerDetail?.file} contract={false}/>
            <div className="request_content-agreement" onClick={() => setAgree(!agree)}>
              <div
                className={
                  agree
                    ? "request_content-agreement-active"
                    : "request_content-agreement-check"
                }
              >
                <Checked/>
              </div>
              <span>{t("gotAcquaintedWithRules")}</span>
            </div>
            <div className="request_content-buttons">
              <button
                onClick={() => {
                  navigate('/')
                  localStorage.removeItem('count')
                  localStorage.removeItem('service_id')
                  localStorage.removeItem('payChoose')
                  localStorage.removeItem('pkcs7')
                }}
              >
                <span>{t("cancel")}</span>
              </button>
              <button
                disabled={!agree}
                style={{opacity: !agree ? 0.5 : 1}}
                onClick={() => {
                  navigate('/emailContractInfo', {state: {service_id}})
                }}
              >
                <span>{t("next")}</span>
                <Arrow2 color="white"/>
              </button>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default EMailPage