import React from "react";

type Props = {
  color: string;
};

const NotSavedIcon: React.FC<Props> = ({color}) => {
  return (
    <svg
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.2C0 3.08609 0.442499 2.0178 1.23015 1.23015C2.0178 0.442499 3.08609 0 4.2 0H15.8C16.9139 0 17.9822 0.442499 18.7698 1.23015C19.5575 2.0178 20 3.08609 20 4.2V25C19.9998 25.184 19.9488 25.3645 19.8527 25.5214C19.7565 25.6783 19.6189 25.8057 19.4551 25.8894C19.2912 25.9732 19.1074 26.0101 18.9239 25.9961C18.7403 25.982 18.5643 25.9177 18.415 25.81L10 19.735L1.585 25.81C1.43574 25.9177 1.25965 25.982 1.07615 25.9961C0.892637 26.0101 0.708821 25.9732 0.544938 25.8894C0.381055 25.8057 0.243464 25.6783 0.147315 25.5214C0.0511663 25.3645 0.000190099 25.184 0 25V4.2ZM4.2 2C3.61652 2 3.05695 2.23179 2.64437 2.64437C2.23179 3.05695 2 3.61652 2 4.2V23.044L9.415 17.689C9.58532 17.5661 9.79 17.5 10 17.5C10.21 17.5 10.4147 17.5661 10.585 17.689L18 23.044V4.2C18 3.61652 17.7682 3.05695 17.3556 2.64437C16.9431 2.23179 16.3835 2 15.8 2H4.2Z"
        fill={color}
      />
    </svg>
  );
};

export default NotSavedIcon;
