import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MuiTable from "../../components/MuiTable/MuiTable";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getUserContracts, getServices } from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import Footer from "../../components/Footer/Footer";


const MyRequestPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { access } = useAppSelector((state) => state.auth);
  const { userContracts, loading } = useAppSelector((state) => state.contracts);
  const { change } = useAppSelector((state) => state.userService);
  const [filterByService, setFilterByService] = useState<string>('contracts')

  const [number, setNumber] = useState<number>(1)

  useEffect(() => {
    dispatch(getUserContracts(access, change.userSelectService === undefined ? 'e-xat' : change.userSelectService));
    dispatch(getServices(access ? access : ""));
  }, [access]);

  useEffect(() => {
    dispatch(getUserContracts(access, change.userSelectService === undefined ? 'e-xat' : change.userSelectService));
    dispatch(getServices(access ? access : ""));
  }, [change]);
  
  // if (loading) return <LoadingPage />;

  return (
    <>
      <div className="myRequest">
        <Sidebar active={location.pathname} type="profile" />
        <div className="myRequest_wrap" style={{height: userContracts?.length >= 3 ? '90vh' : `calc(${window.innerHeight}px - 200px)`}}>
          <MuiTable data={userContracts} num={number} setNum={setNumber} filter={filterByService} setFilter={setFilterByService} key={number + 1}  />
        </div>
        <div className="myRequest_div"></div>
      </div>
    </>
  );
};

export default MyRequestPage;
