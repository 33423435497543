
export const CAPIWS = {
  URL:
    (window.location.protocol.toLowerCase() === "https:"
      ? "wss://127.0.0.1:64443"
      : "ws://127.0.0.1:64646") + "/service/cryptapi",
  callFunction: function (funcDef: any, callback: any, error: any) {
    if (!window.WebSocket) {
      if (error) error();
      return;
    }
    var socket: any;
    try {
      socket = new WebSocket(this.URL);
    } catch (e) {
      error(e);
    }
    socket.onerror = function (e: any) {
      if (error) error(e);
    };
    socket.onmessage = function (event: any) {
      var data = JSON.parse(event.data);
      socket.close();
      callback(event, data);
    };
    socket.onopen = function () {
      socket.send(JSON.stringify(funcDef));
    };
  },
  version: function (callback: any, error: any) {
    if (!window.WebSocket) {
      if (error) error();
      return;
    }
    var socket: any;
    try {
      socket = new WebSocket(this.URL);
    } catch (e) {
      error(e);
    }
    socket.onerror = function (e: any) {
      if (error) error(e);
    };
    socket.onmessage = function (event: any) {
      var data = JSON.parse(event.data);
      socket.close();
      callback(event, data);
    };
    socket.onopen = function () {
      var o = { name: "version" };
      socket.send(JSON.stringify(o));
    };
  },
  apidoc: function (callback: any, error: any) {
    if (!window.WebSocket) {
      if (error) error();
      return;
    }
    var socket: any;
    try {
      socket = new WebSocket(this.URL);
    } catch (e) {
      error(e);
    }
    socket.onerror = function (e: any) {
      if (error) error(e);
    };
    socket.onmessage = function (event: any) {
      var data = JSON.parse(event.data);
      socket.close();
      callback(event, data);
    };
    socket.onopen = function () {
      var o = { name: "apidoc" };
      socket.send(JSON.stringify(o));
    };
  },
  apikey: function (domainAndKey: any, callback: any, error: any) {
    if (!window.WebSocket) {
      if (error) error();
      return;
    }
    var socket: any;
    try {
      socket = new WebSocket(this.URL);
    } catch (e) {
      error(e);
    }
    socket.onerror = function (e: any) {
      if (error) error(e);
    };
    socket.onmessage = function (event: any) {
      var data = JSON.parse(event.data);
      socket.close();
      callback(event, data);
    };
    socket.onopen = function () {
      var o = { name: "apikey", arguments: domainAndKey };
      socket.send(JSON.stringify(o));
    };
  },
};
