import React from "react";

const DropIcon = ({ color = "black" }) => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.347684 1.2555C0.570373 1.03288 0.872364 0.907821 1.18725 0.907821C1.50213 0.907821 1.80412 1.03288 2.02681 1.2555L7.90493 7.13363L13.7831 1.2555C14.007 1.03919 14.307 0.919499 14.6183 0.922204C14.9297 0.92491 15.2275 1.0498 15.4477 1.26997C15.6679 1.49014 15.7928 1.78798 15.7955 2.09934C15.7982 2.4107 15.6785 2.71066 15.4622 2.93463L8.7445 9.65232C8.52181 9.87494 8.21982 10 7.90493 10C7.59005 10 7.28806 9.87494 7.06537 9.65232L0.347684 2.93463C0.125062 2.71194 0 2.40995 0 2.09507C0 1.78018 0.125062 1.47819 0.347684 1.2555V1.2555Z"
        fill={color}
      />
    </svg>
  );
};

export default DropIcon;
