import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import {iCalculateCoLocation} from "../../store/models";
import ContractInfoPage from "../ContractInfoPage/ContractInfoPage";
import {getDataCenterCalculate} from "../../store/actions/contractActions";

const ColocationAgreementAdditionalPage: React.FC = () => {
  
  const dispatch = useAppDispatch();
  
  const {access} = useAppSelector((state) => state.auth);
  const {
    vpsConfig,
  } = useAppSelector(state => state.vps)
  const {calculateDataCenter} = useAppSelector(
    (state) => state.contracts
  );
  
  const [data, setData] = useState<iCalculateCoLocation[]>([
    {data_center: '', mounting_type: '', amount: null, status: 3, id: ''}
  ])
  
  useEffect(() => {
    // if (!handleValidate()) return postBilling(data)
  }, [])
  
  const navigate = useNavigate()
  
  const timeoutId = useRef<any>()
  const postBilling = (value: any) => {
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      dispatch(getDataCenterCalculate(access, value, handleValidateForCalculate()))
    }, 500)
  }
  
  useEffect(() => {
    if (vpsConfig) {
      const dataObjects = vpsConfig?.colocation?.map((item: any) => ({
        data_center: item?.data_center,
        mounting_type: item?.mounting_type,
        amount: item?.amount,
        status: 4,
        id: item?.id
      }))
      setData(dataObjects)
      postBilling(dataObjects)
      localStorage.setItem('coLocation_data', JSON.stringify(dataObjects))
    }
  }, [vpsConfig])
  
  const checkForDuplicateSelections = () => {
    let hasDuplicates = false;
    let combinations: any = {};

    for (let i = 0; i < data.length; i++) {
      let combination = `${data[i].data_center}-${data[i].mounting_type}`;
      if (combinations[combination]) {
        hasDuplicates = true;
        break;
      }
      combinations[combination] = true;
    }

    return hasDuplicates;
  };

  const handleValidateForCalculate = () => {
    if (checkForDuplicateSelections()) {
      return true;
    }
    for (const currentData of data) {
      if (
        !currentData?.amount ||
        !currentData?.data_center ||
        !currentData?.mounting_type
      ) {
        return true
      }
    }
    return false
  }
  
  const handleValidate = () => {
    if (checkForDuplicateSelections()) {
      return true;
    }
    for (const currentData of data) {
      if (
        !calculateDataCenter?.success ||
        !currentData?.amount ||
        !currentData?.data_center ||
        !currentData?.mounting_type
      ) {
        return true
      }
    }
    return false
  }
  
  return (
    <div className='request'>
      <div className='request_content'>
        <ContractInfoPage/>
      </div>
    </div>
  )
}

export default ColocationAgreementAdditionalPage