import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import ShowRequest from "../../components/ShowRequest/ShowRequest";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getContractDetail} from "../../store/actions/contractActions";

const RequestWithoutModal: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const {access} = useAppSelector((state) => state.auth);
  const {contractDetail} = useAppSelector((state) => state.contracts);
  const {userDetail} = useAppSelector((state) => state.user);
  const {change} = useAppSelector((state) => state.userService);

  useEffect(() => {
    dispatch(getContractDetail(access, change.userSelectService === undefined ? 'e-xat' : change.userSelectService, params.id));
  }, [access]);

  useEffect(() => {
    dispatch(getContractDetail(access, change.userSelectService === undefined ? 'e-xat' : change.userSelectService, params.id));
  }, [change]);

  return (
    <>
      <Header/>
      <div className="container">
        <div className="request">
          {/* <Steps activeStep={4} /> */}
          <div className="request_content">
            <ShowRequest detail={contractDetail} userDetail={userDetail}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestWithoutModal;
