import React from "react";

type Props = {
  bgColor: string;
  iconColor: string;
};

const LogoutIcon: React.FC<Props> = ({ bgColor, iconColor }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_430_1055)">
        <rect x="4" width="47" height="47" rx="10" fill={bgColor} />
        <rect
          x="4.5"
          y="0.5"
          width="46"
          height="46"
          rx="9.5"
          stroke="url(#paint0_linear_430_1055)"
        />
      </g>
      <path
        d="M34.9583 28.6667L40.125 23.5001M40.125 23.5001L34.9583 18.3334M40.125 23.5001H22.0417M29.7917 28.6667V29.9584C29.7917 30.9861 29.3834 31.9717 28.6567 32.6985C27.93 33.4252 26.9444 33.8334 25.9167 33.8334H20.75C19.7223 33.8334 18.7367 33.4252 18.01 32.6985C17.2833 31.9717 16.875 30.9861 16.875 29.9584V17.0417C16.875 16.014 17.2833 15.0284 18.01 14.3017C18.7367 13.575 19.7223 13.1667 20.75 13.1667H25.9167C26.9444 13.1667 27.93 13.575 28.6567 14.3017C29.3834 15.0284 29.7917 16.014 29.7917 17.0417V18.3334"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_430_1055"
          x="0"
          y="0"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_430_1055"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_430_1055"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_430_1055"
          x1="4"
          y1="0"
          x2="51"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={iconColor} />
          <stop offset="0.447916" stopColor={iconColor} stopOpacity="0" />
          <stop offset="1" stopColor={iconColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
