import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import App from "./App";
import { persistor, store } from "./store/rootConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.error = (message) => {
  if (message.startsWith('Warning: Encountered two children with the same key')) {
    return;
  }
  console.warn(message);
};

window.addEventListener('keydown', (event: KeyboardEvent) => {
  if (event.key === 'F12') {
    event.preventDefault();
    console.log('block')
  }
});

window.addEventListener('keydown', (event: KeyboardEvent) => {
  if (event.ctrlKey && event.key === 'j') {
    event.preventDefault();
    console.warn("Ctrl + J is disabled.");
  }
});

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
