import React from "react";

type Props = {
  color: string
}

const CallIcon = ({color}: Props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5562 10.9752L12.2344 9.43769C12.6932 9.01647 13.0157 8.46774 13.1605 7.86193C13.3053 7.25611 13.2657 6.62085 13.0469 6.03769L12.3312 4.12675C12.0639 3.41334 11.5346 2.8287 10.8512 2.49194C10.1678 2.15518 9.38173 2.09164 8.65312 2.31425C5.97187 3.13456 3.91093 5.62675 4.5453 8.58613C4.96249 10.533 5.76093 12.9768 7.27343 15.5768C8.78905 18.183 10.5203 20.108 12.0047 21.4611C14.2453 23.5002 17.4484 22.9908 19.5094 21.0689C20.0618 20.5538 20.397 19.8475 20.4467 19.0938C20.4963 18.3401 20.2567 17.5958 19.7766 17.0127L18.4641 15.4189C18.0682 14.937 17.5372 14.5845 16.9393 14.4069C16.3415 14.2292 15.7042 14.2345 15.1094 14.4221L12.9406 15.1049C12.3805 14.5269 11.8897 13.8856 11.4781 13.1939C11.0808 12.4945 10.7712 11.7488 10.5562 10.9736V10.9752Z"
        fill={color}
      />
    </svg>
  );
};

export default CallIcon;
