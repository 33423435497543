import React from "react";

const PlusIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_2717)">
        <rect x="7" y="3" width="38" height="38" rx="19" fill="white" />
        <rect
          x="7.48718"
          y="3.48718"
          width="37.0256"
          height="37.0256"
          rx="18.5128"
          stroke="url(#paint0_linear_1_2717)"
          strokeWidth="0.974359"
        />
      </g>
      <path
        d="M26.25 13V31.5"
        stroke="#0E0E4B"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M17 22.25H35.5"
        stroke="#0E0E4B"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_1_2717"
          x="0.179487"
          y="0.0769231"
          width="51.641"
          height="51.641"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.89744" />
          <feGaussianBlur stdDeviation="3.41026" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.294118 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_2717"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_2717"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_2717"
          x1="7"
          y1="3"
          x2="45"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.447916" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusIcon;
