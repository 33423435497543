import * as accountTypes from "../actionTypes/accountsActionTypes";
import { iBank, iGroupList } from "../models";
import { ReducerActions } from "../utilsTypes";

interface AccountsState {
  loading: boolean;
  error: string;
  groupList: iGroupList[];
  bankInfo: iBank | null;
  current_time: string;
  current_time_loading: boolean;
  messageDetail: MessageDetailStateData | null
}

interface MessageDetailStateData {
  id: string,
  created_time: string,
  updated_time: string,
  contract_num: string,
  contract_id: number,
  contract_date: string,
  payment_code: string,
  amount: string,
  pay_amount: string,
  balance: string,
  start_date: string,
  msg: string,
  email: string,
  lang: string,
  day_left: number,
  is_active: boolean,
  client: number
}

const initState: AccountsState = {
  loading: false,
  error: "",
  groupList: [],
  bankInfo: null,
  current_time: "",
  current_time_loading: false,
  messageDetail: null
};

export const accountsReducer = (
  state = initState,
  action: ReducerActions
): AccountsState => {
  const { type, payload } = action;
  switch (type) {
    case accountTypes.ACCOUNTS_LOADING:
      return { ...state, loading: true };
    case accountTypes.GET_GROUP_LIST:
      return { ...state, loading: false, groupList: payload };
    case accountTypes.GET_BANK:
      return { ...state, loading: false, bankInfo: payload };
    case accountTypes.CURRENT_TIME_LOADING:
      return { ...state, current_time_loading: true };
    case accountTypes.GET_CURRENT_TIME:
      return { ...state, loading: false, current_time_loading: false, current_time: payload };
    case accountTypes.ACCOUNTS_ERROR:
      return { ...state, loading: false, error: payload };
    case accountTypes.MESSAGE_DETAIL_START:
      return {...state, loading: true}
    case accountTypes.MESSAGE_DETAIL_FULL:
      return {...state, loading: false, messageDetail: payload}
    case accountTypes.MESSAGE_DETAIL_ERROR:
      return {...state, loading: false, error: payload}
    default:
      return state;
  }
};
