import React, {useEffect} from 'react';
import {useAppSelector, useAppDispatch} from "../../hooks/redux";
import LoadingPage from "../LoadingPage/LoadingPage";
import OutsideClickHandler from "react-outside-click-handler";
import {getMessageDetail} from "../../store/actions/accountActions";
import {getVmMsg} from "../../store/actions/vpsActions";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import moment from "moment";
import Table from "@mui/material/Table";

type Props = {
  close: () => void;
  id: string | null;
  type: number | null
};

const ModalMsg: React.FC<Props> = ({close, id, type}) => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation();
  const {access} = useAppSelector((state) => state.auth);
  const {vmMsgDetail} = useAppSelector((state) => state.vps)
  const {messageDetail} = useAppSelector((state) => state.accounts)
  
  useEffect(() => {
    if (type === 1) {
      dispatch(getVmMsg(access, id))
    } else {
      dispatch(getMessageDetail(access, id))
    }
  }, [access, dispatch, id, type]);
  
  return (
    <div className='modalWrap'>
      {type === 1 ? (
        <OutsideClickHandler onOutsideClick={close}>
          <div className="modalWrap_textWrap news_modal">
            <h1>Vm boyicha xabarlar</h1>
            <p>{vmMsgDetail?.msg}</p>
            <TableContainer>
              <Table className='muiTable'>
                <TableHead className='muiTable_head'>
                  <TableRow>
                    <TableCell className='muiTable_head-cell'>Nomi</TableCell>
                    <TableCell className='muiTable_head-cell'>Ip</TableCell>
                    <TableCell className='muiTable_head-cell'>Login</TableCell>
                    <TableCell className='muiTable_head-cell'>Parol</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{vmMsgDetail?.vm_name}</TableCell>
                    <TableCell>{vmMsgDetail?.ip}</TableCell>
                    <TableCell>{vmMsgDetail?.login}</TableCell>
                    <TableCell>{vmMsgDetail?.password}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="modalWrap_block-btnBlock" style={{justifyContent: 'center'}}>
              <button onClick={close}>OK</button>
            </div>
          </div>
        </OutsideClickHandler>
      ) : (
        <OutsideClickHandler onOutsideClick={close}>
          <div className="modalWrap_textWrap news_modal">
            <h1>Shartnoma boyicha xabar</h1>
            <p>{messageDetail?.msg}</p>
            <TableContainer>
              <Table className={'muiTable'}>
                <TableHead className='muiTable_head'>
                  <TableRow>
                    <TableCell className='muiTable_head-cell'>{t("contractNumber")}</TableCell>
                    <TableCell className='muiTable_head-cell'>{t("contractDate")}</TableCell>
                    <TableCell className='muiTable_head-cell'>{t("email")}</TableCell>
                    <TableCell className='muiTable_head-cell'>{t("invoice")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{messageDetail?.contract_num}</TableCell>
                    <TableCell>{moment(messageDetail?.contract_date).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{messageDetail?.email}</TableCell>
                    <TableCell>{messageDetail?.payment_code}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="modalWrap_block-btnBlock" style={{justifyContent: 'center'}}>
              <button onClick={close}>OK</button>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default ModalMsg;