import React from "react";
import { Grid } from "@mui/material";

const LoadingPage: React.FC = () => {
  return (
    <Grid
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="90vh"
    >
      <div className="loadingio-spinner-spinner-u17kqrniter">
        <div className="ldio-weqmpwzosqd">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Grid>
  );
};

export default LoadingPage;
