export const GET_VPS_TARIFFS_START = 'GET_VPS_TARIFFS_START'
export const GET_VPS_TARIFFS_SUCCESS = 'GET_VPS_TARIFFS_SUCCESS'
export const GET_VPS_TARIFFS_ERROR = 'GET_VPS_TARIFFS_ERROR'
export const GET_VPS_OPERATION_SYSTEMS_START = 'GET_VPS_OPERATION_SYSTEMS_START'
export const GET_VPS_OPERATION_SYSTEMS_SUCCESS = 'GET_VPS_OPERATION_SYSTEMS_SUCCESS'
export const GET_VPS_OPERATION_SYSTEMS_ERROR = 'GET_VPS_OPERATION_SYSTEMS_ERROR'
export const GET_VPS_OPERATION_SYSTEMS_DETAIL_START = 'GET_VPS_OPERATION_SYSTEMS_DETAIL_START'
export const GET_VPS_OPERATION_SYSTEMS_DETAIL_SUCCESS = 'GET_VPS_OPERATION_SYSTEMS_DETAIL_SUCCESS'
export const GET_VPS_OPERATION_SYSTEMS_DETAIL_ERROR = 'GET_VPS_OPERATION_SYSTEMS_DETAIL_ERROR'
export const POST_VPS_BILLING_START = 'POST_VPS_BILLING_START'
export const POST_VPS_BILLING_SUCCESS = 'POST_VPS_BILLING_SUCCESS'
export const POST_VPS_BILLING_ERROR = 'POST_VPS_BILLING_ERROR'
export const POST_VPS_CONTRACT_START = 'POST_VPS_CONTRACT_START'
export const POST_VPS_CONTRACT_SUCCESS = 'POST_VPS_CONTRACT_SUCCESS'
export const POST_VPS_CONTRACT_ERROR = 'POST_VPS_CONTRACT_ERROR'
export const CLEAR_VPS_BILLING = 'CLEAR_VPS_BILLING'
export const CREATE_CONTRACT_ADDITIONAL_AGREEMENT_START = 'CREATE_CONTRACT_ADDITIONAL_AGREEMENT_START'
export const CREATE_CONTRACT_ADDITIONAL_AGREEMENT_SUCCESS = 'CREATE_CONTRACT_ADDITIONAL_AGREEMENT_SUCCESS'
export const CREATE_CONTRACT_ADDITIONAL_AGREEMENT_ERROR = 'CREATE_CONTRACT_ADDITIONAL_AGREEMENT_ERROR'
export const GET_VPS_BALANCE_START = 'GET_VPS_BALANCE_START'
export const GET_VPS_BALANCE_SUCCESS = 'GET_VPS_BALANCE_SUCCESS'
export const GET_VPS_BALANCE_ERROR = 'GET_VPS_BALANCE_ERROR'
export const GET_MY_VPS_START = 'GET_MY_VPS_START'
export const GET_MY_VPS_SUCCESS = 'GET_MY_VPS_SUCCESS'
export const GET_MY_VPS_ERROR = 'GET_MY_VPS_ERROR'
export const GET_MY_VPS_DETAIL_START = 'GET_MY_VPS_DETAIL_START'
export const GET_MY_VPS_DETAIL_SUCCESS = 'GET_MY_VPS_DETAIL_SUCCESS'
export const GET_MY_VPS_DETAIL_ERROR = 'GET_MY_VPS_DETAIL_ERROR'
export const POST_VPS_FINISH_START = 'POST_VPS_FINISH_START'
export const POST_VPS_FINISH_SUCCESS = 'POST_VPS_FINISH_SUCCESS'
export const POST_VPS_FINISH_ERROR = 'POST_VPS_FINISH_ERROR'
export const CLEAR_VPS_CALCULATION = 'CLEAR_VPS_CALCULATION'
export const SAVE_VPS_CONTRACT_DATA = 'SAVE_VPS_CONTRACT_DATA'
export const CLEAR_VPS_CONTRACT_DATA = 'CLEAR_VPS_CONTRACT_DATA'
export const GET_MESSAGE_VM_START = 'GET_MESSAGE_VM_START'
export const GET_MESSAGE_VM_FINISH = 'GET_MESSAGE_VM_FINISH'
export const GET_MESSAGE_VM_ERROR = 'GET_MESSAGE_VM_ERROR'
export const GET_OS_TYPE_START = 'GET_OS_TYPE_START'
export const GET_OS_TYPE_SUCCESS = 'GET_OS_TYPE_SUCCESS'
export const GET_OS_TYPE_ERROR = 'GET_OS_TYPE_ERROR'
export const GET_OS_TYPE_DETAIL_START = 'GET_OS_TYPE_DETAIL_START'
export const GET_OS_TYPE_DETAIL_SUCCESS = 'GET_OS_TYPE_DETAIL_SUCCESS'
export const GET_OS_TYPE_DETAIL_ERROR = 'GET_OS_TYPE_DETAIL_ERROR'
