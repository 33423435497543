import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceBlock from "../../components/ServiceBlock/ServiceBlock";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createSavedService, deleteSavedService, getGroupServices } from "../../store/actions/contractActions";
import LoadingPage from "../LoadingPage/LoadingPage";
import Footer from "../../components/Footer/Footer";

const DataCenterPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { groupId } = location.state;

  const { access } = useAppSelector(state => state.auth);
  const { groupServices, loading } = useAppSelector(state => state.contracts);

  const handleSaveService = (id: number) => {
    dispatch(createSavedService({ service_id: id }, access));
    window.location.reload();
  };

  const handleDeleteSaveService = (id: number) => {
    dispatch(deleteSavedService(id, access));
    window.location.reload();
  };

  useEffect(() => {
    if (groupId) {
      dispatch(getGroupServices(groupId));
    }
  }, [groupId, dispatch]);

  if (loading) return <LoadingPage />;

  return (
    <>
      <div className="dataCenter">
        {window.innerWidth > 768 && <Sidebar active={location.pathname} type="main"/>}
        <div className="dataCenter_wrap">
          <div className="dataCenter_wrap-services">
            {groupServices && groupServices?.map((el) => (
              <ServiceBlock
                key={el.id}
                name={el.name}
                icon={el.image}
                descr={el.description}
                favorite={el.is_saved}
                visible={el.group.is_visible_in_front}
                token={access}
                setSaved={
                  el.is_saved
                    ? () => handleDeleteSaveService(el.id)
                    : () => handleSaveService(el.id)
                }
                navigate={() => navigate(`/service/${el.id}`, {
                  state: {
                    slug: el
                  }
                })}
              />
            ))}
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default DataCenterPage;
