import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      width="136"
      height="137"
      viewBox="0 0 136 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_198_1118)">
        <path
          d="M68.0001 8.05566C56.0454 8.05566 44.359 11.6007 34.419 18.2424C24.479 24.8841 16.7316 34.3242 12.1568 45.369C7.58185 56.4138 6.38485 68.5672 8.71711 80.2922C11.0494 92.0173 16.8062 102.787 25.2595 111.241C33.7128 119.694 44.4829 125.451 56.208 127.783C67.9331 130.115 80.0864 128.918 91.1312 124.344C102.176 119.769 111.616 112.021 118.258 102.081C124.9 92.1412 128.445 80.4549 128.445 68.5001C128.445 52.4692 122.076 37.095 110.741 25.7594C99.4053 14.4239 84.031 8.05566 68.0001 8.05566ZM68.0001 121.389C57.5397 121.389 47.3142 118.287 38.6166 112.476C29.9191 106.664 23.1402 98.404 19.1372 88.7398C15.1341 79.0756 14.0868 68.4414 16.1275 58.182C18.1682 47.9226 23.2054 38.4987 30.602 31.102C37.9987 23.7054 47.4226 18.6682 57.682 16.6275C67.9415 14.5867 78.5757 15.6341 88.2398 19.6371C97.904 23.6402 106.164 30.4191 111.976 39.1166C117.787 47.8141 120.889 58.0397 120.889 68.5001C120.889 82.5271 115.317 95.9796 105.398 105.898C95.4796 115.817 82.0271 121.389 68.0001 121.389Z"
          fill="#5FFFB2"
        />
        <path
          d="M105.778 46.2128C105.07 45.5092 104.113 45.1143 103.115 45.1143C102.117 45.1143 101.159 45.5092 100.451 46.2128L58.5179 87.9573L35.8512 65.2906C35.1599 64.5441 34.2004 64.1029 33.1837 64.0639C32.1671 64.025 31.1766 64.3915 30.4301 65.0828C29.6837 65.7741 29.2425 66.7337 29.2035 67.7503C29.1645 68.767 29.531 69.7575 30.2224 70.5039L58.5179 98.7239L105.778 51.5773C106.132 51.2261 106.413 50.8082 106.605 50.3479C106.797 49.8875 106.895 49.3937 106.895 48.895C106.895 48.3963 106.797 47.9025 106.605 47.4422C106.413 46.9818 106.132 46.564 105.778 46.2128Z"
          fill="#5FFFB2"
        />
      </g>
      <defs>
        <clipPath id="clip0_198_1118">
          <rect
            width="136"
            height="136"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessIcon;
