import React from "react";

const MailIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_198_1335)">
        <path
          d="M12.0688 5.485C13.9774 5.485 15.7758 6.3311 17.0938 7.65375V7.65766C17.0938 7.0225 17.5211 6.54203 18.1117 6.54203H18.2617C19.1938 6.54203 19.3805 7.42094 19.3805 7.69828L19.3844 17.5694C19.3195 18.2163 20.0524 18.5506 20.4594 18.135C22.0414 16.5077 23.9375 9.76078 19.4742 5.85453C15.3117 2.20844 9.72501 2.81079 6.75469 4.85766C3.59766 7.03891 1.57969 11.8569 3.53985 16.385C5.68048 21.3217 11.8 22.7944 15.4422 21.3256C17.2859 20.5811 18.1359 23.0702 16.2195 23.885C13.3313 25.117 5.28204 24.9913 1.52266 18.4811C-1.01718 14.0834 -0.882807 6.3475 5.85626 2.33969C11.007 -0.728278 17.8031 0.121723 21.9 4.39907C26.1813 8.87485 25.9336 17.2491 21.7539 20.5045C19.8609 21.985 17.05 20.5452 17.0703 18.3881L17.0492 17.685C15.7313 18.9905 13.9774 19.7561 12.0688 19.7561C8.29298 19.7561 4.96876 16.4311 4.96876 12.6592C4.96876 8.84672 8.29298 5.48657 12.0688 5.48657V5.485ZM16.818 12.3866C16.675 9.62328 14.6242 7.95922 12.1461 7.95922H12.0524C9.1961 7.95922 7.60938 10.2092 7.60938 12.76C7.60938 15.6202 9.52579 17.4264 12.0406 17.4264C14.8477 17.4264 16.6906 15.3725 16.825 12.9428L16.818 12.3866Z"
          fill="#9A9A9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_198_1335">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MailIcon;
